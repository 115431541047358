import React, { useState, useEffect, useMemo } from "react"

import { useNavigate} from 'react-router-dom';
import { DAILY_PRICE_MOVEMENT_LIST_ENDPOINT } from "utils/WT3ApiConstants"
import { getDataFromAPI } from 'utils/WT3ApiUtil'
import PriceTrendsFilterComponentChain from 'admin/components/PriceTrendsFilterComponentChain'
import {DailyMovementLineChartModal} from 'analysis/DailyPriceMovementPage'

import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";

const DailyPriceMovementList = ({trading_date}) => {

    console.log(`Loading DailyPriceMovementList for trading date ${trading_date}`)
    const navigate = useNavigate();
    const [dailyPriceMovementList, setDailyPriceMovementList] = useState([])
    const [filteredDailyPriceMovementList, setFilteredDailyPriceMovementList] = useState([]); // Filtered data    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const [filterOptions, setFilterOptions] = useState({
        ticker: { value: '', rule: 'eq', name: 'Ticker' },
        exchange: { value: '', rule: 'eq', name: 'Exchange' },
        dir_vw_price: { value: '', rule: 'lte', name: 'Direction' },
        mov_vw_price: { value: '', rule: 'lte', name: 'Movement' }
      });

      const sortedAndFilteredDailyPriceMovementList = useMemo(() => {
        // Check if dailyPriceMovementList is empty
        if (!filteredDailyPriceMovementList || filteredDailyPriceMovementList.length === 0) {
          // Return dailyPriceMovementList as is or an empty array
          return filteredDailyPriceMovementList || [];
        }
  
        return [...filteredDailyPriceMovementList].sort((a, b) => {
            if (sortConfig.key === null) return 0;
  
            if (sortConfig.direction === 'asc') {
                return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
            } else {
                return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
            }
        });
    }, [filteredDailyPriceMovementList, sortConfig]);
    
    useEffect(() => {
    
        const applyFilters = () => {
          if (dailyPriceMovementList == undefined || dailyPriceMovementList.length === 0) {
            console.log('No price trends data available.');
            setFilteredDailyPriceMovementList([]);
            return;
          }
        
          let filteredData = dailyPriceMovementList.filter((trend) => {
            return Object.keys(filterOptions).every(key => {
              if (!trend.hasOwnProperty(key) || filterOptions[key].value === '') return true;
              
              const filterType = typeof trend[key];
              const filterValue = filterType === 'number' ? parseFloat(filterOptions[key].value) : filterOptions[key].value;
            
              // Check for NaN values in filterValue when it's supposed to be a number
              if (filterType === 'number' && isNaN(filterValue)) {
                console.log(`NaN encountered for ${key}`);
                return true;
              }
        
              let result;
              switch (filterOptions[key].rule) {
                case 'eq':
                  result = trend[key] === filterValue;
                  break;
                case 'gte':
                  result = filterType === 'number' && trend[key] >= filterValue;
                  break;
                case 'lte':
                  result = filterType === 'number' && trend[key] <= filterValue;
                  break;
                default:
                  result = true;
              }
        
              return result;
    
            });
          });    
          setFilteredDailyPriceMovementList(filteredData);
        };
      
        applyFilters();
      }, [filterOptions, dailyPriceMovementList]);

    const fetchDailyPriceMovementList = async (trading_date) => {
        console.log(`Fetching daily price movement for date: ${trading_date}`);
        let dailyPriceMovementListByDateEndpoint = `${DAILY_PRICE_MOVEMENT_LIST_ENDPOINT}?trading_date=${trading_date}`;
        console.log(`dailyPriceMovementListByDateEndpoint: ${dailyPriceMovementListByDateEndpoint}`)

        showProgress();
        const response = await getDataFromAPI(dailyPriceMovementListByDateEndpoint, navigate);
        if (response) {
            // console.log(response['data'])
            setDailyPriceMovementList(response['data']);       
            hideProgress();  
        }
    };

    useEffect(() => {
        fetchDailyPriceMovementList(trading_date);
    }, []);

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };

    const DailyPriceMovementListHeader = () => {
        return(
                <div className="list-header">
                    <div className="list-item-header" onClick={() => requestSort('ticker')}><span>Ticker</span></div>
                    <div className="list-item-header" onClick={() => requestSort('exchange')}><span>Exchange</span></div>
                    <div className="list-item-header" onClick={() => requestSort('dir_vw_price')}><span>Direction</span></div>
                    <div className="list-item-header" onClick={() => requestSort('mov_vw_price')}><span>Movement</span></div>
                </div>
            )
    }
    
    const loadDailyPriceMovement = (ticker) => {
        console.log(`Loading daily trends for ${ticker}`);
        setIsModalOpen(true);
        setSelectedTicker(ticker);
    }

    const handleAllFiltersChange = (filterConfigs) => {
        let filteredData = [...dailyPriceMovementList]; // Replace with your original data
      
        filterConfigs.forEach(filterConfig => {
          if (filterConfig.key && filterConfig.value) {
            const filterValue = parseFloat(filterConfig.value);
            if (filterConfig.rule === 'gte') {
              filteredData = filteredData.filter(item => item[filterConfig.key] >= filterValue);
            } else if (filterConfig.rule === 'lte') {
              filteredData = filteredData.filter(item => item[filterConfig.key] <= filterValue);
            } else if (filterConfig.rule === 'eq') {
              filteredData = filteredData.filter(item => item[filterConfig.key].toString().startsWith(filterConfig.value));
            }
          }
        });

        setFilteredDailyPriceMovementList(filteredData); // Update the state with the filtered data
      };

    const displayDailyPriceMovementList = () => {
        console.log(dailyPriceMovementList.length);
        if (dailyPriceMovementList == undefined || dailyPriceMovementList.length < 1) {
            return '';
        }
        return (
            <React.Fragment>
                {
                    sortedAndFilteredDailyPriceMovementList.map(dailyPriceMovement => (
                            <div className="list-row-nested list-row-clickable" id={dailyPriceMovement.ticker} style={{ borderBottom: '1px solid #f5f5f5'}} onClick={() => loadDailyPriceMovement(dailyPriceMovement.ticker)}>
                                <div className="list-item-stocks">{dailyPriceMovement.ticker}</div>
                                <div className="list-item-stocks">{dailyPriceMovement.exchange}</div>
                                <div className="list-item-stocks">{dailyPriceMovement.dir_vw_price}</div>
                                <div className="list-item-stocks">{dailyPriceMovement.mov_vw_price}</div>
                            </div>
                    )) //map
                }
                {console.log("selectedTicker:" + selectedTicker)}
                {isModalOpen && <DailyMovementLineChartModal ticker={selectedTicker} isOpen={isModalOpen} onRequestClose={() => {setIsModalOpen(false);}} />}
              </React.Fragment>
          );
    }
    
    return (
                <React.Fragment>
                    <PriceTrendsFilterComponentChain filterOptions={filterOptions}  handleAllFiltersChange={handleAllFiltersChange}/>
                    <ProgressBar></ProgressBar>
                    <h5>Rows: {sortedAndFilteredDailyPriceMovementList.length}</h5>
                    {DailyPriceMovementListHeader()}
                    {displayDailyPriceMovementList()}
                </React.Fragment>
            )
}

export default DailyPriceMovementList;