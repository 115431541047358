import { useNavigate } from 'react-router-dom';
import ClickableLogo from '../components/ClickableLogo';


const ResourceNotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className="error-container">
            <ClickableLogo />
                <h2>Resource you are looking for does not exist.</h2>
        </div>
    )
}

export default ResourceNotFoundPage