// import React from 'react';
import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import MajorMarketIndexFunds from "home/components/MajorMarketIndexFunds";
import UpcomingTradesList from "home/components/UpcomingTradesList";
import OpenTradesListLive from "home/components/OpenTradesListLive";
import LatestTradesDeltaForTrendingTickers from "tickertrades/LatestTradesDeltaForTrendingTickers";
import Collapsible from "common/components/CollapsibleComponent";

import Header from "common/components/Header";



const TrendingTradesPage = ({}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    return (
        <div className="content">
            <Header />
            {/* <Collapsible title="Trading Metrics">
                <LatestTradesDeltaForTrendingTickers />
            </Collapsible> */}
            <Collapsible title="Stocks Watchlist">
                <UpcomingTradesList />
            </Collapsible>
            <hr></hr>
            <OpenTradesListLive/>
            <hr></hr>
            {/* <Collapsible title="Major Market Index Funds">
                    <MajorMarketIndexFunds/>
            </Collapsible> */}
        </div>
    );   
}

export default TrendingTradesPage;