import NavBar from './NavBar';
import Logo from './Logo';

const Header = () => {
    return (
        <header>
            <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,700i%7CYanone+Kaffeesatz:300,700%7CNova+Mono"></link>
            <Logo />
            <NavBar />
        </header>
    )
}

export default Header