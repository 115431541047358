import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import IntradayTradeAnalysis from "tickertrades/components/IntradayTradeAnalysis"
import { getDataFromAPI, getTickerDetailsEndpoint } from 'utils/WT3ApiUtil'

import { getQueryParam} from "utils/WT3ApiUtil"

import Header from "common/components/Header";

const IntradayTradeAnalysisPage = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { ticker } = useParams();

    const [tickerDetails, setTickerDetails] = useState([])

    let dateStr = getQueryParam(location, 'date');

    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                console.log(tickerDetailsEndPoint);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;

        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }
        

    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <IntradayTradeAnalysis ticker={ticker} date={dateStr} showCrumb={true}/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default IntradayTradeAnalysisPage