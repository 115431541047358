import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getDataFromAPI, getTickerDetailsEndpoint} from 'utils/WT3ApiUtil'
import { TICKER_DAILY_MOVEMENT, TICKER_DAILY_DIRECTION, DAILY_DIVERGENCE_END_POINT, DERIVED_DIVERGENCE_END_POINT } from "utils/WT3ApiConstants"
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import DailyMovementLineChart from "tickertrades/components/DailyMovementLineChart"
import TimeRangeRadioList from "analysis/components/DailyAggregatesTimeRangeRadioList";

import {CloseButton} from 'admin/styles/StyleComponents'
import {Bars} from 'react-loader-spinner';

import Header from "common/components/Header";
import Modal from 'react-modal';

export  const datasetItems = [
    { key: 'dir_vw_price', label: 'Price', borderColor: 'rgb(94, 124, 208)', borderWidth: 2, pointRadius: 1},
    { key: 'dir_volume', label: 'Volume', borderColor: 'rgb(255, 127, 80)', borderWidth: 2, pointRadius: 1},
    { key: 'dir_density', label: 'Density', borderColor: 'rgb(175, 195, 21)', borderWidth: 2, pointRadius: 1.5},
    // { key: 'dir_rsi', label: 'RSI', borderColor: 'rgb(4, 214, 208)', borderWidth: 2, pointRadius: 1.5},
    { key: 'mov_vw_price', label: 'Price', borderColor: 'rgb(94, 208, 178)', borderWidth: 2, pointRadius: 1.5},
    { key: 'mov_volume', label: 'Volume', borderColor: 'rgb(255, 127, 80)', borderWidth: 2, pointRadius: 1.5},
    { key: 'mov_density', label: 'Density', borderColor: 'rgb(175, 195, 21)', borderWidth: 2, pointRadius: 1.5},
    { key: 'div_daily', label: 'Daily Div.', borderColor: 'rgb(75, 95, 210)', borderWidth: 2, pointRadius: 1.5},
    { key: 'div_derived', label: 'Derived Div.', borderColor: 'rgb(15, 65, 110)', borderWidth: 2, pointRadius: 1.5},
];

export const fetchDailyDirection = async (ticker, timeRange, navigate) => {
    let dailyDirectionEndPoint = `${TICKER_DAILY_DIRECTION}/${ticker}?window=${timeRange}`;
    
    console.log("dailyDirectionEndPoint:" + dailyDirectionEndPoint);

    const response = await getDataFromAPI(dailyDirectionEndPoint, navigate);
    if (response && response['updated_tickers'] > 0) {
        return response['data']; // Return the fetched data
    }
    return null; // Return null if no data is fetched
};

export const fetchDailyMovement = async (ticker, timeRange, navigate) => {
    let dailyMovementEndPoint = `${TICKER_DAILY_MOVEMENT}/${ticker}?window=${timeRange}`;

    const response = await getDataFromAPI(dailyMovementEndPoint, navigate);
    if (response && response['updated_tickers'] > 0) {
        return response['data']; // Return the fetched data
    }
    return null; // Return null if no data is fetched
};

export const fetchDailyDivergence = async (ticker, timeRange, navigate) => {
    let tickerDailyAggregatesEndPoint = DAILY_DIVERGENCE_END_POINT.replace("[ticker]", ticker);
    tickerDailyAggregatesEndPoint = `${tickerDailyAggregatesEndPoint}?window=${timeRange}`
    console.log("tickerDailyAggregatesEndPoint:" + tickerDailyAggregatesEndPoint);

    const response = await getDataFromAPI(tickerDailyAggregatesEndPoint, navigate);
    if (response) {
        console.log(response['data']);
        return response['data'];
    }
    return null; // Return null if no data is fetched
};

export const fetchDerivedDivergence = async (ticker, timeRange, navigate) => {
    let tickerDerivedAggregatesEndPoint = `${DERIVED_DIVERGENCE_END_POINT.replace("[ticker]", ticker)}?window=${timeRange}`
    console.log("tickerDerivedAggregatesEndPoint:" + tickerDerivedAggregatesEndPoint);

    const response = await getDataFromAPI(tickerDerivedAggregatesEndPoint, navigate);
    if (response) {
        return response['data'];
    }
    return null; // Return null if no data is fetched
};

export const fetchAndCombineData = async (ticker, selectedTimeRange, navigate) => {
    try {
        const [directionData, movementData, dailyDivergenceData, derivedDivergence] = await Promise.all([
            fetchDailyDirection(ticker, selectedTimeRange, navigate),
            fetchDailyMovement(ticker, selectedTimeRange, navigate),
            fetchDailyDivergence(ticker, selectedTimeRange, navigate),
            fetchDerivedDivergence(ticker, selectedTimeRange, navigate)
        ]);

        if (directionData && movementData && dailyDivergenceData && derivedDivergence) {
            return directionData.map(dirItem => {
                const movItem = movementData.find(mov => mov.date === dirItem.date);
                const dailyDivItem = dailyDivergenceData.find(div => div.date === dirItem.date);
                const derivedDivItem = derivedDivergence.find(div => div.date === dirItem.date);

                return {
                    date: dirItem.date,
                    dir_density: dirItem.density,
                    dir_volume: dirItem.volume,
                    dir_vw_price: dirItem.vw_price,
                    mov_density: movItem ? movItem.density : null,
                    mov_volume: movItem ? movItem.volume : null,
                    mov_vw_price: movItem ? movItem.vw_price : null,
                    div_daily: dailyDivItem ? dailyDivItem.divergence : null,
                    div_derived: derivedDivItem ? derivedDivItem.divergence : null
                };
            });
        }
        return [];
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};


const DailyMovementLineChartModal = ({ ticker, isOpen, onRequestClose }) => {
    const [momentDirectionData, setMomentDirectionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTimeRange, setSelectedTimeRange] = useState("150");

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const combinedData = await fetchAndCombineData(ticker, selectedTimeRange, navigate);
                setMomentDirectionData(combinedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isOpen) {
            fetchData();
        }
    }, [ticker, isOpen, location, navigate]);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',  // Adjust the width as needed
          height: '60%', // Adjust the height as needed
          overflow: 'auto' // Add scroll if content is too long
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
            <h2>{ticker}</h2>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Bars
                        height="100"
                        width="100"
                        color="#f99200"
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>
            ) : (
                momentDirectionData.length > 0 && (
                    <React.Fragment>
                        <DailyMovementLineChart dailyMovements={momentDirectionData} datasetItems={datasetItems} initialOption="mov_vw_price" />
                        <CloseButton onClick={onRequestClose}>&times;</CloseButton>
                    </React.Fragment>
                )
            )}
        </Modal>
    );
};

const DailyPriceMovementPage = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedTimeRange, setSelectedTimeRange] = useState("150");

    const { ticker } = useParams();

    const [momentDirectionData, setMomentDirectionData] = useState([]);
    const [tickerDetails, setTickerDetails] = useState([])

    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;
    
        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }

        // Handle exchange changes
        const handleTimeRangeOptionChange = (timeRangeOption) => {
            setMomentDirectionData([]);
            setSelectedTimeRange(timeRangeOption);
        };

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    
    useEffect(() => {
        const fetchData = async () => {
            showProgress();
            setIsLoading(true)
            const combinedData = await fetchAndCombineData(ticker, selectedTimeRange, navigate);
            setMomentDirectionData(combinedData);
            hideProgress();
            setIsLoading(false)
        };

        fetchData();
    }, [ticker, selectedTimeRange, location, navigate]);

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading ticker trades for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTickerDailyMovementEmpty = (momentDirectionData.length === 0);

        if (( isLoading == false 
            && (momentDirectionData == undefined) || isTickerDailyMovementEmpty === true)) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No daily movements fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <ProgressBar />
                    {DisplayMessageIfNoTickerTrades()}
                    <div>
                        <TimeRangeRadioList selectedOption={selectedTimeRange} handleOptionChange={handleTimeRangeOptionChange}/>
                        <div>
                            {momentDirectionData !== undefined && Object.keys(momentDirectionData).length > 0 &&
                                <DailyMovementLineChart dailyMovements={momentDirectionData} datasetItems={datasetItems} initialOption="mov_vw_price"></DailyMovementLineChart>
                            }
                        </div> 
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export { DailyPriceMovementPage, DailyMovementLineChartModal };
