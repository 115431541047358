import React, { useState, useEffect, useMemo } from "react"
import { useNavigate} from 'react-router-dom';
import {TRENDS_TRADES_CORRELATIONS_ENDPOINT} from 'utils/WT3ApiConstants'
import { getDataFromAPI } from 'utils/WT3ApiUtil'
import Header from "common/components/Header";
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import TradeAutoCorr from "admin/components/TradeAutoCorr";
import ExchangeRadioList from 'admin/components/ExchangeRadioList'; // Import the TrendCheckboxes component
import ServiceNotAvailable from 'common/pages/ServiceNotAvailable'

import 'admin/styles/resonsive_list.css';


const PriceTrendsTradesCorrListPage = () => {

    console.log('Rendering PriceTrendTradeCorrsTradesCorrelations.....');

    const navigate = useNavigate();

    const numColumns = 13; // Adjust based on your data or calculations
    const baseFontSize = 8; // Base font size in pixels
    let fontSize = Math.max(baseFontSize - numColumns, 12); // Reduce font size as columns increase
    const columnStyle = {
      fontSize: `${fontSize}px`
  };

    const [tradeAutoCorrList, setTradeAutoCorrList] = useState([])
    const [filteredTradeAutoCorrList, setFilteredTradeAutoCorrList] = useState([]); // Filtered data
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [selectedExchange, setSelectedExchange] = useState('NYSE');

    const getpriceTrendTradeCorrsEndpoint = (exchange) => {
      return `${TRENDS_TRADES_CORRELATIONS_ENDPOINT}?exchange=${exchange}`;
    };
        
    const [filterOptions, setFilterOptions] = useState({
      ticker: { value: '', rule: 'eq', name: 'Ticker' }
    });

    const sortedAndFilteredPriceTrendTradeCorrs = useMemo(() => {
      // Check if priceTrendTradeCorrs is empty
      if (!filteredTradeAutoCorrList || filteredTradeAutoCorrList.length === 0) {
        // Return priceTrendTradeCorrs as is or an empty array
        return filteredTradeAutoCorrList || [];
      }

      return [...filteredTradeAutoCorrList].sort((a, b) => {
          if (sortConfig.key === null) return 0;

          if (sortConfig.direction === 'asc') {
              return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
              return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
      });
  }, [filteredTradeAutoCorrList, sortConfig]);
  

  useEffect(() => {
    
    const applyFilters = () => {
      if (tradeAutoCorrList == undefined || tradeAutoCorrList.length === 0) {
        console.log('No price trends data available.');
        setFilteredTradeAutoCorrList([]);
        return;
      }
    
      let filteredData = tradeAutoCorrList.filter((trend) => {
        return Object.keys(filterOptions).every(key => {
          if (!trend.hasOwnProperty(key) || filterOptions[key].value === '') return true;
          
          const filterType = typeof trend[key];
          const filterValue = filterType === 'number' ? parseFloat(filterOptions[key].value) : filterOptions[key].value;
        
          // Check for NaN values in filterValue when it's supposed to be a number
          if (filterType === 'number' && isNaN(filterValue)) {
            console.log(`NaN encountered for ${key}`);
            return true;
          }
    
          let result;
          switch (filterOptions[key].rule) {
            case 'eq':
              result = trend[key] === filterValue;
              break;
            case 'gte':
              result = filterType === 'number' && trend[key] >= filterValue;
              break;
            case 'lte':
              result = filterType === 'number' && trend[key] <= filterValue;
              break;
            default:
              result = true;
          }
    
          return result;

        });
      });    
      setFilteredTradeAutoCorrList(filteredData);
    };
  
    applyFilters();
  }, [filterOptions, tradeAutoCorrList]);
  
  
    const displayPriceTrendTradeCorrHeader = () => {
        return(
            <div className="list-header-corr" style={columnStyle}>
                <div className="list-header-item-corr" onClick={() => requestSort('Ticker')}>Ticker</div>
                <div className="list-header-item-corr" onClick={() => requestSort('auto_r1')}>Return(1)</div>
                <div className="list-header-item-corr" onClick={() => requestSort('auto_r3')}>Return(3)</div>
                <div className="list-header-item-corr" onClick={() => requestSort('auto_r5')}>Return(5)</div>
              </div>
          )
    }

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };


        // Handle exchange changes
        const handleExchangeOptionChange = (exchangeOption) => {
          setTradeAutoCorrList([]);
          setSelectedExchange(exchangeOption);
        };     

        const handleAllFiltersChange = (filterConfigs) => {
          let filteredData = [...tradeAutoCorrList]; // Replace with your original data
        
          filterConfigs.forEach(filterConfig => {
            if (filterConfig.key && filterConfig.value) {
              const filterValue = parseFloat(filterConfig.value);
              if (filterConfig.rule === 'gte') {
                filteredData = filteredData.filter(item => item[filterConfig.key] >= filterValue);
              } else if (filterConfig.rule === 'lte') {
                filteredData = filteredData.filter(item => item[filterConfig.key] <= filterValue);
              } else if (filterConfig.rule === 'eq') {
                filteredData = filteredData.filter(item => item[filterConfig.key].toString().startsWith(filterConfig.value));
              }
            }
          });

          setFilteredTradeAutoCorrList(filteredData); // Update the state with the filtered data
        };
        
        
        useEffect(() => {          
            const fetchpriceTrendTradeCorrs = async () => {
              showProgress();
              console.log(`fetching correlations for ${selectedExchange}`)
              const priceTrendTradeCorrsEndpoint = getpriceTrendTradeCorrsEndpoint(selectedExchange);
              console.log("Fetching price trend correlations: " + priceTrendTradeCorrsEndpoint);
              const priceTrendTradeCorrsResponse = await getDataFromAPI(priceTrendTradeCorrsEndpoint, navigate);
              // console.log(priceTrendTradeCorrsResponse);
              if (priceTrendTradeCorrsResponse == undefined) {
                return <ServiceNotAvailable/ >
              } 
              setTradeAutoCorrList(priceTrendTradeCorrsResponse);
              hideProgress();     
            };

            fetchpriceTrendTradeCorrs();
            // console.log(`Correlations fetched: ${tradeAutoCorrList}`)

          }, [selectedExchange]);
          
    return (
    <div className="content">
        <Header />
        <ExchangeRadioList selectedOption={selectedExchange} handleOptionChange={handleExchangeOptionChange} />
        <div className="list-container">
                <ProgressBar />
                {displayPriceTrendTradeCorrHeader()}
                <TradeAutoCorr tradesAutoCorrList={sortedAndFilteredPriceTrendTradeCorrs} columnStyle={columnStyle}/>
        </div>        
    </div>
    )
}
export default PriceTrendsTradesCorrListPage;