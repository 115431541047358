export const TRENDING_TRADES_END_POINT = "/wt3-api/trades/trending";

export const RECENT_AGGREGATES_END_POINT= "/wt3-api/[aggregates_type]/aggregates/recent/[ticker]"

export const DAILY_DIVERGENCE_END_POINT= "/wt3-api/daily/divergence/[ticker]"

export const INTRADAY_DIVERGENCE_END_POINT= "/wt3-api/intraday/divergence/[ticker]"

export const DERIVED_DIVERGENCE_END_POINT= "/wt3-api/derived/divergence/[ticker]"

export const INTRADAY_AGGREGATES_END_POINT= "/wt3-api/aggregates/intraday"

export const CURRENT_AGGREGATES_END_POINT= "/wt3-api/aggregates/current"

export const LATEST_AGGREGATES_END_POINT= "/wt3-api/aggregates/latest"

export const SUBMIT_USER_FEEDBACK_END_POINT= "/wt3-api/user/feedback"

export const FEEDBACK_LIST_END_POINT= "/wt3-api/user/feedback"

export const TRADES_SUMMARY_ENDPOINT= "/wt3-api/trades/summary"

export const TRENDS_TRADES_CORRELATIONS_ENDPOINT= "/wt3-api/correlations/trends"

export const LATEST_DELTA_TICKER_TRADES_ENDPOINT= "/wt3-api/tickertrades/delta"

export const TICKER_TRADES_COUNT_ENDPOINT= "/wt3-api/tickertrades/count"

export const TICKER_TRADES_USER_ACTION_ENDPOINT= "/wt3-api/tickertrades"

export const TICKER_TRADES_IMPORT_ENDPOINT = "/wt3-api/tickertrades/import"

export const LATEST_TRADE_POSITIONS = "/wt3-api/trade-positions/latest"

export const TICKER_TRADE_POSITIONS = "/wt3-api/trade-positions"

export const TICKER_DAILY_MOVEMENT = "/wt3-api/daily/movement"

export const TICKER_DAILY_DIRECTION = "/wt3-api/daily/direction"

export const TICKER_INTRADAY_MOVEMENT = "/wt3-api/intraday/movement"

export const TICKER_INTRADAY_DIRECTION = "/wt3-api/intraday/direction"

export const LATEST_DAILY_MOVEMENTS = "/wt3-api/movement/daily/latest"

export const DAILY_PRICE_MOVEMENT_SUMMARY_ENDPOINT= "/wt3-api/daily/pricemovement/summary"

export const DAILY_PRICE_MOVEMENT_LIST_ENDPOINT= "/wt3-api/daily/pricemovement/list"

export const GENERATE_DAILY_PRICE_MOVEMENT_ENDPOINT = "/wt3-api/daily/pricemovement"
