import './Logo.css';

const Logo = () =>{
        return (
            <div className="logo">
                <img src="/images/logo2.png"></img>
            </div>
        )
};

export default Logo;