import React, { useState } from 'react';

const PriceTrendsCheckboxComponent = ({datasetItems, selectedOptions, onOptionChange}) => {

  return (
    <div className="user-attr filter-attr">
      {datasetItems.map(option => (
          <label className="checkbox-container" key={option.key}>{option.label}
            <input 
              type="checkbox" 
              checked={selectedOptions[option.key]} 
              onChange={() => onOptionChange(option.key)} />
            <span className="options-checkmark"></span>
          </label>
      ))}
    </div>
  );
};

export default PriceTrendsCheckboxComponent;