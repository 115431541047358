import React, { useState, useEffect } from "react"

import { useNavigate} from 'react-router-dom';
import { formatAsFloat, formatToTimeString } from '../../utils/WT3DisplayUtil'
import { TRENDING_TRADES_END_POINT } from "utils/WT3ApiConstants"
import { getDataFromAPI } from 'utils/WT3ApiUtil'
import ChartPriceMovementIntraday from 'home/components/ChartPriceMovementIntraday'

const PastTradesList = ({date}) => {

    const navigate = useNavigate();
    const [pastTrades, setPastTrades] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState(null);
    const [tradingDate, setTradingDate] = useState(null);


    const fetchPastTrades = async () => {
        console.log(`Fetching past trades for date: ${date}`);
        let pastTradesByDateEndpoint = `${TRENDING_TRADES_END_POINT}?status=closed&date=${date}`;
        const response = await getDataFromAPI(pastTradesByDateEndpoint, navigate);
        if (response) {
            setPastTrades(response['data']);           
        }
    };

    useEffect(() => {
        fetchPastTrades();
    }, []);

    const PastTradesListHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header-no-sort"><span>Ticker</span></div>
                <div className="list-item-header-no-sort"><span>Exchange</span></div>
                <div className="list-item-header-no-sort"><span>Market Cap</span></div>
                <div className="list-item-header-no-sort"><span>Buy Price</span></div>
                <div className="list-item-header-no-sort"><span>Sell Price</span></div>
                <div className="list-item-header-no-sort"><span>Opened at</span></div>
                <div className="list-item-header-no-sort"><span>Closed at</span></div>
                <div className="list-item-header-no-sort"><span>% Change</span></div>
            </div>
          )
    }
    
    const getIntradayTickerTradesUrl = (ticker) => {
        const intraDayTradeDeltaUrl = `/intraday/${ticker}?from=/past-picks/${ticker}&date=${date}`;
        return (
            <a href={intraDayTradeDeltaUrl} target="_blank" className="custom-chart-title" style={{color: "#013A6F", fontWeight: "bold", fontSize: "14px", fontFamily: "Arial, sans-serif", marginBottom: "10px"}}>
                {ticker}
            </a>
        );
    };
    
    const loadIntradayChart = (ticker, date) => {
        console.log(`Loading intraday chart for ${ticker}, ${date}`);
        setIsModalOpen(true);
        setSelectedTicker(ticker);
        setTradingDate(date);
    }

    const displayPastTradesList = () => {    
        if (pastTrades == undefined || pastTrades.length < 1) {
            return '';
        }
        return (
            <React.Fragment>
                {
                    pastTrades.map(stockTrade => (
                            <div className="list-row-nested list-row-clickable" key={stockTrade.order_id} id={stockTrade.ticker} style={{ borderBottom: '1px solid #f5f5f5'}} onClick={() => loadIntradayChart(stockTrade.ticker, date)}>
                                <div className="list-item-stocks">{getIntradayTickerTradesUrl(stockTrade.ticker)}</div>
                                <div className="list-item-stocks">{stockTrade.exchange}</div>
                                <div className="list-item-stocks">{stockTrade.market_cap}</div>
                                <div className="list-item-stocks">{stockTrade.open}</div>
                                <div className="list-item-stocks">{stockTrade.last_price}</div>
                                <div className="list-item-stocks">{formatToTimeString(stockTrade.open_dt)}</div>
                                <div className="list-item-stocks">{formatToTimeString(stockTrade.last_updated)}</div>
                                <div className="list-item-stocks">{formatAsFloat(stockTrade.pct_price_chg, 2)}</div>        
                            </div>
                        
                    )) //map
                }
                {isModalOpen && <ChartPriceMovementIntraday ticker={selectedTicker} date={tradingDate} isOpen={isModalOpen} onRequestClose={() => {setIsModalOpen(false);}} />}
              </React.Fragment>
          );
    }
    
    return (
                <React.Fragment>
                    {PastTradesListHeader()}
                    {displayPastTradesList()}
                </React.Fragment>
            )
}

export default PastTradesList;