import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LogInOauthPage from 'common/pages/LogInOauthPage';
import UserListPage from 'admin/pages/UserListPage';
import UserEditPage from 'admin/pages/UserEditPage';
import SignUpPage from 'common/pages/SignUpPage'
import ServerErrorPage from 'common/pages/ServerErrorPage'
import ServiceNotAvailable from 'common/pages/ServiceNotAvailable'
import ResourceNotFound from 'common/pages/ResourceNotFound'
import ResourceRestrictedPage from 'common/pages/ResourceRestrictedPage'

import TrendingTradesLandinPage from 'home/TrendingTradesLandinPage';
import PastTradesSummary from 'home/PastTradesSummary';
import TrendingTradesAdminPage from 'admin/pages/TrendingTradesAdminPage';

import PriceTrendsOverviewListPage from 'admin/pages/PriceTrendsOverviewListPage'
import PriceTrendsTradesCorrListPage from 'admin/pages/PriceTrendsTradesCorrListPage'
import TradesAutoCorrListPage from 'admin/pages/TradesAutoCorrListPage'

import LatestTradesDeltaForTrendingTickers from 'tickertrades/LatestTradesDeltaForTrendingTickers'
import IntradayTradeAnalysisPage from 'tickertrades/IntradayTradeAnalysisPage'

import LatestTradePositionsListPage from 'tickertrades/LatestTradePositionsListPage'
import TickerTradePositionsPage from 'tickertrades/TickerTradePositionsPage'

import TickerDailyVolumeMovementPage from 'tickertrades/TickerDailyVolumeMovementPage'

import TickerIntradayMovementPage from 'tickertrades/TickerIntradayMovementPage'
import LatestDailyMovementsListPage from 'tickertrades/LatestDailyMovementsListPage.js'
import PriceTrendsSeriesPage from 'admin/pages/PriceTrendsSeriesPage'

import {DailyPriceMovementPage} from 'analysis/DailyPriceMovementPage'
import {DailyAggregatesPage} from 'analysis/DailyAggregatesPage'
import {DailyDivergencePage} from 'analysis/DailyDivergencePage'
import {IntradayDivergencePage} from 'analysis/IntradayDivergencePage'
import {DerivedDivergencePage} from 'analysis/DerivedDivergencePage'

import TickerIntradayTrendsPage from 'analysis/TickerIntradayTrendsPage'


// import DailyTradePlacementPage from 'trading/DailyTradePlacementPage';
import DailyPriceMovementSummaryListPage from 'analysis/DailyPriceMovementSummaryListPage';

import PrivacyPolicy from 'common/pages/PrivacyPolicy';
import About from 'common/pages/About';

import UserFeedbackForm from 'common/pages/UserFeedback';
import FeedbackListPage from 'admin/pages/FeedbackListPage';

import MessagesComponent from 'POCPusherClient'

import reportWebVitals from 'reportWebVitals'

import './App.css';

function App() {
  return (
    <div className="page-container">
      <BrowserRouter>
        <Routes>
          {/* <Route path = "/stream" element = {<TestStream />} /> */}

          <Route path = "/login" element = {<LogInOauthPage />} />
          <Route path = "/poc_pusher" element = {<MessagesComponent />} />
          <Route path = "/signup" element = {<SignUpPage />} />
          <Route path = "/users" element = {<UserListPage />} />
          <Route path = "/users/:userId" element = {<UserEditPage />} />
          <Route path = "/serviceNotAvailable" element = {<ServiceNotAvailable />} />
          <Route path = "/serverError" element = {<ServerErrorPage />} />
          <Route path = "/restricted" element = {<ResourceRestrictedPage />} />
          <Route path = "/notfound" element = {<ResourceNotFound />} />
          <Route path = "/" element = {<TrendingTradesLandinPage />} />


          <Route path = "/trades" element = {<TrendingTradesAdminPage />} />
          <Route path = "/past-picks" element = {<PastTradesSummary />} /> 

          
          <Route path = "/trends" element = {<PriceTrendsOverviewListPage />} />
          <Route path = "/corrs" element = {<PriceTrendsTradesCorrListPage />} />
          <Route path = "/autocorrs" element = {<TradesAutoCorrListPage />} />
          <Route path = "/trends/:ticker" element = {<PriceTrendsSeriesPage />} />
          <Route path = "/intraday" element = {<LatestTradesDeltaForTrendingTickers />} />
          <Route path = "/intraday/:ticker" element = {<IntradayTradeAnalysisPage />} />
          <Route path = "/positions" element = {<LatestTradePositionsListPage />} />
          <Route path = "/positions/:ticker" element = {<TickerTradePositionsPage />} />
          <Route path = "/movements" element = {<LatestTradePositionsListPage />} /> 
          <Route path = "/movement/daily" element = {<LatestDailyMovementsListPage />} />
          <Route path = "/movement/volume/:ticker" element = {<TickerDailyVolumeMovementPage />} />
         

          <Route path = "/daily/pricemovement/:ticker" element = {<DailyPriceMovementPage />} />
          <Route path = "/daily/aggregates/:ticker" element = {<DailyAggregatesPage />} />

          <Route path = "/daily/divergence/:ticker" element = {<DailyDivergencePage />} />
          <Route path = "/intraday/divergence/:ticker" element = {<IntradayDivergencePage />} />
          <Route path = "/derived/divergence/:ticker" element = {<DerivedDivergencePage />} />

          <Route path = "/trends/intraday/:ticker" element = {<TickerIntradayTrendsPage />} />
          
          <Route path = "/daily/pricemovement/summary" element = {<DailyPriceMovementSummaryListPage />} />

          
          <Route path = "/intraday/pricemovement/:ticker" element = {<TickerIntradayMovementPage />} />

          <Route path = "/privacy-policy" element = {<PrivacyPolicy />} />
          <Route path = "/about" element = {<About />} />
          <Route path = "/feedback" element = {<UserFeedbackForm />} /> 
          <Route path = "/feedbackList" element = {<FeedbackListPage />} />  
          <Route path = "server-status" element = {reportWebVitals} />        
          <Route path = "*" element = {<ResourceNotFound />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
