import React from "react";

const TimeRangeRadioList = ({ selectedOption, handleOptionChange, displayType }) => {
  return (
    <div>
      <h4>TimeRanges</h4>
      <div className="user-attr filter-attr">
      {(("overview") === displayType) && (
        <React.Fragment>
          <label className="radio-container">1 Day
            <input type="radio" name="timerange" value="1" 
                  checked={selectedOption === '1'} 
                  onChange={() => handleOptionChange('1')} />
            <span className="options-radio"></span>
          </label>

          <label className="radio-container">1 Week
          <input type="radio" name="timerange" value="5" 
                checked={selectedOption === '5'} 
                onChange={() => handleOptionChange('5')} />
          <span className="options-radio"></span>
          </label>

          <label className="radio-container">2 Weeks
            <input type="radio" name="timerange" value="10" 
                  checked={selectedOption === '10'} 
                  onChange={() => handleOptionChange('10')} />
            <span className="options-radio"></span>
          </label>

        </React.Fragment>
      )}
        <label className="radio-container">1 Month
          <input type="radio" name="timerange" value="20" 
                 checked={selectedOption === '20'} 
                 onChange={() => handleOptionChange('20')} />
          <span className="options-radio"></span>
        </label>
        {(("series") === displayType) && (
          <label className="radio-container">2 Months
            <input type="radio" name="timerange" value="40" 
                  checked={selectedOption === '40'} 
                  onChange={() => handleOptionChange('40')} />
            <span className="options-radio"></span>
          </label>
        )}  
        {(("series") === displayType) && (
          <React.Fragment>
            <label className="radio-container">3 Months
              <input type="radio" name="timerange" value="60" 
                    checked={selectedOption === '60'} 
                    onChange={() => handleOptionChange('60')} />
              <span className="options-radio"></span>
            </label>
            <label className="radio-container">All
              <input type="radio" name="timerange" value="90" 
                    checked={selectedOption === '90'} 
                    onChange={() => handleOptionChange('90')} />
              <span className="options-radio"></span>
            </label>            
          </React.Fragment>
        )}       
      </div>
      
    </div>
  );
};

export default TimeRangeRadioList;