import React, { useState } from 'react';

import 'admin/styles/filters.css';

const PriceTrendsFilterComponent = ({ filters, handleFilterChange }) => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [selectedRule, setSelectedRule] = useState('gte'); // 'gte' for '>=', 'lte' for '<='

  const handleSelectedFilterChange = (e) => {
    const filterName = e.target.value;
    setSelectedFilter(filterName);
   setFilterValue(filters[filterName]?.value || '');
   setSelectedRule(filters[filterName]?.rule || 'gte');
  };
  
  const handleFilterValueChange = (e) => {
    setFilterValue(e.target.value);
    handleFilterChange(selectedFilter, e.target.value, selectedRule);
  };

  const handleRuleChange = (e) => {
    const newRule = e.target.value;
    setSelectedRule(newRule);
    handleFilterChange(selectedFilter, filterValue, newRule);
  };

  
  return (
    <div>
      <div className="filter-container">
          <select className="filter-dropdown"
            id="filter-select"
            value={selectedFilter} 
            onChange={handleSelectedFilterChange}
          >
            <option value="">Add a filter...</option>
            {Object.keys(filters).map((key) => (
              <option key={key} value={key}>{filters[key].name}</option>
            ))}
          </select>
          <select className="rule-dropdown"
            value={selectedRule}
            onChange={handleRuleChange}>
            <option value="gte">gte</option>
            <option value="lte">lte</option>
            <option value="eq">eq</option>
          </select>          
          <input className="filter-value"
            id="filter-value"
            value={filterValue} 
            onChange={handleFilterValueChange}
            disabled={!selectedFilter}
          />
      </div>
    </div>
  );
};

export default PriceTrendsFilterComponent;
