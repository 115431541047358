 import React, { useState, useEffect, useRef } from "react"
import { useNavigate} from 'react-router-dom';
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { getDataFromAPI} from "../../utils/WT3ApiUtil"
import { CURRENT_AGGREGATES_END_POINT } from "../../utils/WT3ApiConstants"


const ChartPriceMovementLive = ({stockTrade}) => {

    const chartComponentRef = useRef(null);

    const [currentAggregates, setCurrentAggregates] = useState([]);
    const [chartWidth, setChartWidth] = useState(null);

    const navigate = useNavigate();

    const showLoading = () => {
        if (chartComponentRef.current && chartComponentRef.current.chart) {
            chartComponentRef.current.chart.showLoading();
        }
    };

    const hideLoading = () => {
        if (chartComponentRef.current && chartComponentRef.current.chart) {
            chartComponentRef.current.chart.hideLoading();
        }
    };
    const fetchCurrentAggregates = async (ticker) => {
        console.log(`Fetching current aggregates for ${ticker}`);
        showProgress();
        showLoading();
        let currentAggregatesEndpoint = `${CURRENT_AGGREGATES_END_POINT}?ticker=${ticker}&start_date=${stockTrade.open_dt}`
        try {
            const response = await getDataFromAPI(currentAggregatesEndpoint, navigate);
            if (response) {
                setCurrentAggregates(response['data']);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // handle error scenario
        } finally {
            hideLoading(); // Hide loading after fetching data
            hideProgress();
        }
    };

    const updateCurrentAggregates = async (ticker) => {
        console.log(`Fetching current aggregates for ${ticker}`);
        let currentAggregatesEndpoint = `${CURRENT_AGGREGATES_END_POINT}?ticker=${ticker}&start_date=${stockTrade.last_updated}`
        try {
            const response = await getDataFromAPI(currentAggregatesEndpoint, navigate);
            if (response) {
                // Append new data to the existing current aggregates
                setCurrentAggregates(prevAggregates => [
                    ...prevAggregates,
                    ...response['data']
                ]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // handle error scenario
        } finally {
        }
    };    

    useEffect(() => {
        fetchCurrentAggregates(stockTrade.ticker);

        // Set an interval to fetch latest stock data
        const intervalId = setInterval(() => {
            updateCurrentAggregates(stockTrade.ticker);
        }, 1000); // Adjust the interval as needed

        // Clean up the interval
        return () => clearInterval(intervalId);
        
    }, [stockTrade.ticker]);

    useEffect(() => {
        const handleResize = () => {
            if (chartComponentRef.current) {
                setChartWidth(chartComponentRef.current.container.current.offsetWidth);
            }
        };
        // Set initial size
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
           
    const drawPriceMovements = (price_series) => {
        let previousDate = null;
        const timezoneOffset = 5 * 60; // 5 hours in minutes

        // Set the utc to False globally for Highcharts
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        }); 
        
        const options = {
            chart: {
                backgroundColor: '#ddd',
            // Dynamic width based on state
                width: chartWidth,
                height: chartWidth * (2.5 / 4), // Or any other aspect ratio you prefer                
                zooming: {
                    mouseWheel: {
                      enabled: false
                    }
                  },
                  accessibility: {enabled: false}
            },
            title: {
                text: `${stockTrade.ticker} Live Chart`,
                style: {
                    color: '#f99200',
                    fontSize: '18px'
                    
                }                
            },            
            plotOptions: {
                candlestick: {
                    upColor: '#013A6F',
                    opacity: .8,
                    lineColor: '#013A6F',
                    color: '#ffffff'
                },
                line: {
                    color: '#f99200',
                    opacity: 1,
                    lineWidth: .7
                }
            },
            navigator: {
                enabled: false, // Disable the navigator
            },
            scrollbar: {
                enabled: false, // Disable the scrollbar
            },
            rangeSelector: {
                enabled: false, // Disables the date range selector
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter: function() {
                        const currentDate = Highcharts.dateFormat('%b %e', this.value); // Format date as 'Nov 23'
                        const currentTime = Highcharts.dateFormat('%H:%M', this.value);
    
                        if (previousDate !== currentDate) {
                            previousDate = currentDate;
                            return currentDate + ' ' + currentTime; // Display date and time when date changes
                        } else {
                            return currentTime; // Display only time otherwise
                        }
                    }
                }
            },
            tooltip: {
                formatter: function() {
                    const dateStr = Highcharts.dateFormat('%b %e, %I:%M %p', this.x);
                    const valueStr = Highcharts.numberFormat(this.y, 2); // Format value to two decimals
                    return `${dateStr}<br/><b>Close: ${valueStr}</b>`;
                }
            },            
            series: [{
                name: 'Closing Price',
                data: price_series,
                tooltip: {
                    valueDecimals: 2
                }
            }],
            time: {
                timezone: 'America/New_York'
            }
        };
        // In your component's render method
        return (
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
                ref={chartComponentRef}
            />
        );                
    };

      const processDataForHighstock = (data) => {
        return data.map(item => {
            // Extracting the timestamp and the closing price
            return [
                new Date(item.datetime).getTime(), // Convert datetime to Unix timestamp in milliseconds
                item.quote.close // The closing price
            ];
        });
    };
    

    const chartTickerPriceMovements = () => {
        if (currentAggregates == undefined || currentAggregates.length < 1) {
            return "";
        }
        console.log("inside chart price movements......");
        console.log(`Current aggregates fetched for ${stockTrade.ticker}: ${currentAggregates.length}`);   
        if (currentAggregates == undefined || currentAggregates.length < 1) {
            return '';
        }
        let closing_timeseries_data = processDataForHighstock(currentAggregates);
        return drawPriceMovements(closing_timeseries_data);
    }

    return (
                <React.Fragment>
                    <ProgressBar />
                    {chartTickerPriceMovements()}
                    <div id="closing_prices"></div>
                </React.Fragment>
            )
}

export default ChartPriceMovementLive;