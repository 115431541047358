import React, { useState, useMemo } from 'react';
import { useNavigate} from 'react-router-dom';

import Header from "common/components/Header";
import { SUBMIT_USER_FEEDBACK_END_POINT } from "utils/WT3ApiConstants"
import { addAPIData} from "utils/WT3ApiUtil"
import "./Feedback.css"

const FeedbackResponse = ({ htmlContent }) => {
    return <h2 className="feedback-title" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

const UserFeedbackForm = () => {

    const navigate = useNavigate();

    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const allInputsFilled = useMemo(() => {
        return formData.name.trim() !== '' && formData.email.trim() !== '' && formData.message.trim() !== '';
    }, [formData]);

    const [errorMessage, setErrorMessage] = useState('')
    const [feedbackTitle, setFeedbackTitle] = useState('Please share your thoughts about TrendingTrades.')

    const handleInputChange = (e) => {
        setErrorMessage("");
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateName = (name) => {
        return /^[A-Za-z\s]+$/.test(name);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("")
        if (!validateEmail(formData.email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        if (!validateName(formData.name)) {
            setErrorMessage("Name must contain only alphabetic characters.");
            return;
        }

        if (formData.message.trim() === '') {
            setErrorMessage("Uh Oh! Please provide feedback");
            return;
        }
        const userFeedback = {
            name: formData.name,
            email: formData.email,
            message: formData.message
        };

        const response = await  addAPIData(SUBMIT_USER_FEEDBACK_END_POINT, userFeedback, navigate)
        if (response.status == 200) {
            setFormData({ name: '', email: '', message: '' }); // Reset form
            setFeedbackTitle(response["data"])
            console.log(response.data);
        } else {
            setErrorMessage("An error occurred. Please try again later.");
        }
    };

    return (
        <div className="content-container">
            <Header />
            <FeedbackResponse htmlContent={feedbackTitle}/>
            <form onSubmit={handleSubmit} className='feedback-form'>
                {errorMessage && <div className="fail">{errorMessage}</div>}

                <input className="form-input" name="name" value={formData.name}  onChange={handleInputChange} type="text" placeholder="Name" autoComplete="off" />
                <input className="form-input" name="email" value={formData.email}  onChange={handleInputChange} type="text" placeholder="Email" autoComplete="off" />
                <textarea className="form-input form-textarea" name="message" value={formData.message}  onChange={handleInputChange} type="text" placeholder="Feedback" autoComplete="off" maxLength="1000" />
                <button className="button-primary" type="submit" disabled={!allInputsFilled}>Submit</button>
            </form>
        </div>
    );
};

export default UserFeedbackForm;
