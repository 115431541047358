const ExchangeRadioList = ({ selectedOption, handleOptionChange }) => {
  return (
    <div>
      <h4>Exchanges</h4>
      <div className="user-attr filter-attr">
        <label className="radio-container">NYSE
          <input type="radio" name="exchange" value="NYSE" 
                 checked={selectedOption === 'NYSE'} 
                 onChange={() => handleOptionChange('NYSE')} />
          <span className="options-radio"></span>
        </label>
        <label className="radio-container">NASDAQ
          <input type="radio" name="exchange" value="NASDAQ" 
                 checked={selectedOption === 'NASDAQ'} 
                 onChange={() => handleOptionChange('NASDAQ')} />
          <span className="options-radio"></span>
        </label>
        <label className="radio-container">NYSEARCA
          <input type="radio" name="exchange" value="NYSEARCA" 
                 checked={selectedOption === 'NYSEARCA'} 
                 onChange={() => handleOptionChange('NYSEARCA')} />
          <span className="options-radio"></span>
        </label>
        <label className="radio-container">CRYPTO
          <input type="radio" name="exchange" value="CRYPTO" 
                 checked={selectedOption === 'CRYPTO'} 
                 onChange={() => handleOptionChange('CRYPTO')} />
          <span className="options-radio"></span>
        </label>        
      </div>
    </div>
  );
};

export default ExchangeRadioList;