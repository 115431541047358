import React, { useState, useEffect, useMemo} from "react"
import { useNavigate} from 'react-router-dom';
import { DAILY_PRICE_MOVEMENT_SUMMARY_ENDPOINT, GENERATE_DAILY_PRICE_MOVEMENT_ENDPOINT } from "utils/WT3ApiConstants"
import { getDataFromAPI, addAPIData} from "utils/WT3ApiUtil"
import { formatISODateShort} from "utils/WT3DisplayUtil"
import PusherMessageComponent from "common/components/PusherMessageClient"

import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";

import Header from "common/components/Header";

import DailyPriceMovementList from "analysis/DailyPriceMovementListPage"

export const getPriceMovementReferenceDate = () => {
    // Get the current date and time in UTC
    const now = new Date();

    // Convert the current time to EST (UTC-5)
    const offset = -5 * 60; // EST is UTC-5
    const estNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000) + (offset * 60000));

    // Get the current hour in EST
    const estHour = estNow.getUTCHours();

    // Check if the current time is after 5 PM EST
    if (estHour >= 17) {
        // Return the current date in YYYY-MM-DD format
        return estNow.toISOString().split('T')[0];
    } else {
        // Return the previous date in YYYY-MM-DD format
        const previousDay = new Date(estNow);
        previousDay.setDate(estNow.getDate() - 1);
        return previousDay.toISOString().split('T')[0];
    }
};

const DailyPriceMovementGenerationComponent = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [resetTrigger, setResetTrigger] = useState(0);
  
    const navigate = useNavigate();
  
    const generateDailyPriceMovement = async () => {
      setErrorMessage(null);
      let currentDailyPriceMovementRefDate = getPriceMovementReferenceDate();
      const data = {
        ref_date: currentDailyPriceMovementRefDate
      };
      document.querySelectorAll('.button-primary').forEach(button => {
        button.disabled = true;
      });
  
      let generateDailyPriceMovementEndPoint = GENERATE_DAILY_PRICE_MOVEMENT_ENDPOINT;
      console.log(`EndPoint: ${generateDailyPriceMovementEndPoint}, Current reference date: ${currentDailyPriceMovementRefDate}`);
      
      // Trigger the reset before the request
      setResetTrigger(prev => prev + 1);
      showProgress();
      const response = await addAPIData(generateDailyPriceMovementEndPoint, data, navigate);
      if (response.status !== 200) {
        console.log(response["data"]["error"]);
        setErrorMessage(response["data"]["error"]);
      } 
      document.querySelectorAll('.button-primary').forEach(button => {
        button.disabled = false;
      });
      
      if (errorMessage) {
        console.log(`Error generating price movement: ${errorMessage}`);
      } else {
        setSuccessMessage(`Price trends generated for ${currentDailyPriceMovementRefDate}`)
        console.log(`${response["data"]}`);
      }
      hideProgress();
    };
  
    return (
      <React.Fragment>
        <div className="button-container">
          <button className="button-primary" onClick={generateDailyPriceMovement}>Generate Price Movement</button>
        </div>
        <ProgressBar/>
        <PusherMessageComponent resetTrigger={resetTrigger} />
        <div className="fail" style={{ textAlign: "left" }}>{errorMessage}</div>
        <div className="success" style={{ textAlign: "left" }}>{successMessage}</div>
      </React.Fragment>
    );
  }

const DailyPriceMovementSummaryListPage = () => {

    const navigate = useNavigate();

    const [dailyPriceMovementSummaryList, setDailyPriceMovementSummaryList] = useState([]);
    const [dailyPriceMovement, setDailyPriceMovement] = useState(false);
    const [clickedSummary, setClickedSummary] = useState(null);

    const fetchDailyPriceMovementSummaryList = async () => {
        console.log(`Fetching past trades summary`);
        let dailyPriceMovementSummaryListEndpoint = `${DAILY_PRICE_MOVEMENT_SUMMARY_ENDPOINT}`;
        const response = await getDataFromAPI(dailyPriceMovementSummaryListEndpoint, navigate);
        console.log(response);
        if (response && (response.status !== undefined) && response.status != 200) {
        } else {
            setDailyPriceMovementSummaryList(response);
        }
    };

    useEffect(() => {
        fetchDailyPriceMovementSummaryList();
    }, []);

    const displayDailyPriceMovementSummaryListTitle = () => {
        return  <h3 style={{color: '#f99200'}}>Daily Price Movement Summary</h3>
    }

    const dailyPriceMovementSummaryListHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header"><span>Trading Date</span></div>
                <div className="list-item-header"><span>NASDAQ</span></div>
                <div className="list-item-header"><span>NYSE</span></div>
                <div className="list-item-header"><span>NYSEARCA</span></div>        
                <div className="list-item-header"></div>    
            </div>
          )
    }

    const loadDailyPriceMovement = async (tradingDate) => {
        const summaryKey = `${tradingDate}`;
        console.log(`Loading daily price movement for ${tradingDate}`);
        if (clickedSummary === summaryKey) {
            // Toggle the display of trade details
            setDailyPriceMovement(!dailyPriceMovement);
            // If closing the details, reset the clickedSummary
            if (dailyPriceMovement) {
                setClickedSummary('');
            }
            return;
        }
    
        try {
            setDailyPriceMovement(true);
            setClickedSummary(summaryKey); // Set the clicked summary
        } catch (error) {
            console.error('Failed to load trade details', error);
        }
    };

    const displayDailyPriceMovementSummaryList = () => {    
        if (dailyPriceMovementSummaryList == undefined || dailyPriceMovementSummaryList.length < 1) {
            return <h2>No daily price movement summary.</h2>;
        }
        return (
            dailyPriceMovementSummaryList.map(dailyPriceMovementSummary => {
                // Check if clickedSummary matches the current dailyPriceMovementSummary
                const isClicked = clickedSummary === `${dailyPriceMovementSummary.trading_date}`;
                const listItemClass = isClicked ? "list-row list-row-selected" : "list-row";
        
                return (
                    <div className="list-container-nested" key={dailyPriceMovementSummary.date} style={{ borderBottom: '1px solid #f5f5f5'}}>
                        <div className="list-row-clickable list-row" onClick={() => loadDailyPriceMovement(dailyPriceMovementSummary.trading_date)}>
                            <div className="list-item-stocks">{formatISODateShort(dailyPriceMovementSummary.trading_date)}</div>
                            <div className="list-item-stocks">{dailyPriceMovementSummary.NASDAQ}</div>
                            <div className="list-item-stocks">{dailyPriceMovementSummary.NYSE}</div>
                            <div className="list-item-stocks">{dailyPriceMovementSummary.NYSEARCA}</div>
                            <div className="list-item-stocks">
                            <button>
                                { (dailyPriceMovement && clickedSummary === `${dailyPriceMovementSummary.trading_date}`) ? "-" : "+"}
                            </button>
                            </div>
                        </div>
                        {dailyPriceMovement && isClicked && (
                            <DailyPriceMovementList trading_date={dailyPriceMovementSummary.trading_date} />
                        )}

                    </div>
                );
            }) // map
        );        
    }


    return (
                <React.Fragment>
                    <Header />
                    <div className="content">
                        <DailyPriceMovementGenerationComponent/>
                        {displayDailyPriceMovementSummaryListTitle()}
                        {dailyPriceMovementSummaryListHeader()}
                        {displayDailyPriceMovementSummaryList()}
                    </div>
                </React.Fragment>
            )
}

export default DailyPriceMovementSummaryListPage;