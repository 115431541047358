import React, { useState, useEffect, useRef, createRef} from "react"
import { useNavigate} from 'react-router-dom';
import { TRENDING_TRADES_END_POINT } from "utils/WT3ApiConstants"
import { getDataFromAPI} from "utils/WT3ApiUtil"
import { formatISODateShortNoYear, formatAsFloat, toCamelCase } from "utils/WT3DisplayUtil"
import OpenTradeDetailsComponent from "./OpenTradeDetailsComponent";
import TouchEnabledListItem from "common/components/TouchEnabledListItem"
const OpenTradesListLive = () => {

    const navigate = useNavigate();

    const [openTrades, setOpenTrades] = useState([]);
    const [displayTradeDetails, setDisplayTradeDetails] = useState(false);
    const [clickedSummary, setClickedSummary] = useState(null);
    
    const tradeRefs = useRef({});


    const fetchOpenTrades = async () => {
        console.log(`Fetching Open trades`);
        let trendingTradesEndPoint = `${TRENDING_TRADES_END_POINT}?status=open`;
        const response = await getDataFromAPI(trendingTradesEndPoint, navigate);
        if (response) {
            setOpenTrades(response['data']);           
        }
    };

    useEffect(() => {
        fetchOpenTrades();

        // Set an interval to fetch latest stock data
        const intervalId = setInterval(() => {
            fetchLatestStockData();
        }, 5000); // Adjust the interval as needed

        // Clean up the interval
        return () => clearInterval(intervalId);
    }, []);

    // Inside the useEffect for triggering animations
    useEffect(() => {
        openTrades.forEach(trade => {
            if (trade.priceUpdated) {
                trade.priceUpdated = false;
                const element = tradeRefs.current[trade.order_id].current;
                if (element) {
                    triggerFlashAnimation(trade, element);
                }
            }
        });
    }, [openTrades]); // Dependency array with your openTrades state


    // Function to fetch the latest stock data
    const fetchLatestStockData = async () => {
        
        const tickers = openTrades.map(trade => trade.ticker);

        // Replace with your API endpoint and ticker list
        const apiEndpoint = `${TRENDING_TRADES_END_POINT}?status=open`;
        const latestData = await getDataFromAPI(apiEndpoint, navigate);
        const updatedOpenTrades = latestData['data'];

        // Compare and update data if necessary
        if (updatedOpenTrades) {
            setOpenTrades(currentTrades => {
                return currentTrades.map(trade => {
                    const latestTradeData = updatedOpenTrades.find(data => data.ticker === trade.ticker);
                    if (latestTradeData && latestTradeData.last_updated > trade.last_updated && latestTradeData.last_price !== trade.last_price)  {
                        console.log(`Updating trade for ${latestTradeData.ticker} with ${latestTradeData.last_price} / ${latestTradeData.pct_price_chg}`);
                        return { ...trade, ...latestTradeData, priceUpdated: true }; // Update trade data
                    }
                    return trade; // No update, return original trade
                });
            });
        }
    };

    const loadTradeDetails = async (ticker, order_id) => {
        const summaryKey = `${ticker}-${order_id}`;
    
        if (clickedSummary === summaryKey) {
            // Toggle the display of trade details
            setDisplayTradeDetails(!displayTradeDetails);
            // If closing the details, reset the clickedSummary
            if (displayTradeDetails) {
                setClickedSummary('');
            }
            return;
        }
    
        try {
            setDisplayTradeDetails(true);
            setClickedSummary(summaryKey); // Set the clicked summary
        } catch (error) {
            console.error('Failed to load trade details', error);
        }
    };
    
    const displayOpenTradesTitle = () => {
        return  <h3 style={{color: '#f99200'}}>Open Positions</h3>
    }

    const displayOpenTradesListLive = () => {    
        if (openTrades == undefined || openTrades.length < 1) {
            return <h2>No trades in progress right now.</h2>;
        }
        openTrades.forEach(trade => {
            tradeRefs.current[trade.order_id] = tradeRefs.current[trade.order_id] || createRef();
        });
                
        return (
            openTrades.map(stockTrade => {
                // Check if clickedSummary matches the current stockTrade
                const isClicked = clickedSummary === `${stockTrade.ticker}-${stockTrade.order_id}`;
                const listItemClass = isClicked ? "open-trade list-row-selected" : "open-trade";
        
                return (
                    <div className="list-container-nested" style={{ borderBottom: '1px solid #f5f5f5'}}>
                        <div className="list-row-nested" onClick={() => loadTradeDetails(stockTrade.ticker, stockTrade.order_id)}>
                            <TouchEnabledListItem key={stockTrade.order_id} classes={listItemClass}>
                               <div className="company-info">
                                    <div className="ticker_name no-wrap-span">{toCamelCase(stockTrade.company_name)}</div>
                                    <div className="no-wrap-span"><span className="ticker_symbol larger">{stockTrade.ticker}</span>, <span className="ticker_exchange larger">{stockTrade.exchange}</span>
                                    </div>
                                </div>
                                <div className="trade-info">
                                    <div className="open-price">
                                        <span className="no-wrap-span">Opened At: {stockTrade.open} ({formatISODateShortNoYear(stockTrade.open_dt)})</span>
                                    </div>                            
                                    <div ref={tradeRefs.current[stockTrade.order_id]} className="last-price">
                                        <span className="no-wrap-span">
                                            <span>Last Price: {stockTrade.last_price}</span>
                                            <span className= {`${stockTrade.pct_price_chg <= 0 ? 'down' : 'up'}`}>({stockTrade.pct_price_chg}%)</span>
                                        </span>
                                    </div>                         
                                </div>
                                <div className="expand-open-trade">
                                        <button>
                                            { (displayTradeDetails && clickedSummary === `${stockTrade.ticker}-${stockTrade.order_id}`) ? "-" : "+"}
                                        </button>
                                </div>
                            </TouchEnabledListItem>
                        </div>
                            {displayTradeDetails && isClicked && (
                                <OpenTradeDetailsComponent stockTrade={stockTrade} />
                            )}
                    </div>
                );
            }) // map
        );        
    }

    // Function to trigger animation
    const triggerFlashAnimation = (trade, element) => {
        element.classList.remove('updated-positive', 'updated-negative');
        void element.offsetWidth;
        element.classList.add(trade.pct_price_chg > 0 ? 'updated-positive' : 'updated-negative');
    };
    

    return (
                <React.Fragment>
                    {displayOpenTradesTitle()}
                    {displayOpenTradesListLive()}
                </React.Fragment>
            )
}

export default OpenTradesListLive;