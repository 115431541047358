// import React from 'react';
import React from "react"
import { useNavigate} from 'react-router-dom';
import Collapsible from "common/components/CollapsibleComponent";
import './tickerdetails.css';


const TickerDetailsComponent = ({tickerDetails}) => {
    const navigate = useNavigate();

    console.log(`Rendering TickerDetailsComponent for ${tickerDetails.ticker}`);


    const TickerDetailsContent = () => {
        return (
            <div>
                    <div className="ticker_details_desc">{tickerDetails.description}</div>
                    {tickerDetails.location && (
                    <div className="ticker_details_row">
                        <div className="ticker_details_row_lbl">HEADQUARTERS</div>
                        <div className="ticker_details_row_cnt">{tickerDetails.location}</div>
                    </div>
                    )}

                    {tickerDetails.homepage_url && (
                    <div className="ticker_details_row">
                        <div className="ticker_details_row_lbl">WEBSITE</div>
                        <div className="ticker_details_row_cnt">
                        <a 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            href={tickerDetails.homepage_url}>
                            {tickerDetails.homepage_url.replace(/(^\w+:|^)\/\//, '').replace('www.', '')}
                        </a>
                        </div>
                    </div>
                )}

                {tickerDetails.total_employees && (
                <div className="ticker_details_row">
                    <div className="ticker_details_row_lbl">EMPLOYEES</div>
                    <div className="ticker_details_row_cnt">{tickerDetails.total_employees.toLocaleString()}</div>
                </div>
                )}

                {tickerDetails.market_cap && (
                <div className="ticker_details_row">
                    <div className="ticker_details_row_lbl">MARKET CAP</div>
                    <div className="ticker_details_row_cnt">{Math.trunc(tickerDetails.market_cap).toLocaleString()}</div>
                </div>
                )}
                <div className="ticker_details_row">
                    <div className="ticker_details_row_lbl"><a href={`https://robinhood.com/stocks/${tickerDetails.ticker}`} target="_blank" rel="noopener noreferrer">Robinhood</a></div>
                    <div className="ticker_details_row_lbl"><a href={`/positions/${tickerDetails.ticker}`} target="_blank" rel="noopener noreferrer">Positions</a></div>
                    <div className="ticker_details_row_lbl"></div>
                    <div className="ticker_details_row_lbl"></div>
                </div>
          </div>
        );
      };
      

    return (
        <div className="ticker_details">
            {/* <TickerDetailsContent /> */}
            <Collapsible title="About">
            <div className="ticker_details_content">
                    <TickerDetailsContent />
                </div>
            </Collapsible>           
        </div>
    );   
}

export default TickerDetailsComponent;