import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from 'react-router-dom';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

import { getDataFromAPI, getTickerDetailsEndpoint } from 'utils/WT3ApiUtil'
import Header from "common/components/Header";
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import TimeRangeRadioList from 'admin/components/TimeRangeRadioList';
import PriceTrendsCheckboxComponent from 'admin/components/PriceTrendsCheckboxComponent'
import Breadcrumbs from 'common/components/Breadcrumbs'


import ServiceNotAvailable from 'common/pages/ServiceNotAvailable'

import TickerDetailsComponent from "tickerdetails/TickerDetailsComponent";
import ManageTickerTradesPage from "tickertrades/ManageTickerTradesPage";
import StockTradesAdminPage from "stocks/StockTradesAdminPage";

const DailyPriceTrendsDetailsPage = () => {

    const { ticker } = useParams();
    console.log(`Rendering DailyPriceTrendsDetailsPage for ${ticker}`);

    const navigate = useNavigate();


    const [dailyPriceTrends, setDailyPriceTrends] = useState([])
    const [tickerDetails, setTickerDetails] = useState([])

    const [dateRange, setDateRange] = useState([])
    const [selectedTimeRange, setSelectedTimeRange] = useState('40');
    const [loading, setLoading] = useState(true); // Assume loading is true by default

    const getDailyPriceTrendsDetailsEndpoint = (ticker, timeRange) => {
    
      return `/wt3-api/pricetrends/${ticker}/timerange/${timeRange}`;
    };

    // console.log(dailyPriceTrendsEndpoint); // Outputs: /wt3-api/analytics/NYSE/timerange/5
    
    // Handle exchange changes
    const handleTimeRangeOptionChange = (timeRangeOption) => {
      setDailyPriceTrends([]);
      setSelectedTimeRange(timeRangeOption);
    };

    useEffect(() => {
      console.log(`Time Range: ${selectedTimeRange}`);
      
        const fetchdailyPriceTrends = async () => {
          // showProgress();
          setLoading(true); // Set loading to true before fetching data
          const dailyPriceTrendsEndpoint = getDailyPriceTrendsDetailsEndpoint(ticker, selectedTimeRange);
          console.log("Fetching price trends: " + dailyPriceTrendsEndpoint);
          const dailyPriceTrendsResponse = await getDataFromAPI(dailyPriceTrendsEndpoint, navigate);
          if (dailyPriceTrendsResponse == undefined) {
            return <ServiceNotAvailable/ >
          } 
          setDailyPriceTrends(dailyPriceTrendsResponse['data']);
          setDateRange(dailyPriceTrendsResponse['daterange']);
          setLoading(false); // Set loading to false after fetching data 
          // hideProgress();      
        };
        fetchdailyPriceTrends();
      }, [ticker, selectedTimeRange]);

    const fetchTickerDetails = async () => {
        // showProgress();
        if (ticker) {
          let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
          console.log(tickerDetailsEndPoint);
          setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
          // hideProgress();
        }
    };

      useEffect(
        () => {
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, [ticker]
    );

      const displayDateRange = () => {
        // Check if priceTrends is empty
      if (loading) {
        return <div>Loading price trends...</div>;
      }
      if (!dailyPriceTrends || dailyPriceTrends.length === 0) {
          return <div>No data found for ticker {ticker}.</div>;

      }    
      return (
            <React.Fragment>
                <h5>Date Range: {dateRange['start']} - {dateRange['end']}</h5>
                <TimeRangeRadioList selectedOption={selectedTimeRange} handleOptionChange={handleTimeRangeOptionChange} displayType={"series"}/>
            </React.Fragment>
          )
      }

    const datasetItems = [
      { key: 'trade_return_1', label: 'Return(1)', borderColor: 'rgb(75, 192, 192)' },
      { key: 'trade_return_3', label: 'Return(3)', borderColor: 'rgb(255, 127, 80)' },
      { key: 'trade_return_5', label: 'Return(5)', borderColor: 'rgb(150, 123, 182)'},
      { key: 'price_change_3', label: '%Change(3)', borderColor: 'rgb(102, 205, 170)' },
      { key: 'price_change_5', label: '%Change(5)', borderColor: 'rgb(100, 149, 237)' },
      // { key: 'price_change_10', label: '%Change(10)', borderColor: 'rgb(255, 165, 0)'},
      { key: 'rsi5', label: 'RSI(5)', borderColor: 'rgb(138, 43, 226)'}
    ];

    // Step 1: Initialize state for checkboxes
    const initialOptionsState = datasetItems.reduce(
      (acc, item) => ({ ...acc, [item.key]: false }),
      {}
    );

    // Set only 'trade_return_1' to true
    initialOptionsState['trade_return_5'] = true;
    
    const [selectedOptions, setSelectedOptions] = useState(initialOptionsState);

    // Step 3: Handle change in checkboxes
    const handleOptionChange = (key) => {
      setSelectedOptions({ ...selectedOptions, [key]: !selectedOptions[key] });
    };

    const tradeReturnDatasetItems = datasetItems.filter(item => item.label.includes('Return'));
    const otherDatasetItems = datasetItems.filter(item => !item.label.includes('Return'));
    
    const chartData = useMemo(() => {
      // memoize chart data calculation
      const dates = dailyPriceTrends.map(trend => trend.buy_datetime.split('T')[0]);
      const datasets = datasetItems.filter(item => selectedOptions[item.key]).map(item => ({
          label: item.label,
          data: dailyPriceTrends.map(trend => trend[item.key] === "NAN" ? null : trend[item.key]),
          fill: false,
          borderColor: item.borderColor,
          tension: .5
      }));

      return {
          labels: dates,
          datasets: datasets
      };
  }, [dailyPriceTrends, selectedOptions, datasetItems]); // dependencies

  const tickerDetailsMemo = useMemo(() => {
    // Perform any complex transformations or computations with tickerDetails here
    return tickerDetails;
}, [tickerDetails]); // Dependency array


    const drawPriceTrendsSeries = () => {
      console.log("Inside drawPriceTrendsSeries....");
    
      // Sort the data by date
      dailyPriceTrends.sort((a, b) => new Date(a.date) - new Date(b.date));
    
      const dates = dailyPriceTrends.map(trend => trend.buy_datetime.split('T')[0]);
    
      const datasets = datasetItems.filter(item => selectedOptions[item.key]).map(item => ({
        label: item.label,
        data: dailyPriceTrends.map(trend => trend[item.key] === "NAN" ? null : trend[item.key]),
        fill: false,
        borderColor: item.borderColor,
        tension: .5
      }));
    
    const data = {
        labels: dates,
        datasets: datasets
      };

      const options = {
        scales: {
          x: { // For the x-axis
            grid: {
              display: true // Hides the grid lines for the x-axis
            }
          },
          y: { // For the y-axis
            grid: {
              display: true // Hides the grid lines for the y-axis
            }
          }
        },
        plugins: {
          legend: {
            position: 'right' // Keeps the legend to the right
          },
          tooltip: {
            // backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
            displayColors: false, // Do not display the legend color box in the tooltip
            callbacks: {
              label: function(context) {
                // Return the value only
                return context.parsed.y;
              },
              title: function() {
                // Return an empty string or null to not display the title
                return '';
              }
            }
          }         
        }       
      };
      return (<div style={{ width: '1200px' }}>
              <Line data={chartData} options={options} />;
            </div>)
    };
    

    return (
    <div>
        <Header />
        <div className="content">
          <Breadcrumbs />
          {/* <ProgressBar/> */}
          <div class="ticker_trades">
            <TickerDetailsComponent tickerDetails={tickerDetailsMemo} />
            <div class="intraday_trends">
              <ManageTickerTradesPage tickerDetails={tickerDetailsMemo} />
            </div>
          </div>
          
          <div style={{marginBottom: 20 + "px"}}>
              {displayDateRange()}
              {drawPriceTrendsSeries()}
              <PriceTrendsCheckboxComponent datasetItems={tradeReturnDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
              <PriceTrendsCheckboxComponent datasetItems={otherDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
            </div>
          </div>
          <StockTradesAdminPage  ticker={ticker}/>
    </div>
    )
}
export default DailyPriceTrendsDetailsPage;