import axios from 'axios';

const getDataFromAPI = async (api_endpoint, navigate) => {

    try {
    // console.log(`Fetching data from: ${api_endpoint}`);
    const response = await axios.get(api_endpoint);
    return response.data;
    } catch (e) {
        console.log(e);
        if (e.response) {
            console.log(e.response.status);
            if (e.response.status === 404) {
                navigate("/notfound");
            } else if(e.response.status === 403) {
                navigate("/login");
            } else if (e.response.status === 504) {
                navigate("/serviceNotAvailable");
            } else if (e.response.status === 400) {
                return e.response;
            } else {
                navigate("/serverError");
            }
        }
    }
}

const editAPIData = async (api_endpoint, data, navigate) => {
    try {
        const response = await axios.put(api_endpoint, data);
        return response;
    } catch (e) {
        console.log(e);
        if (e.response) {
            console.log(e.response.status);
            if (e.response.status === 404) {
                navigate("/notfound");
            } else if(e.response.status === 403) {
                navigate("/login");
            } 
        }
        throw e;
    }
}

const addAPIData = async (api_endpoint, data, navigate) => {
    try {
        const response = await axios.post(api_endpoint, data);
        return response;
    } catch (e) {
        console.log(e);
        if (e.response) {
            console.log(`Response status: ${e.response.status}`);
            if (e.response.status === 404) {
                navigate("/notfound");
            } else if(e.response.status === 403) {
                navigate("/restricted");
            } else if (e.response.status === 400) {
                return e.response;
            } else if (e.response.status === 500) {
                return e.response;
            } else {
                navigate("/serverError");
            }
        }
        throw e;
    }
}

const deleteAPIData = async (api_endpoint, navigate) => {
    try {
        const response = await axios.delete(api_endpoint);
        return response;
    } catch (e) {
        console.log(e);
        if (e.response) {
            console.log(`Response status: ${e.response.status}`);
            if (e.response.status === 404) {
                navigate("/notfound");
            } else if(e.response.status === 403) {
                navigate("/restricted");
            } else if (e.response.status === 400) {
                return e.response;
            } else {
                navigate("/serverError");
            }
        }
        throw e;
    }
}

// Function to parse a specific query parameter
export const getQueryParam = (location, paramName) => {
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the specified query parameter
    const paramValue = queryParams.get(paramName);
    return paramValue ? paramValue : null; // Return the parameter value or null if it doesn't exist
};

export const getTickerDetailsEndpoint = (ticker) => {
    return `/wt3-api/ticker/${ticker}/details`;
  }

export {getDataFromAPI, editAPIData, addAPIData, deleteAPIData};