// import React from 'react';
import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import TrendingTrades from "admin/components/TrendingTradesAdmin";
import Header from "common/components/Header";
import { TRENDING_TRADES_END_POINT } from "utils/WT3ApiConstants"

import { getDataFromAPI } from 'utils/WT3ApiUtil'

import "admin/styles/tr_admin.css";


const TrendingTradesAdminPage = ({}) => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const [trendingTrades, setTrendingTrades] = useState(null);

    const handleError = (error) => {
        setErrorMessage(error);
    };

    const fetchTrendingTrades = async () => {
        let trendingTradesEndPoint = `${TRENDING_TRADES_END_POINT}`;
        const response = await getDataFromAPI(trendingTradesEndPoint, navigate);
        if (response) {
            // console.log(response['data']);
            setTrendingTrades(response['data']);
        }        
    };

    useEffect(() => {
        fetchTrendingTrades();
    }, []);

    return (
        <div className="content">
            <Header />
            <div className="stock_trades">
                <ProgressBar />
                <div className="stock_trades_content">
                    <h4 className="stock_trade_header">Trending Trades</h4>
                        {trendingTrades && trendingTrades.length > 0 ? (
                            <React.Fragment>
                                {<div className="fail" style={{textAlign: "left"}}>{errorMessage}</div>}
                                <TrendingTrades trendingTrades={trendingTrades} onTradeEdit={fetchTrendingTrades} onError={handleError}/>
                            </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <h4>No trending trades</h4>
                                </React.Fragment>                        
                            )
                        }                
                </div>
            </div>
        </div>
    );   
}

export default TrendingTradesAdminPage;