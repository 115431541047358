import React from "react";
import { useNavigate} from 'react-router-dom';


const LatestDailyMovements = ({dailyMovementsList}) => {

    const navigate = useNavigate();

    const handleRowClick = (event) => {
        const tickerElement = event.currentTarget.querySelector('.ticker');
        const ticker = tickerElement.textContent;
        // Construct the URL
        const url = `/movement/${ticker}`;
        window.open(url);
    };

    const displayTradePositions = () => {        
        return (
            dailyMovementsList.map(tickerDailyMovement => (
                    <div className="list-row list-row-clickable" key={tickerDailyMovement.ticker} onClick={handleRowClick}>
                        <div className="list-item ticker">{tickerDailyMovement.ticker}</div>
                        {/* <div className="list-item" style={{ maxWidth: '90px' }}>{tickerDailyMovement.ticker}</div> */}
                        <div className="list-item" style={{ maxWidth: '90px' }}>{tickerDailyMovement.volume}</div>
                        <div className="list-item" style={{ maxWidth: '90px' }}>{tickerDailyMovement.vw_price}</div>
                        <div className="list-item" style={{ maxWidth: '90px' }}>{tickerDailyMovement.return3}</div>
                        <div className="list-item" style={{ maxWidth: '90px' }}>{tickerDailyMovement.return5}</div>
                      </div>
              ))//map
          ); //return
    }
    
    return (
                <div className="scrollable-div">{displayTradePositions()}</div>
            )
}

export default LatestDailyMovements;