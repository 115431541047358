import React, { useState, useEffect, useMemo} from "react"
import { useNavigate } from 'react-router-dom';
import {formatISODateMonthHourMinute} from "utils/WT3DisplayUtil"
import TickerTradesDeltaBarChartList from "tickertrades/TickerTradesDeltaBarChartList"

import Header from "common/components/Header";

const LatestTradesDeltaForTrendingTickers = () => {
    const navigate = useNavigate();    
    const [lastRefreshed, setLastRefreshed] = useState(new Date()); // State to store the last refresh time

    useEffect(() => {
        // Set up an interval to update the lastRefreshed state every 2 minutes
        const intervalId = setInterval(() => {
            setLastRefreshed(new Date());
            // Any additional logic that needs to be executed periodically can also be placed here
        }, 120000); // 120000 milliseconds = 2 minutes
    
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs once on mount

    const displayLastRefreshed = () => {
        return <h4>Last Refreshed: {formatISODateMonthHourMinute(lastRefreshed)}</h4>
    };

    return (
        <div className="content">
            {displayLastRefreshed()}
            <TickerTradesDeltaBarChartList />
        </div>
    );
}

export default LatestTradesDeltaForTrendingTickers