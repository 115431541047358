import React from "react";
import { useNavigate} from 'react-router-dom';

const TradeAutoCorr = ({tradesAutoCorrList}) => {

    const navigate = useNavigate();

    const handleRowClick = (event) => {
        // Find the ticker within the clicked row. Adjust the selector as necessary.
        const tickerElement = event.currentTarget.querySelector('.ticker');
        const ticker = tickerElement.textContent;
        // Construct the URL
        const url = `/trends/${ticker}`;
        //navigate(url);
        window.open(url, '_blank');
    };

    const displayTrendTradeCorrs = () => {   
        return (
            tradesAutoCorrList.map(tradeAutoCorr => (
                    <div className="list-row-corr list-row-clickable" key={tradeAutoCorr.ticker} onClick={handleRowClick}>
                        <div className="list-item-corr ticker">{tradeAutoCorr.ticker}</div>
                        <div className="list-item-corr">{tradeAutoCorr.auto_r1}</div>
                        <div className="list-item-corr">{tradeAutoCorr.auto_r3}</div>
                        <div className="list-item-corr">{tradeAutoCorr.auto_r5}</div>
                      </div>
              ))//map
          ); //return
    }
    
    return (
                <div className="scrollable-div">{displayTrendTradeCorrs()}</div>
            )
}

export default TradeAutoCorr;