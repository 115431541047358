import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getDataFromAPI, getTickerDetailsEndpoint, getQueryParam } from 'utils/WT3ApiUtil'
import { TICKER_DAILY_MOVEMENT } from "utils/WT3ApiConstants"
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import DailyMovementLineChart from "tickertrades/components/DailyMovementLineChart"
import DailyVolumeFilterComponent from "./components/DailyVolumeFilter"; // Adjust the import path if necessary

import Header from "common/components/Header";


const TickerDailyVolumeMovementPage = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { ticker } = useParams();
    const [filterType, setFilterType] = useState('session');
    const [filterValue, setFilterValue] = useState('regular');
    const [count, setCount] = useState(2);

    const [tickerDetails, setTickerDetails] = useState([])
    const [tickerDailyMovements, setTickerDailyMovements] = useState({});

    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    useEffect(() => {
        console.log(`Ticker: ${ticker}`)
        const fetchDailyMovements = async () => {
            const data = await fetchTickerDailyVolumeMomenents(ticker);
            // console.log(data['eod']);
            if (data) {
                setTickerDailyMovements(data);
            }
        };

        fetchDailyMovements();
    }, [ticker, filterType, filterValue, count]); // Add ticker to dependencies



    const fetchTickerDailyVolumeMomenents = async (ticker) => {
        showProgress();
        setIsLoading(true);

        let dateStr = getQueryParam(location, 'date');
        let tickerDailyVolumeMovementEndPoint = `${TICKER_DAILY_MOVEMENT}/${ticker}`;
        if (dateStr) {
            tickerDailyVolumeMovementEndPoint += `?date=${dateStr}`;
        }
        
        if (filterType && filterValue) {
            tickerDailyVolumeMovementEndPoint += `${dateStr ? '&' : '?'}filter_type=${filterType}&filter_value=${filterValue}`;
        }
        console.log(`filterType: ${filterType}`)
        if ("direction" == filterType) {
            tickerDailyVolumeMovementEndPoint +=`&count=${count}`
        }

        console.log("ticker daily volume end point:" + tickerDailyVolumeMovementEndPoint);

        const response = await getDataFromAPI(tickerDailyVolumeMovementEndPoint, navigate);
        if (response && response['updated_tickers'] > 0) {
            hideProgress();
            setName(response['name']);
            setIsLoading(false);
            return response['data']; // Return the fetched data
        }
        setIsLoading(false);
        hideProgress();
        return null; // Return null if no data is fetched
    };

    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;

        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading ticker trades for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTickerDailyMovementEmpty = (tickerDailyMovements.length === 0);

        if (( tickerDailyMovements == undefined) || isTickerDailyMovementEmpty === true) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No daily movements fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };
    const datasetItems = [
        { key: 'volume', label: 'Volume', borderColor: 'rgb(255, 127, 80)', borderWidth: 2, pointRadius: 1.5},
        { key: 'density', label: 'Density', borderColor: 'rgb(175, 195, 21)', borderWidth: 2, pointRadius: 1.5},
        { key: 'vw_price', label: 'Price', borderColor: 'rgb(94, 124, 208)', borderWidth: 2, pointRadius: 1.5},

    ];

    const handleFilterUpdate = (type, value, count) => {
        setFilterType(type);
        setFilterValue(value);
        setCount(count);
      };

    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <ProgressBar />
                    {DisplayMessageIfNoTickerTrades()}
                    <div>
                        <div>
                            {tickerDailyMovements !== undefined && Object.keys(tickerDailyMovements).length > 0 &&
                                <DailyMovementLineChart dailyMovements={tickerDailyMovements} datasetItems={datasetItems} initialOption="vw_price"></DailyMovementLineChart>
                            }
                        </div> 
                    </div>                            
                </div>
            </div>
            <div className="content">
                <DailyVolumeFilterComponent filterType={filterType} filterValue={filterValue} count={count} handleFilterUpdate={handleFilterUpdate} />
            </div>
        </React.Fragment>
    );
}

export default TickerDailyVolumeMovementPage