import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TickerTradePositions from "tickertrades/components/TickerTradePositions"
import { getDataFromAPI, getTickerDetailsEndpoint } from 'utils/WT3ApiUtil'
import { TICKER_TRADE_POSITIONS } from "utils/WT3ApiConstants"
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import TradesPositionsLineChart from "tickertrades/components/TradesPositionsLineChart"

import Header from "common/components/Header";

const TickerTradePositionsPage = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { ticker } = useParams();

    const [tickerDetails, setTickerDetails] = useState([])
    const [sodTickerTradePositions, setSodTickerTradePositions] = useState({});
    const [eodTickerTradePositions, setEodTickerTradePositions] = useState({});
    const [combinedTickerTradePositions, setCombinedTickerTradePositions] = useState({});

    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    useEffect(() => {
        console.log(`Ticker: ${ticker}`)
        const fetchTrades = async () => {
            const data = await fetchTickerTradePositions(ticker);
            // console.log(data['eod']);
            if (data) {
                setSodTickerTradePositions(data['sod']);
                setEodTickerTradePositions(data['eod']);
            }
        };

        fetchTrades();
    }, [ticker]); // Add ticker to dependencies

    useEffect(() => {
        mergeEodAndSodTradePositions()
    }, [sodTickerTradePositions, eodTickerTradePositions]); // Add ticker to dependencies


    const fetchTickerTradePositions = async (ticker) => {
        showProgress();
        setIsLoading(true);
        let tickerTradePositionsEndPoint = `${TICKER_TRADE_POSITIONS}/${ticker}`;
        

        const response = await getDataFromAPI(tickerTradePositionsEndPoint, navigate);
        if (response && response['updated_tickers'] > 0) {
            hideProgress();
            setName(response['name']);
            setIsLoading(false);
            return response['data']; // Return the fetched data
        }
        setIsLoading(false);
        hideProgress();
        return null; // Return null if no data is fetched
    };


    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;

        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading ticker trades for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTickerTradePositionsEmpty = (Object.keys(eodTickerTradePositions).length === 0) && (Object.keys(eodTickerTradePositions).length === 0);

        if (( eodTickerTradePositions == undefined  && 
            sodTickerTradePositions == undefined) || isTickerTradePositionsEmpty === true) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No Trades positions fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };
    function mergeMaps(eodMap, sodMap) {
        const mergedArray = [];
        const commonKeys = new Set([...eodMap.keys()].filter(key => sodMap.has(key)));
      
        // Helper function to compute the average size
        const computeAvgSize = (eodValue, sodValue) => {
            if (eodValue !== undefined && sodValue !== undefined) {
              const average = (eodValue + sodValue) / 2;
              return Number(average.toFixed(2));
            }
            return null;
          };
                
        // Function to create a merged object
        const createMergedItem = (key, eodItem, sodItem) => {
          const mergedItem = {};
      
          // Assign date and ticker from eodItem or sodItem
          mergedItem.date = key;
      
          // Merge eod and sod item properties
          Object.assign(mergedItem, ...Object.keys(eodItem || {}).map(k => ({[k]: eodItem[k]})));
          Object.assign(mergedItem, ...Object.keys(sodItem || {}).map(k => ({[k]: sodItem[k]})));
      
          // Compute the 'pct_change_avg_size' field
        //   mergedItem.pct_change_avg_size = computeAvgSize(eodItem.eod_pct_change_avg_size, sodItem.sod_pct_change_avg_size);
        //   mergedItem.pct_change_total_size = computeAvgSize(eodItem.eod_pct_change_total_size, sodItem.sod_pct_change_total_size);
          
          delete mergedItem.eod_date;
          delete mergedItem.sod_date;
            
          return mergedItem;
        };
      
        // Process each common key and merge the corresponding items
        commonKeys.forEach(key => {
          const eodItem = eodMap.get(key);
          const sodItem = sodMap.get(key);
          const mergedItem = createMergedItem(key, eodItem, sodItem);
          mergedArray.push(mergedItem);
        });
      
        return mergedArray;
      }
            
    const renameTradeItemArrayFields = (items, keyPrefix) => {
        const transformedMap = new Map();
        items.forEach(item => {
          const transformedItem = {};
          Object.keys(item).forEach(key => {
            transformedItem[keyPrefix + "_" + key] = item[key];
          });
          const compositeKey = item.date; // composite key: date + ticker
          transformedMap.set(compositeKey, transformedItem);
        });
      
        return transformedMap;
    }
                  
    const mergeEodAndSodTradePositions = () => {
        if (sodTickerTradePositions === undefined || Object.keys(sodTickerTradePositions).length < 1) {
            return
        }
        if (eodTickerTradePositions === undefined || Object.keys(eodTickerTradePositions).length < 1) {
            return
        }
        let sodTradesMap = renameTradeItemArrayFields(sodTickerTradePositions, "sod");
        // console.log(sodTradesMap);
        let eodTradesMap = renameTradeItemArrayFields(eodTickerTradePositions, "eod");
        // console.log(eodTradesMap);

        let mergedTradePositions = mergeMaps(eodTradesMap, sodTradesMap);
        // console.log(mergedTradePositions);
        setCombinedTickerTradePositions(mergedTradePositions);
    }


    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <ProgressBar />
                    {DisplayMessageIfNoTickerTrades()}
                    <div>
                        <div>
                            {combinedTickerTradePositions !== undefined && Object.keys(combinedTickerTradePositions).length > 0 &&
                                <TradesPositionsLineChart tickerTradesDelta={combinedTickerTradePositions}></TradesPositionsLineChart>
                            }
                        </div> 
                    </div>            
                    <div className="ticker_trades">          
                        {/* <div>
                            <TickerTradePositions tradePositions={sodTickerTradePositions} showCrumb={false} />
                        </div>
                        <div>
                            <TickerTradePositions tradePositions={eodTickerTradePositions} showCrumb={false} />
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TickerTradePositionsPage