import {RadioContainer, RadioButton, OptionsRadio} from 'admin/styles/StyleComponents'

const TradeStatusRadioList = ({ selectedOption, handleOptionChange }) => {

  const radioAttrLabelStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '120px',
  };

  return (
    <div className="user-attr">

      <RadioContainer>Placed
        <RadioButton name="tradeStatus" value="placed" 
                checked={selectedOption === 'placed'} 
                onChange={() => handleOptionChange('placed')} />
        <OptionsRadio checked={selectedOption === 'placed'}/>
      </RadioContainer>

      <RadioContainer>Open
        <RadioButton name="tradeStatus" value="open" 
                checked={selectedOption === 'open'} 
                onChange={() => handleOptionChange('open')} />
        <OptionsRadio checked={selectedOption === 'open'}/>
      </RadioContainer>

      <RadioContainer>Closed
        <RadioButton name="tradeStatus" value="closed" 
                checked={selectedOption === 'closed'} 
                onChange={() => handleOptionChange('closed')} />
        <OptionsRadio checked={selectedOption === 'closed'}/>
      </RadioContainer>
    </div>
  );
};

export default TradeStatusRadioList;