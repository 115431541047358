import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import { FEEDBACK_LIST_END_POINT } from "utils/WT3ApiConstants"
import { getDataFromAPI} from "utils/WT3ApiUtil"
import Header from "common/components/Header";

const FeedbackListPage = () => {

    const navigate = useNavigate();

    const [feedbackList, setFeedbackList] = useState([]);


    const fetchFeedbackList = async () => {
        let feedbackListEndpoint = `${FEEDBACK_LIST_END_POINT}`;
        const response = await getDataFromAPI(feedbackListEndpoint, navigate);
        if (response) {
            setFeedbackList(response);
        }
    };

    useEffect(() => {
        fetchFeedbackList();
    }, []);

    const displayFeedbackListPage = () => {    
        if (feedbackList == undefined || feedbackList.length < 1) {
            return <h2 style={{ textAlign: 'center' }}>No users have provided feedback so far.</h2>;
        }
        return (
            feedbackList.map(feedback => (
                    <div  key={feedback.feedback_id} style={{ borderBottom: '1px solid #f5f5f5', padding: 15 + 'px' }}>
                            <div className="no-wrap-span feedback-item">
                                <span style={{ fontWeight: 600, marginRight: 10 + 'px'}}>Name:</span> 
                                {feedback.name}
                            </div>
                            <div className="no-wrap-span feedback-item">
                                <span style={{ fontWeight: 600, marginRight: 10 + 'px'}}>Email:</span>
                                <a href={`mailto:${feedback.email}`}>{feedback.email}</a>

                            </div>
                            <p className="styled-paragraph">{feedback.message}</p>                                               
                    </div>
              ))//map
          );
    }
    
    return (
                <React.Fragment>
                    <Header />
                    <div className="content feedback-list">
                        {displayFeedbackListPage()}
                    </div>
                </React.Fragment>
            )
}

export default FeedbackListPage;