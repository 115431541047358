import React, { useState, useEffect, useMemo} from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { LATEST_DELTA_TICKER_TRADES_ENDPOINT } from "utils/WT3ApiConstants"
import TickerTradesDeltaBarChart from "tickertrades/TickerTradesDeltaBarChart"

import { getDataFromAPI} from "utils/WT3ApiUtil"
import {scrollToBottom} from "utils/WT3DisplayUtil"

import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import Breadcrumbs from 'common/components/Breadcrumbs'

const AllTradesDeltaForTicker = ({ticker, date, showCrumb}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [tradesData, setTradesData] = useState({});
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        console.log(`Ticker: ${ticker}, Date: ${date}`)
        const fetchTrades = async () => {
            const data = await fetchLatestTickerTradesDelta(ticker, date,);
            if (data) {
                setTradesData(data.reduce((acc, item) => ({ ...acc, [item.sequence]: item }), {}));
            }
        };

        fetchTrades();
    }, [ticker]); // Add ticker to dependencies



    const fetchLatestTickerTradesDelta = async (ticker, date) => {
        showProgress();
        setIsLoading(true);
        let latestTickerTradesDeltaEndpoint = `${LATEST_DELTA_TICKER_TRADES_ENDPOINT}/${ticker}`;
        
        if (date) {
            latestTickerTradesDeltaEndpoint += `?date=${date}`;
        }

        const response = await getDataFromAPI(latestTickerTradesDeltaEndpoint, navigate);
        if (response && response['updated_tickers'] > 0) {
            hideProgress();
            setName(response['name']);
            setIsLoading(false);
            return response['data']; // Return the fetched data
        }
        setIsLoading(false);
        hideProgress();
        return null; // Return null if no data is fetched
    };

    const sortedSequences = useMemo(() => {
        return Object.keys(tradesData).sort((a, b) => b - a);
    }, [tradesData]);

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading ticker trades for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTradesDataEmpty = Object.keys(tradesData).length === 0;
        if (tradesData == undefined || isTradesDataEmpty === true) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No Trades fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            {showCrumb && <Breadcrumbs />}
            <div className="bar-chart-list">
                <ProgressBar />
                {DisplayMessageIfNoTickerTrades()}
                <button onClick={scrollToBottom}>Scroll Down</button>
                {sortedSequences.map(sequence => {
                    const tickerData = tradesData[sequence];
                    return tickerData ? (
                        <TickerTradesDeltaBarChart key={sequence} tickerTradesDelta={tickerData} showTitle={false}/>
                    ) : (
                        <h2 key={sequence}> No trades delta available for {ticker} </h2>
                    );
                })}
            </div>
        </React.Fragment>
    );
}

export default AllTradesDeltaForTicker