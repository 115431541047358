import styled from 'styled-components';


export const CloseButton = styled.button`
position: absolute;
top: 10px;
right: 10px;
border: none;
background: none;
font-size: 20px;
cursor: pointer;`;

export const TradeAttribute = styled.div`
  letter-spacing: -0.03em;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #2a2a2c;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  width: 80%;
`;

export const TradeAttributeLabel = styled.label`
  width: 100%;
  font-weight: bold;
  text-align: left;`;

  export const OptionsRadio = styled.span`
  position: absolute;
  left: calc(60px); // Position to the right of RadioButton, with a 10px gap
  top: 50%;
  transform: translateY(-50%); // Center it vertically relative to the RadioButton
  height: 11px;
  width: 11px;
  background-color: #ebebeb;
  border-radius: 100%;
`;

export  const RadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

  export const RadioContainer = styled.label`
  position: relative;
  cursor: pointer;

  &:hover ${OptionsRadio} {
    background-color: #ccc;
  }

  ${RadioButton}:checked + ${OptionsRadio} {
    background-color: #ebebeb;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 2px;
      left: 2px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #f99200;
    }
  }
`;
