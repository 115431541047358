import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { getDataFromAPI, getQueryParam } from 'utils/WT3ApiUtil'
import {LATEST_TRADE_POSITIONS} from 'utils/WT3ApiConstants'
import Header from "common/components/Header";
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import ExchangeRadioList from 'admin/components/ExchangeRadioList';
import PriceTrendsFilterComponentChain from 'admin/components/PriceTrendsFilterComponentChain'
import LatestTradePositions from "tickertrades/components/LatestTradePositions";
import ServiceNotAvailable from 'common/pages/ServiceNotAvailable'

const LatestTradePositionsListPage = () => {

    console.log('Rendering DailyTradePositions.....');

    const navigate = useNavigate();
    
    const location = useLocation();

    const [dailyTradePositions, setDailyTradePositions] = useState([])
    const [filteredDailyTradePositions, setFilteredDailyTradePositions] = useState([]); // Filtered data
    const [dateRange, setDateRange] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [selectedExchange, setSelectedExchange] = useState('NASDAQ');
    const [loading, setLoading] = useState(true); // Assume loading is true by default

    const getDailyTradePositionsEndpoint = (exchange) => {
      let offset = getQueryParam(location, 'offset');
      if (offset) {
        return `${LATEST_TRADE_POSITIONS}/${exchange}?offset=${offset}`;
      } else {
        return `${LATEST_TRADE_POSITIONS}/${exchange}`;
      }
    };
    
    const [filterOptions, setFilterOptions] = useState({
      ticker: { value: '', rule: 'eq', name: 'Ticker' },
      avg_size_eod: { value: '', rule: 'gte', name: 'Avg Size (EOD)' },
      total_size_eod: { value: '', rule: 'gte', name: 'Total Size (EOD)' },
      avg_size_sod: { value: '', rule: 'gte', name: 'Avg Size (SOD)' },
      total_size_sod: { value: '', rule: 'gte', name: 'Total Size (SOD)' }
    });
        
    const sortedFilteredDailyTradePositions = useMemo(() => {
      if (!filteredDailyTradePositions || filteredDailyTradePositions.length === 0) {
        return filteredDailyTradePositions || [];
      }

      return [...filteredDailyTradePositions].sort((a, b) => {
          if (sortConfig.key === null) return 0;

          if (sortConfig.direction === 'asc') {
              return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
              return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
      });
  }, [filteredDailyTradePositions, sortConfig]);    

  const handleAllFiltersChange = (filterConfigs) => {
    let filteredData = [...dailyTradePositions]; // Replace with your original data
  
    filterConfigs.forEach(filterConfig => {
      if (filterConfig.key && filterConfig.value) {
        const filterValue = parseFloat(filterConfig.value);
        if (filterConfig.rule === 'gte') {
          filteredData = filteredData.filter(item => item[filterConfig.key] >= filterValue);
        } else if (filterConfig.rule === 'lte') {
          filteredData = filteredData.filter(item => item[filterConfig.key] <= filterValue);
        } else if (filterConfig.rule === 'eq') {
          filteredData = filteredData.filter(item => item[filterConfig.key].toString().startsWith(filterConfig.value));
        }
      }
    });

    setFilteredDailyTradePositions(filteredData); // Update the state with the filtered data
  };

  useEffect(() => {
    
    const applyFilters = () => {
      if (dailyTradePositions == undefined || dailyTradePositions.length === 0) {
        console.log('No trade positions data available.');
        setFilteredDailyTradePositions([]);
        return;
      }
    
      let filteredData = dailyTradePositions.filter((trend) => {
        return Object.keys(filterOptions).every(key => {
          if (!trend.hasOwnProperty(key) || filterOptions[key].value === '') return true;
          
          const filterType = typeof trend[key];
          const filterValue = filterType === 'number' ? parseFloat(filterOptions[key].value) : filterOptions[key].value;
        
          // Check for NaN values in filterValue when it's supposed to be a number
          if (filterType === 'number' && isNaN(filterValue)) {
            console.log(`NaN encountered for ${key}`);
            return true;
          }
    
          let result;
          switch (filterOptions[key].rule) {
            case 'eq':
              result = trend[key] === filterValue;
              break;
            case 'gte':
              result = filterType === 'number' && trend[key] >= filterValue;
              break;
            case 'lte':
              result = filterType === 'number' && trend[key] <= filterValue;
              break;
            default:
              result = true;
          }
    
          return result;

        });
      });    
      setFilteredDailyTradePositions(filteredData);
    };
  
    applyFilters();
  }, [filterOptions, dailyTradePositions]);

    const displayDailyTradePositionsHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header" onClick={() => requestSort('Ticker')}><span>Ticker</span></div>
                <div className="list-item-header" onClick={() => requestSort('avg_size_sod')}><span>SOD Avg Size</span></div>
                <div className="list-item-header" onClick={() => requestSort('total_size_sod')}><span>SOD Total Size</span></div>
                <div className="list-item-header" onClick={() => requestSort('avg_size_eod')}><span>EOD Avg Size</span></div>
                <div className="list-item-header" onClick={() => requestSort('total_size_eod')}><span>EOD Total Size</span></div>
            </div>
          )
    }


    const handleExchangeOptionChange = (exchangeOption) => {
      setDailyTradePositions([]);
      setSelectedExchange(exchangeOption);
    };     


    const displayDateRange = () => {
        // Check if dailyTradePositions is empty
      if (loading) {
        return <div>Loading price trends...</div>;
      }
      if (!dailyTradePositions || dailyTradePositions.length === 0) {
          // Return dailyTradePositions as is or an empty array
          // return '';
          return <div>No price trends available.</div>;

        }      
      return (
            <React.Fragment>
                <h5>Date Range: {dateRange['start']} - {dateRange['end']}</h5>
                <h5>Rows: {sortedFilteredDailyTradePositions.length}</h5>
            </React.Fragment>
          )
      }

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };



      useEffect(() => {
          const fetchdailyTradePositions = async () => {
            showProgress();
            setLoading(true); // Set loading to true before fetching data
            const dailyTradePositionsEndpoint = getDailyTradePositionsEndpoint(selectedExchange);
            // console.log("Fetching price trends: " + dailyTradePositionsEndpoint);
            const dailyTradePositionsResponse = await getDataFromAPI(dailyTradePositionsEndpoint, navigate);
            // console.log(dailyTradePositionsResponse);
            if (dailyTradePositionsResponse == undefined) {
              return <ServiceNotAvailable/ >
            } 
            setDailyTradePositions(dailyTradePositionsResponse['data']);
            // console.log(dailyTradePositions);
            setDateRange(dailyTradePositionsResponse['daterange']);
            hideProgress();     
            setLoading(false); // Set loading to false after fetching data  
          };
          fetchdailyTradePositions();
        }, [selectedExchange]);
          
    return (
    <div className="content">
        <Header />
        <ExchangeRadioList selectedOption={selectedExchange} handleOptionChange={handleExchangeOptionChange} />
        <PriceTrendsFilterComponentChain filterOptions={filterOptions}  handleAllFiltersChange={handleAllFiltersChange}/>
        <div className="list-container">
            {displayDateRange()}
            <ProgressBar />
            {displayDailyTradePositionsHeader()}
            <LatestTradePositions tradePositionsList={sortedFilteredDailyTradePositions}/>
        </div>        
    </div>
    )
}
export default LatestTradePositionsListPage;