import React, { useState, useEffect, useMemo} from "react"
import { useNavigate} from 'react-router-dom';
import { TRADES_SUMMARY_ENDPOINT } from "utils/WT3ApiConstants"
import { getDataFromAPI} from "utils/WT3ApiUtil"
import { formatISODateShort, getDateFromISODateTime} from "utils/WT3DisplayUtil"
import TouchEnabledListItem from "common/components/TouchEnabledListItem"
import Header from "common/components/Header";

import PastTradesList from "home/components/PastTradesList"

const PastTradesSummary = () => {

    const navigate = useNavigate();

    const [pastTradesSummary, setPastTradesSummary] = useState([]);
    const [displayTrades, setDisplayTrades] = useState(false);
    const [clickedSummary, setClickedSummary] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const fetchPastTradesSummary = async () => {
        console.log(`Fetching past trades summary`);
        let pastTradesSummaryEndpoint = `${TRADES_SUMMARY_ENDPOINT}?status=closed`;
        const response = await getDataFromAPI(pastTradesSummaryEndpoint, navigate);
        if (response) {
            setPastTradesSummary(response);           
        }
    };

    useEffect(() => {
        fetchPastTradesSummary();
    }, []);

    const sortedTradesSummary = useMemo(() => {
        if (!pastTradesSummary || pastTradesSummary.length === 0) {
            // Return priceTrends as is or an empty array
            return pastTradesSummary || [];
        }
    
        return [...pastTradesSummary].sort((a, b) => {
            if (sortConfig.key === null) return 0;
    
            if (sortConfig.direction === 'asc') {
                return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
            } else {
                return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
            }
        });
    }, [pastTradesSummary, sortConfig]);


    const displayPastTradesSummaryTitle = () => {
        return  <h3 style={{color: '#f99200'}}>Past Stock Picks</h3>
    }


    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };
      
    const tradesSummaryHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header" onClick={() => requestSort('date')}><span>Date</span></div>
                <div className="list-item-header" onClick={() => requestSort('pct_price_chg')}><span>Price Change(%)</span></div>
                <div className="list-item-header" onClick={() => requestSort('trades')}><span>Trades</span></div>
                <div className="list-item-header"></div>    
            </div>
          )
    }

    const loadClosedTrades = async (date) => {
        const summaryKey = `${date}`;
        console.log(`Loading past trades for ${date}`);
        if (clickedSummary === summaryKey) {
            // Toggle the display of trade details
            setDisplayTrades(!displayTrades);
            // If closing the details, reset the clickedSummary
            if (displayTrades) {
                setClickedSummary('');
            }
            return;
        }
    
        try {
            setDisplayTrades(true);
            setClickedSummary(summaryKey); // Set the clicked summary
        } catch (error) {
            console.error('Failed to load trade details', error);
        }
    };

    const displayPastTradesSummary = () => {    
        if (pastTradesSummary == undefined || pastTradesSummary.length < 1) {
            return <h2>No past trades found.</h2>;
        }
        return (
            sortedTradesSummary.map(stockTrade => {
                // Check if clickedSummary matches the current stockTrade
                const isClicked = clickedSummary === `${stockTrade.date}`;
                const listItemClass = isClicked ? "list-row list-row-selected" : "list-row";
        
                return (
                    <div className="list-container-nested" key={stockTrade.date} style={{ borderBottom: '1px solid #f5f5f5'}}>
                        <div className="list-row-clickable list-row" onClick={() => loadClosedTrades(stockTrade.date)}>
                            <div className="list-item-stocks">{formatISODateShort(stockTrade.date)}</div>
                            <div className="list-item-stocks">{stockTrade.pct_price_chg}</div>
                            <div className="list-item-stocks">{stockTrade.trades}</div>
                            <div className="list-item-stocks">
                            <button>
                                { (displayTrades && clickedSummary === `${stockTrade.date}`) ? "-" : "+"}
                            </button>
                            </div>
                        </div>
                        {displayTrades && isClicked && (
                            <PastTradesList date={getDateFromISODateTime(stockTrade.date)} />
                        )}

                    </div>
                );
            }) // map
        );        
    }


    return (
                <React.Fragment>
                    <Header />
                    <div className="content">
                        {displayPastTradesSummaryTitle()}
                        {tradesSummaryHeader()}
                        {displayPastTradesSummary()}
                    </div>
                </React.Fragment>
            )
}

export default PastTradesSummary;