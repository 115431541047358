import './TickerLogo.css';

const TickerLogo = ({ ticker, name }) => {
    const handleImageError = (e) => {
        e.target.style.display = 'none'; // Hide the image element if an error occurs
    };

    return (
        <div className="ticker-logo">
            <span className='company_name'>{name}</span>
            <img src={`/images/ticker_logos/${ticker}.png`} 
                 alt={`${ticker} logo`} 
                 onError={handleImageError} />
        </div>
    );
};



export default TickerLogo;