import React, { useState } from 'react';

const TouchEnabledListItem = ({ children, classes }) => {
    const [isTouched, setIsTouched] = useState(false);

    const handleTouchStart = () => {
        setIsTouched(true);
    };

    const handleTouchEnd = () => {
        setIsTouched(false);
    };

    const className = `list-row-clickable ${isTouched ? 'touched' : ''}`;
    const combinedClassName = `${className} ${classes}`;


    return (
        <div 
            className={combinedClassName}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onMouseEnter={() => setIsTouched(true)}
            onMouseLeave={() => setIsTouched(false)}
            onClick={() => {/* handle click event */}}
        >
            {children}
        </div>
    );
};

export default TouchEnabledListItem;
