import React from 'react';
import ClickableLogo from "../components/ClickableLogo";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
        <div className="content-container">
            <ClickableLogo />
            <div className="content" style={{width: 75 + "%"}}>
                <h1>Privacy Policy for TrendingTrades</h1>
                <h4>Last Updated: Nov 18, 2023</h4>

                <p>
                    TrendingTrades is committed to protecting your privacy. 
                    This privacy policy explains how we handle and protect the personal information we collect through the use of Google OAuth for authentication and cookies for maintaining your session on our app.
                </p>
                <h3>Information Collection and Use</h3>
                <h4>Google OAuth Authentication</h4>
                <p>
                    When you log in using Google OAuth, we receive your profile information from Google. This typically includes your name, email address, and profile picture.
                    We use this information solely to identify you as a user and to personalize your experience within our app.
                </p>
                <h3>Cookies</h3>
                <p>
                    Our app uses cookies to maintain your session. Cookies are small data files stored on your device that help us improve your experience on our app.
                    The cookies we use are essential for the operation of the app and do not track any personal information.
                </p>
                <h3>Data Sharing and Disclosure</h3>
                <p>
                    We do not share or sell any of your personal information with third parties.
                </p>
                <h3>Data Security</h3>
                <p>
                    We take reasonable measures to protect the information we collect from or about you from unauthorized access, use, or disclosure.
                </p>
                <h3>User Rights</h3>
                <p>
                    You have the right to access, update, or delete your information in our app. To do so please <a href="mailto:pawan@trendingtrades.io">email us</a>.
                </p>
                <h3>Changes to This Privacy Policy</h3>
                <p>
                    We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
                </p>
                <h3>Contact Us</h3>
                <p>
                    If you have any questions about this privacy policy, please contact us at pawan@trendingtrades.io.
                </p>
            </div>
        </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
