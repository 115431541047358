import React from "react";
import { useState } from "react"

import { useNavigate} from 'react-router-dom';
import {formatISODateMonthHourMinute, formatAsFloat} from '../../utils/WT3DisplayUtil'
import { addAPIData } from '../../utils/WT3ApiUtil'

const getTradeCloseEndpoint = () => {
    return `/wt3-api/trade/close`;
};

const getTradeOpenEndpoint = () => {
    return `/wt3-api/trade/open`;
};

export const closeTrade = async(orderId, onTradeClose, onError, nav) => {
    console.log(`Closing trade for with order id ${orderId}`);
    onError("");
    const data = {
        order_id: orderId
    };
    console.log(`Closing trade for ${JSON.stringify(data, null, 2)}`);
    let closeTradesEndPoint = getTradeCloseEndpoint();
    const response = await addAPIData(closeTradesEndPoint, data, nav);
    if (response.status == 400) {
        console.log(response['data']['error']);
        onError(response['data']["error"]);
    } 
    console.log(response);
    onTradeClose();
}

export const openTrade = async(orderId, onTradeClose, onError, nav) => {
    onError("");
    console.log(`Updating trade with order id ${orderId}`);
    const data = {
        order_id: orderId
    };
    console.log(`Updating trade for ${JSON.stringify(data, null, 2)}`);
    let updaeTradesEndPoint = getTradeOpenEndpoint();
    const response = await addAPIData(updaeTradesEndPoint, data, nav);

    if (response.status == 400) {
        console.log(response['data']['error']);
        onError(response['data']["error"]);
    }
    console.log(response);
    onTradeClose();
}

const StockTrades = ({stockTrades, onTradeClose, onError}) => {

    const navigate = useNavigate();

    const stockTradesHeader = (status) => {
        return(
            <div className="list-header">
                <div className="list-item-header-no-sort"><span>Order Placed</span></div>
                <div className="list-item-header-no-sort"><span>Opened Date</span></div>
                <div className="list-item-header-no-sort"><span>Open Price</span></div>
                <div className="list-item-header-no-sort"><span>Last Updated</span></div>
                <div className="list-item-header-no-sort"><span>% Change</span></div>
                {   /* Place holder header for close trade button. An order that is placed or open can be closed */
                    (status !== "closed") && (
                            <div className="list-item-header-no-sort"><span></span></div>
                    )
                }
                { /* Place holder header for open trade button. An order that is placed can be opened*/

                    (status === "placed") && (
                            <div className="list-item-header-no-sort"><span></span></div>
                    )
                }                
            </div>
          )
    }
    const displayStockTrades = () => {    
        if (stockTrades == undefined || stockTrades.length < 1) {
            return '';
        }
        return (
            stockTrades.map(stockTrade => (
                    <div className="list-row" key={stockTrade.order_id}>
                        <div className="list-item-stocks">{formatISODateMonthHourMinute(stockTrade.order_dt)}</div>
                        <div className="list-item-stocks">{formatISODateMonthHourMinute(stockTrade.open_dt)}</div>
                        <div className="list-item-stocks">{stockTrade.open}</div>
                        <div className="list-item-stocks">{formatISODateMonthHourMinute(stockTrade.last_updated)}</div>
                        {/* <div className="list-item-stocks" style={{"paddingLeft" : 150 + "px"}}>{formatAsFloat(stockTrade.pct_price_chg, 1)}</div> */}
                        <div className="list-item-stocks">{formatAsFloat(stockTrade.pct_price_chg, 1)}</div>
                                         
                        {/* An order that is placed or open can be closed */ 
                            (stockTrade.status !== "closed") && (
                                <div className="list-item-stocks">
                                    <a onClick={() => closeTrade(stockTrade.order_id, onTradeClose, onError, navigate)} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Close</a>
                                </div>
                            )
                        }
                        {/* Only orders that are placed can be opened */ 
                            (stockTrade.status === "placed") && (
                                <div className="list-item-stocks">
                                    <a onClick={() => openTrade(stockTrade.order_id, onTradeClose, onError, navigate)} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Open</a>
                                </div>                                
                            )
                        }                        
                    </div>
              ))//map
          );
    }
    
    return (
                <React.Fragment>
                    {stockTradesHeader(stockTrades[0].status)}
                    {displayStockTrades()}
                </React.Fragment>
            )
}

export default StockTrades;