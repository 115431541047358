import React, { useState, useMemo } from "react"

import { useNavigate} from 'react-router-dom';
import {formatISODateMonthHourMinute, formatAsFloat, formatISODateShort} from 'utils/WT3DisplayUtil'

import { openTrade, closeTrade } from "stocks/components/StockTrades";
import TrendingTradeEditModal from "admin/components/TrendingTradeEditModal"

import { capitalizeFirstLetter } from "utils/WT3DisplayUtil";
import { deleteAPIData } from "utils/WT3ApiUtil";
import { TRENDING_TRADES_END_POINT } from 'utils/WT3ApiConstants'

const TrendingTrades = ({trendingTrades, onTradeEdit, onError}) => {

    const navigate = useNavigate();

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [selectedTrade, setSelectedTrade] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const updateTrade = (trendingTrade) => {
        console.log(`Order Id selected: ${trendingTrade.order_id}`);
        setSelectedTrade(trendingTrade);
        setIsModalOpen(true);
    };

    const deleteTrade = async (trendingTrade) => {
        console.log(`Deleting order id ${trendingTrade.order_id}`);
        try {
            let deleteTradeOrderEndPoint = `${TRENDING_TRADES_END_POINT}/${trendingTrade.order_id}`;
            const response = await deleteAPIData(deleteTradeOrderEndPoint);
            console.log(response);
            onTradeEdit();
        } catch (error) {
            console.error('Error deleteing trade order:', trendingTrade.order_id);
        } finally {
        }
    };

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };

    const sortedTrades = useMemo(() => {
    // Check if priceTrends is empty
    if (!trendingTrades || trendingTrades.length === 0) {
        // Return priceTrends as is or an empty array
        return trendingTrades || [];
    }

    return [...trendingTrades].sort((a, b) => {
        if (sortConfig.key === null) return 0;

        if (sortConfig.direction === 'asc') {
            return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
        } else {
            return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
        }
    });
}, [trendingTrades, sortConfig]);
    
    const trendingTradesHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('exchange')}><span>Exchange</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('ticker')}><span>Ticker</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('order_dt')}><span>Order Date</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('open_dt')}><span>Opened Date</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('open')}><span>Open Price</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('last_updated')}><span>Last Updated</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}}onClick={() => requestSort('pct_price_chg')}><span>% Change</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}} onClick={() => requestSort('status')}><span>Status</span></div>
                <div className="list-item-header" style={{mrginRight : 20 + "px"}}><span></span></div>  
                <div className="list-item-header" style={{mrginRight : 20 + "px"}}><span></span></div>             
            </div>
          )
    }

    const displayTrendingTrades = () => {    
        if (trendingTrades == undefined || trendingTrades.length < 1) {
            return '';
        }
        return (
            <React.Fragment>
                {sortedTrades.map(stockTrade => (
                    <div className="list-row" key={stockTrade.order_id} style={{ borderBottom: '1px solid #f5f5f5'}}>
                        <div className="list-item-stocks">{stockTrade.exchange}</div>
                        <div className="list-item-stocks"><a href={`/trends/${stockTrade.ticker}`} target="_blank" rel="noopener noreferrer">{stockTrade.ticker}</a></div>
                        <div className="list-item-stocks">{formatISODateShort(stockTrade.order_dt)}</div>
                        {(stockTrade.status !== "placed") ? (
                            <div className="list-item-stocks">{formatISODateShort(stockTrade.open_dt)}</div>
                        ) : 
                            <div className="list-item-stocks"></div>
                        }
                        <div className="list-item-stocks">{stockTrade.open}</div>
                        <div className="list-item-stocks">{formatISODateMonthHourMinute(stockTrade.last_updated)}</div>
                        <div className="list-item-stocks">{formatAsFloat(stockTrade.pct_price_chg, 1)}</div>
                        <div className="list-item-stocks">{capitalizeFirstLetter(stockTrade.status)}</div>
                        {/* An order that is placed or open can be closed */}
                        {(stockTrade.status == "closed") && (
                            <>
                                <div className="list-item-stocks"></div>
                                <div className="list-item-stocks"></div>
                            </>
                        )}
                        {(stockTrade.status == "open") && (
                            <>
                                <div className="list-item-stocks">
                                    <a onClick={() => updateTrade(stockTrade)} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Update</a>
                                </div>                            
                                <div className="list-item-stocks">
                                    <a onClick={() => closeTrade(stockTrade.order_id, onTradeEdit, onError, navigate)} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Close</a>
                                </div>
                            </>
                        )}
                        
                        {/* Only orders that are placed can be opened */}
                        {(stockTrade.status === "placed") && (
                            <>
                                <div className="list-item-stocks">
                                    <a onClick={() => deleteTrade(stockTrade)} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Delete</a>
                                </div>                            
                                <div className="list-item-stocks">
                                    <a onClick={() => openTrade(stockTrade.order_id, onTradeEdit, onError, navigate)} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Open</a>
                                </div>
                            </>
                        )}
                    </div>
                ))}
                {isModalOpen && <TrendingTradeEditModal trade={selectedTrade} isOpen={isModalOpen} onRequestClose={() => {setIsModalOpen(false); onTradeEdit()}} />}
              </React.Fragment>
          );
    }
    
    return (
                <React.Fragment>
                    {trendingTradesHeader()}
                    {displayTrendingTrades()}
                </React.Fragment>
            )
}

export default TrendingTrades;