import React from 'react';
import ClickableLogo from "../components/ClickableLogo";

const About = () => {
    // Inline CSS for h4 tag
    const h4Style = {
        display: 'inline',  // Makes h4 inline
        marginRight: '5px', // Adds some space after the h4
    };
    const liStyle = {
        marginBottom: '15px', // Adds some space after the h4
    };      
  return (
    <React.Fragment>
        <div className="content-container">
            <ClickableLogo />
            <div className="content" style={{width: 75 + "%"}}>
                <h3>Welcome to TrendingTrades.io – Discover Winning Trades in Any Market</h3>
                <p>
                    At TrendingTrades.io, we believe in the dynamic potential of the stock market. 
                    Whether the market is bullish or bearish, opportunities abound. 
                    Our mission is to uncover these opportunities by identifying stocks that are exhibiting clear upward or downward trends, making them prime candidates for swing trading.                </p>
                
                <h4>Our Philosophy: Trend-Based Trading</h4>
                <p>
                Our platform is built on the foundational belief that, regardless of overall market conditions, there are always stocks that are trending. TrendingTrades.io harnesses advanced algorithms and market analysis to detect these trends, offering you actionable trading recommendations.</p>
                <h4>How We Work</h4>
                <p>
                    <ol>
                        <li style={liStyle}><h4 style={h4Style}>Discover Trends:</h4>We scan the market to find stocks showing significant up or down trends.</li>
                        <li style={liStyle}><h4 style={h4Style}>Recommendations for the Next Day:</h4>Based on daily price movements, we provide trading suggestions for upcoming trading day. These recommendtions are available starting at 8:PM EST</li>
                        <li style={liStyle}><h4 style={h4Style}>Optimized for Swing Trading:</h4>Our recommendations are tailored for swing trading, focusing on short-term gains.</li>
                    </ol>
                </p>
                <h4>How We Work</h4>
                <p>
                    <ul>
                        <li style={liStyle}><h4 style={h4Style}>Profit Target:</h4>We advise selling the stock at a gain of 3% to 5%.</li>
                        <li style={liStyle}><h4 style={h4Style}>Time Frame:</h4>Alternatively, consider selling after 3 trading days, if profit target has not been met by then.</li>
                        <li style={liStyle}><h4 style={h4Style}>Risk Management:</h4>As with all trading, please follow a balanced approach. Limit your investment in a single trade to 5 percent of total capital.</li>
                    </ul>
                </p>
                <h4>Why to trade with TrendingTrades.io?</h4>
                <p>
                    <ul>
                        <li style={liStyle}><h4 style={h4Style}>Open Access Trading Resource:</h4>Our platform is offered at no cost, allowing traders from all backgrounds and experience levels to gain from reliable and quality trading insights.</li>
                        <li style={liStyle}><h4 style={h4Style}>No Conflict of Interest:</h4>Our platform operates independently, providing unbiased recommendations. This forms the cornerstone of our service, as we strive to discover new trading models to navigae through dynamic and exciting market conditions.</li>
                        <li style={liStyle}><h4 style={h4Style}>User-Friendly Interface:</h4>Our platform is designed to be intuitive and easy to navigate, ensuring a seamless trading experience.</li>
                        <li style={liStyle}><h4 style={h4Style}>Data-Driven Decisions:</h4>Our recommendations are backed by thorough market analysis and data-driven insights.</li>
                    </ul>
                </p>
                <h4>Have Questions or Feedback? We're Here to Help!</h4> 
                <ul>   
                    <p>
                        Your experience with TrendingTrades.io matters to us. If you have any questions, concerns, or feedback, don't hesitate to reach out. We're always here to support you and value your input as we strive to make our platform better each day.<br></br><br></br>
                        Contact us: <a href="mailto:pawan@trendingtrades.io">pawan@trendingtrades.io</a>
                    </p>
                    <p>
                        We look forward to hearing from you and helping you on your trading journey!
                    </p>
                </ul>
            </div>
        </div>
    </React.Fragment>
  );
};

export default About;
