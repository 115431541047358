import ClickableLogo from '../components/ClickableLogo';


const ServiceNotAvailable = () => {

    return (
        <div className="error-container">
            <ClickableLogo />
                <h2>WalkTheTalk API is not not available at this moment. Please try after few minutes.</h2>
                <p style={{paddingTop: 20}}>If the error persisis please contact admin.</p>
        </div>
    )
}

export default ServiceNotAvailable