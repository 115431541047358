// import React from 'react';
import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import MajorMarketIndexFunds from "home/components/MajorMarketIndexFunds";
import UpcomingTradesList from "home/components/UpcomingTradesList";
import OpenTradesListLive from "home/components/OpenTradesListLive";
import Collapsible from "common/components/CollapsibleComponent";

import Header from "common/components/Header";



const TrendingTradesPage = ({}) => {
    const navigate = useNavigate();

    return (
        <div className="content">
            <Header />
            <Collapsible title="Upcoming Trades">
                <UpcomingTradesList />
            </Collapsible>
            <hr></hr>
            <OpenTradesListLive/>
            <hr></hr>
            <Collapsible title="Major Market Index Funds">
                    <MajorMarketIndexFunds/>
            </Collapsible>
        </div>
    );   
}

export default TrendingTradesPage;