import React from 'react';

const DailyVolumeFilterComponent = ({ filterType, filterValue, count, handleFilterUpdate }) => {
  const handleFilterTypeChange = (event) => {
    const newFilterType = event.target.value;
    // Reset filterValue based on new filterType
    const newFilterValue = newFilterType === 'session' ? 'regular' : 'asc';
    handleFilterUpdate(newFilterType, newFilterValue, count);
  };

  const handleFilterValueChange = (event) => {
    handleFilterUpdate(filterType, event.target.value, count);
  };


  const handleCountChange = (event) => {
    console.log(`Updated count to ${event.target.value}`)
    handleFilterUpdate(filterType, filterValue, event.target.value);
  };

  return (
    <>
      <h2>Select Filter Type</h2>
      <div className="user-attr filter-attr">
        <label className="radio-container"> Session
          <input type="radio" name="filterType" value="session" checked={filterType === 'session'} onChange={handleFilterTypeChange} />
          <span className="options-radio"></span>
        </label>
        <label className="radio-container">Direction
          <input type="radio"name="filterType" value="direction" checked={filterType === 'direction'} onChange={handleFilterTypeChange}/>
          <span className="options-radio"></span>
        </label>
      </div>

      {filterType === 'session' && (
        <div style={{ marginTop: '20px' }}>
          <h3>Session Filters</h3>
          <div className="user-attr filter-attr">
            <label className="radio-container">Pre
              <input type="radio" name="filterValue" value="pre" checked={filterValue === 'pre'} onChange={handleFilterValueChange} />  
              <span className="options-radio"></span>
            </label>
            <label className="radio-container">Regular
              <input type="radio" name="filterValue" value="regular" checked={filterValue === 'regular'} onChange={handleFilterValueChange} />
              <span className="options-radio"></span>
            </label>
            <label className="radio-container">Post
            <input type="radio" name="filterValue" value="post" checked={filterValue === 'post'} onChange={handleFilterValueChange} />
              <span className="options-radio"></span>
            </label>
            <label className="radio-container">After Hours
            <input type="radio" name="filterValue" value="after" checked={filterValue === 'after'} onChange={handleFilterValueChange} />
              <span className="options-radio"></span>
            </label>            
          </div>
        </div>
      )}

      {filterType === 'direction' && (
        <div style={{ marginTop: '20px' }}>
          <h3>Direction Filters</h3>
          <div className="user-attr filter-attr">
            <label className="radio-container">Ascending
              <input type="radio" name="filterValue" value="asc" checked={filterValue === 'asc'} onChange={handleFilterValueChange} />
              <span className="options-radio"></span>
            </label>
            <label className="radio-container">Descending
              <input type="radio" name="filterValue" value="desc" checked={filterValue === 'desc'} onChange={handleFilterValueChange} />
              <span className="options-radio"></span>
            </label>
            <div className="filter-container">
              Count
              <select name="count" value={count} onChange={handleCountChange} className="filter-dropdown" id="filter-select">
                {Array.from({ length: 40 }, (_, i) => i + 1).map((count) => (
                  <option key={count} value={count}>{count}</option>
                ))}
              </select>
            </div>            
          </div>
        </div>
      )}
    </>
  );
};

export default DailyVolumeFilterComponent;
