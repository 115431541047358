import React from "react";
import { useLocation, Link } from "react-router-dom";
import { getQueryParam} from "utils/WT3ApiUtil"
import {capitalizeFirstLetter} from "utils/WT3DisplayUtil"

import './Breadcrumbs.css';

const Breadcrumbs = () => {
  const location = useLocation();
  const fromParam = getQueryParam(location, "from");
  const fromPath = fromParam ? fromParam.split('/').filter(x => x) : [];
  const currentPathnames = location.pathname.split('/').filter(x => x);
  const pathnames = fromPath.length > 0 ? fromPath : currentPathnames;

  return (
    <div aria-label="breadcrumb" className="crumb-container">
      <ol className="breadcrumb">
        {pathnames.length > 0 ? (
          <>
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;
              return (
                <React.Fragment key={name}>
                  <span> &gt;&gt; </span>
                  {isLast ? (
                    <li className="breadcrumb-item active">{name}</li>
                  ) : (
                    <li className="breadcrumb-item">
                      <Link to={routeTo}>{capitalizeFirstLetter(name)}</Link>
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <li className="breadcrumb-item active">Home</li>
        )}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
