import React from 'react';
import { useState, useEffect } from "react"
import { getDataFromAPI } from 'utils/WT3ApiUtil'

import Logo from 'common/components/Logo';
import Header from "common/components/Header";

function getProviderLoginConsentEndPoint(provider) {
    return `/wt3-api/login/url/${provider}`;
  }
const LogInPage = () => {

    const [googleLoginUrl, setGoogleLoginUrl] = useState('')

    useEffect( () => {
        const oauthProviderConsentUrl = async (provider) => {
            let providerLoginConsentEndPoint = getProviderLoginConsentEndPoint(provider);
            console.log(`provider login consent end point: ${providerLoginConsentEndPoint}`);
            try {
                let provideLoginUrl = await getDataFromAPI(providerLoginConsentEndPoint)
                console.log(`Provider Consent URL: ${provideLoginUrl}`);
                if ('google' === provider) {
                    setGoogleLoginUrl(provideLoginUrl);
                }
            } catch (e) {
                console.log(e);
            }
        }
        oauthProviderConsentUrl('google');
    }, []);

    return (
    <React.Fragment>
        <div className="login-container">
            <Logo />
            <h2>Log in to your account.</h2>
            <hr/>
            
            <button className="google-login-btn"  disabled={!googleLoginUrl} onClick={() => {window.location.href = googleLoginUrl}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="google-logo">
                    <path fill="#4285F4" d="M23.08 12.199a14.384 14.384 0 0 0-.203-2.426H11.773v4.594h6.36a5.564 5.564 0 0 1-.81 2.056 5.487 5.487 0 0 1-1.542 1.569v2.982h3.796c2.222-2.076 3.502-5.146 3.502-8.775z"></path><path fill="#34A853" d="M11.773 23.856c3.178 0 5.853-1.057 7.804-2.88l-3.796-2.981c-1.056.725-2.417 1.14-4.008 1.14-3.07 0-5.677-2.098-6.61-4.924h-3.91v3.073a11.871 11.871 0 0 0 4.341 4.797 11.661 11.661 0 0 0 6.18 1.775z"></path><path fill="#FBBC04" d="M5.164 14.209a7.235 7.235 0 0 1 0-4.566V6.57h-3.91a12.055 12.055 0 0 0 0 10.712l3.91-3.073z"></path><path fill="#EA4335" d="M11.773 4.722a6.347 6.347 0 0 1 4.517 1.79l3.36-3.405A11.233 11.233 0 0 0 11.773 0C9.591 0 7.452.615 5.594 1.776a11.871 11.871 0 0 0-4.34 4.796l3.91 3.073c.932-2.826 3.538-4.923 6.61-4.923z"></path></svg>
                <span className="google-login-text">Log in with Google</span>
            </button>
        </div>
    </React.Fragment>
    )
}

export default LogInPage