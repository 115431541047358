import React, { useState } from 'react';

const disableButtons = () => {
  document.querySelectorAll('.button-primary').forEach(button => {
    button.disabled = true;
  });
};

const enableButtons = () => {
  document.querySelectorAll('.button-primary').forEach(button => {
    button.disabled = false;
  });
};

const ProgressBar =() => {
  const [isShowing, setIsShowing] = useState(false);

  const showProgress = () => {
    setIsShowing(true);
    disableButtons();
  };

  const hideProgress = () => {
    setIsShowing(false);
    enableButtons();
  };

  return (
    <div className={`progress-bar ${isShowing ? 'animate_progress_bar' : ''}`}
         style={{
           background: '#f99200',
           height: '3px',
           width: '0%', // Set to '0%' since the animation itself handles the width transitions
         }}
    >
      {/* Render children components here and pass showProgress and hideProgress as props if needed */}
    </div>
  );
}

export default ProgressBar;