// import React from 'react';
import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import './tickerdetails.css';
import {toCamelCase} from "utils/WT3DisplayUtil"
import TickerLogo from "tickerdetails/TickerLogo"

const TickerDetailsWideContent = ({tickerDetails}) => {
    const navigate = useNavigate();

    const TickerDetailsWideContent = () => {
        return (
            <div>
                <div>
                    <TickerLogo ticker={tickerDetails.ticker} name={toCamelCase(tickerDetails.name)}></TickerLogo>
                </div>
                <div className="ticker_details_desc">{tickerDetails.description}</div>
                <div className="ticker_details_row">
                        {tickerDetails.location && (
                        <div>
                            <h6>HEADQUARTERS</h6>
                            <div className="ticker_details_row_cnt">{toCamelCase(tickerDetails.location)}</div>
                        </div>
                        )}
                    {tickerDetails.homepage_url && (
                    <div>
                        <h6>WEBSITE</h6>
                        <div className="ticker_details_row_cnt">
                        <a 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            href={tickerDetails.homepage_url}>
                            {tickerDetails.homepage_url.replace(/(^\w+:|^)\/\//, '').replace('www.', '')}
                        </a>
                        </div>
                    </div>
                )}

                {tickerDetails.total_employees && (
                <div>
                    <h6>EMPLOYEES</h6>
                    <div className="ticker_details_row_cnt">{tickerDetails.total_employees.toLocaleString()}</div>
                </div>
                )}

                {tickerDetails.market_cap && (
                <div>
                    <h6>MARKET CAP</h6>
                    <div className="ticker_details_row_cnt">{Math.trunc(tickerDetails.market_cap).toLocaleString()}</div>
                </div>
                )}
                </div>
                <div className="ticker_details_row">
                    <div>
                        <div className="ticker_details_row_lbl"><a href={`https://finance.yahoo.com/chart/${tickerDetails.ticker}`} target="_blank" rel="noopener noreferrer">Yahoo Finance</a></div>
                    </div>
                </div>
          </div>
        );
      };
      

    return (
        <div className="ticker_details_wide">
            <div className="ticker_details_content">
                    <TickerDetailsWideContent />
                </div>
        </div>
    );   
}

export default TickerDetailsWideContent;