import React from "react";

const TimeRangeRadioList = ({ selectedOption, handleOptionChange }) => {
  
  return (
    <div>
      <h4>TimeRanges</h4>
      <div className="user-attr filter-attr">
          <label className="radio-container">1 Month
            <input type="radio" name="timerange" value="40" 
                  checked={selectedOption === '40'} 
                  onChange={() => handleOptionChange('40')} />
            <span className="options-radio"></span>
          </label>

          <label className="radio-container">3 Months
          <input type="radio" name="timerange" value="80" 
                checked={selectedOption === '80'} 
                onChange={() => handleOptionChange('80')} />
          <span className="options-radio"></span>
          </label>

          <label className="radio-container">6 Months
            <input type="radio" name="timerange" value="150" 
                  checked={selectedOption === '150'} 
                  onChange={() => handleOptionChange('150')} />
            <span className="options-radio"></span>
          </label>

        <label className="radio-container">1 Year
          <input type="radio" name="timerange" value="275" 
                 checked={selectedOption === '275'} 
                 onChange={() => handleOptionChange('275')} />
          <span className="options-radio"></span>
        </label>
      </div>
      
    </div>
  );
};

export default TimeRangeRadioList;