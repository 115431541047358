import ClickableLogo from 'common/components/ClickableLogo';


const ServerErrorPage = () => {

    return (
        <div className="error-container">
            <ClickableLogo />
            <h2>Something went wrong. Please try again.</h2>
            <p style={{paddingTop: 20}}>If the error persisis please contact admin.</p>
        </div>
    )
}

export default ServerErrorPage