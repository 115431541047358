import React from "react";
import { useNavigate} from 'react-router-dom';


const LatestTradePositions = ({tradePositionsList}) => {

    const navigate = useNavigate();

    const handleRowClick = (event) => {
        const tickerElement = event.currentTarget.querySelector('.ticker');
        const ticker = tickerElement.textContent;
        // Construct the URL
        const url = `/positions/${ticker}`;
        window.open(url);
    };

    const displayTradePositions = () => {        
        return (
            tradePositionsList.map(tickerTradePositions => (
                    <div className="list-row list-row-clickable" key={tickerTradePositions.ticker} onClick={handleRowClick}>
                        <div className="list-item ticker">{tickerTradePositions.ticker}</div>
                        <div className="list-item" style={{ minWidth: '110px' }}>{tickerTradePositions.avg_size_sod}</div>
                        <div className="list-item" style={{ minWidth: '110px' }}>{tickerTradePositions.total_size_sod}</div>
                        <div className="list-item" style={{ minWidth: '110px' }}>{tickerTradePositions.avg_size_eod}</div>
                        <div className="list-item" style={{ minWidth: '110px' }}>{tickerTradePositions.total_size_eod}</div>
                      </div>
              ))//map
          ); //return
    }
    
    return (
                <div className="scrollable-div">{displayTradePositions()}</div>
            )
}

export default LatestTradePositions;