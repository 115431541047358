import React, { useState, useEffect, useMemo} from "react"
import TickerTradesDeltaBarChart from "tickertrades/TickerTradesDeltaBarChart"
import { LATEST_DELTA_TICKER_TRADES_ENDPOINT } from "utils/WT3ApiConstants"
import { getDataFromAPI, getQueryParam} from "utils/WT3ApiUtil"
import { useNavigate, useLocation} from 'react-router-dom';
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";

const TickerTradesDeltaBarChartList = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [tradesData, setTradesData] = useState([]);
    const [responseTickers, setTesponseTickers] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect( () => {
        
        const fetchLatestTickerTradesDelta = async (date, sequence) => {
            showProgress();
            setIsLoading(true);
            let latestTickerTradesDeltaEndpoint = `${LATEST_DELTA_TICKER_TRADES_ENDPOINT}`;
            
            let isQueryParamAdded = false;
            if (sequence) {
                latestTickerTradesDeltaEndpoint += `?sequence=${sequence}`;
                isQueryParamAdded = true;
            }
            
            if (date) {
                if (isQueryParamAdded) {
                    // If a parameter is already added, use '&'
                    latestTickerTradesDeltaEndpoint += `&date=${date}`;
                } else {
                    // If this is the first parameter, use '?'
                    latestTickerTradesDeltaEndpoint += `?date=${date}`;
                }
            }
    
            const response = await getDataFromAPI(latestTickerTradesDeltaEndpoint, navigate);
            if (response) {
                setTradesData(response['data']);
                setTesponseTickers(response['tickers'])
            }
            hideProgress();
            setIsLoading(false);
        };

        let sequence = getQueryParam(location, 'sequence');
        let date = getQueryParam(location, 'date');
        fetchLatestTickerTradesDelta(date, sequence);

    }, []);

    // Sort tickers based on pct_change_avg_size
    const sortedTickers = useMemo(() => {
        // Ensure that tradesData is an array
        if (Array.isArray(tradesData)) {
            return tradesData.sort((a, b) => {
                return (b?.pct_change_vw_price || 0) - (a?.pct_change_vw_price || 0);
            });
        }
        return []; // Return an empty array if tradesData is not an array
    }, [tradesData]);

    const DisplayTickersWithNoTradesData = () => {
        const dataTickersSet = new Set(tradesData.map(item => item.ticker));
        const missingTickers = responseTickers.filter(ticker => !dataTickersSet.has(ticker));
        
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h3>Loading latest ticker trades</h3>
                    </React.Fragment>
                </div>
            );            
        }
        return (
            <div>
                {missingTickers.map((ticker, index) => (
                    <React.Fragment>
                        <h3 key={index}>
                            No Trades fetched for <a href={`/intraday/${ticker}`}>{ticker}</a>
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                ))}
            </div>
        );
    };
    
    

    return (
        <div className="bar-chart-list">
            <ProgressBar />
            {sortedTickers.map((tickerData, index) => {
                // Ensure tickerData is not null or undefined
                if (tickerData) {
                    return (
                        <div key={tickerData.ticker}>
                            <TickerTradesDeltaBarChart tickerTradesDelta={tickerData} />
                        </div>
                    );
                } else {
                    // Assign a unique key using the index for the 'No trades found' message
                    return <h2 key={`not-found-${index}`}>No trades found</h2>;
                }
            })}
            {DisplayTickersWithNoTradesData()}
        </div>
    );
};

export default TickerTradesDeltaBarChartList;
