import React, { useState, useEffect } from 'react';

import TrendingTradesHomeMobile from 'home/TrendingTradesHomeMobile'
import TrendingTradesHomeDesktop from 'home/TrendingTradesHomeDesktop'
import PageLoaderView from "common/components/PageLoaderView";

const TrendingTradesLandinPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Choose the component based on window width
    const HomepageComponent = windowWidth > 768 ? TrendingTradesHomeDesktop : TrendingTradesHomeMobile;

    useEffect(() => {
        // Simulate a loading time
        setTimeout(() => setLoading(false), 2000);
    }, []);

    if (loading) {
        return <PageLoaderView />;
    }

    return (
        <div>
            <HomepageComponent />
        </div>
    );
};

export default TrendingTradesLandinPage;
