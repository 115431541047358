import React, { useState, useEffect, useRef } from "react"
import { useNavigate} from 'react-router-dom';
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import {CloseButton} from 'admin/styles/StyleComponents'

import Modal from 'react-modal';

// Import Highcharts and the necessary components
// import Highcharts from 'highcharts';
// import { HighchartsChart, Chart, withHighcharts, stockChart } from 'react-jsx-highcharts';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { getDataFromAPI} from "../../utils/WT3ApiUtil"
import { INTRADAY_AGGREGATES_END_POINT } from "utils/WT3ApiConstants"

Modal.setAppElement('#root'); // Replace '#root' with your app element ID

const ChartPriceMovementIntraday = ({ticker, date, isOpen, onRequestClose}) => {
    // date = "2023-12-15"
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',  // Adjust the width as needed
          height: '60%', // Adjust the height as needed
          overflow: 'auto' // Add scroll if content is too long
        }
      };

    const chartComponentRef = useRef(null);
    const [intradayAggregates, setIntradayAggregates] = useState([]);
    const [chartWidth, setChartWidth] = useState(null);

    const navigate = useNavigate();

    const fetchAggregates = async (ticker) => {
        console.log(`Fetching intraday aggregates for ${ticker}`);
        showProgress();
        showLoading();
        let intradayAggregatesEndpoint = `${INTRADAY_AGGREGATES_END_POINT}/${ticker}/${date}`
        try {
            const response = await getDataFromAPI(intradayAggregatesEndpoint, navigate);
            if (response) {
                setIntradayAggregates(response['data']);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // handle error scenario
        } finally {
            hideLoading(); // Hide loading after fetching data
            hideProgress();
        }
    };

    const showLoading = () => {
        if (chartComponentRef.current && chartComponentRef.current.chart) {
            chartComponentRef.current.chart.showLoading();
        }
    };

    const hideLoading = () => {
        if (chartComponentRef.current && chartComponentRef.current.chart) {
            chartComponentRef.current.chart.hideLoading();
        }
    };
    
    useEffect(() => {
        fetchAggregates(ticker);
    }, [ticker]);

    useEffect(() => {
        const handleResize = () => {
            if (chartComponentRef.current) {
                setChartWidth(chartComponentRef.current.container.current.offsetWidth);
            }
        };

        // Set initial size
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
           
    const drawPriceMovements = (price_series) => {
        // Set the utc to False globally for Highcharts
        Highcharts.setOptions({
            global: {
                useUTC: false,
                timezoneOffset: 5 * 60
            }
        }); 

        const options = {
            chart: {
                backgroundColor: '#ffffff',
            // Dynamic width based on state
                width: chartWidth,
                height: chartWidth * (2.5 / 4), // Or any other aspect ratio you prefer                
                zooming: {
                    mouseWheel: {
                      enabled: false
                    }
                  },
                  accessibility: {enabled: false}
            },
            title: {
                text: `Intraday Chart: ${ticker}, ${date} `,
                style: {
                    color: '#535353',
                    fontSize: '12px'
                    
                }                
            },            
            rangeSelector: {
                enabled: false, // Disables the date range selector
            },                       
            plotOptions: {
                line: {
                    color: '#f99200',
                    opacity: .7
                }
            },
            navigator: {
                enabled: false, // Disable the navigator
            },
            scrollbar: {
                enabled: false, // Disable the scrollbar
            },
            series: [{
                name: 'Closing Price',
                data: price_series
            }],
            tooltip: {
                formatter: function() {
                    const dateStr = Highcharts.dateFormat('%b %e, %I:%M %p', this.x);
                    const valueStr = Highcharts.numberFormat(this.y, 2); // Format value to two decimals
                    return `${dateStr}<br/><b>Close: ${valueStr}</b>`;
                }
            },            
            // time: {
            //     timezone: 'America/New_York'
            // },
            yAxis: {
                opposite: false,
            },
            xAxis: {
                type: 'datetime'
            },            
        };
        // In your component's render method
        return (
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
                ref={chartComponentRef}
            />
        );                
    };

      const processDataForHighstock = (data) => {
        return data.map(item => {
            // Extracting the timestamp and the closing price
            return [
                new Date(item.datetime).getTime(), // Convert datetime to Unix timestamp in milliseconds
                item.quote.close // The closing price
            ];
        });
    };
    

    const chartTickerPriceMovements = () => {
        if (intradayAggregates == undefined || intradayAggregates.length < 1) {
            return "";
        }
        console.log("inside chart price movements......");
        console.log(`Intraday aggregates fetched for ${ticker}: ${intradayAggregates.length}`);   
        if (intradayAggregates == undefined || intradayAggregates.length < 1) {
            return '';
        }
        let closing_timeseries_data = processDataForHighstock(intradayAggregates);
        return drawPriceMovements(closing_timeseries_data);
    }

    return (
                <React.Fragment>
                    <ProgressBar />
                    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Intrday Chart" style={customStyles}>
                        <CloseButton onClick={onRequestClose}>&times;</CloseButton>
                        {chartTickerPriceMovements()}
                    </Modal>
                </React.Fragment>
            )
}

export default ChartPriceMovementIntraday;