import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';

const pusher = new Pusher('c6faee3a5e4561bceda8', {
  cluster: 'us2'
});

// The channel name should be dynamic based on the hostname
const hostname = window.location.hostname;
const channelName = `price-movement-${hostname}`;
console.log(`Fetching messages from: ${channelName}`)

const MessagesComponent = () => {
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const channel = pusher.subscribe(channelName);

    channel.bind('generate', function(message) {
      console.log(message);
      setMessages(prevMessages => ({
        ...prevMessages,
        ...message // message is expected to be an object like {exchange: 'Tickers fetched'}
      }));
    });

    // Cleanup subscription on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  return (
    <div>
      <h1>Messages</h1>
      <ul>
        {Object.entries(messages).map(([exchange, message]) => (
          <li key={exchange}>
            <strong>{exchange}:</strong> {message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MessagesComponent;
