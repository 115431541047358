import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getDataFromAPI, getTickerDetailsEndpoint, getQueryParam } from 'utils/WT3ApiUtil'
import { TICKER_INTRADAY_MOVEMENT, TICKER_INTRADAY_DIRECTION } from "utils/WT3ApiConstants"
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import DailyMovementLineChart from "tickertrades/components/DailyMovementLineChart"

import Header from "common/components/Header";


const TickerIntradayTrendsPage = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { ticker } = useParams();

    const [tickerDetails, setTickerDetails] = useState([])
    const [intradayDirection, setIntradayDirection] = useState({});
    const [intradayMovement, setIntradayMovement] = useState({});

    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    useEffect(() => {
        console.log(`Ticker: ${ticker}`)
        const drawIntradayDirection = async () => {
            const data = await fetchIntradayDirection(ticker);
            console.log(data['volume']);
            if (data) {
                setIntradayDirection(data);
            }
        };

        const drawIntradayMovement = async () => {
            const data = await fetchIntradayMomenent(ticker);
            // console.log(data['eod']);
            if (data) {
                setIntradayMovement(data);
            }
        };

        drawIntradayMovement();
        drawIntradayDirection();
    }, [ticker]); // Add ticker to dependencies



    const fetchIntradayDirection = async (ticker) => {
        showProgress();
        setIsLoading(true);

        let dateStr = getQueryParam(location, 'date');
        let tickerTradePositionsEndPoint = `${TICKER_INTRADAY_DIRECTION}/${ticker}`;
        if (dateStr) {
            tickerTradePositionsEndPoint += `?date=${dateStr}`;
        }
        
        console.log("tickerTradePositionsEndPoint:" + tickerTradePositionsEndPoint);

        const response = await getDataFromAPI(tickerTradePositionsEndPoint, navigate);
        if (response && response['updated_tickers'] > 0) {
            hideProgress();
            setName(response['name']);
            setIsLoading(false);
            return response['data']; // Return the fetched data
        }
        setIsLoading(false);
        hideProgress();
        return null; // Return null if no data is fetched
    };

    const fetchIntradayMomenent = async (ticker) => {
        showProgress();
        setIsLoading(true);
        let dateStr = getQueryParam(location, 'date');
        let tickerTradePositionsEndPoint = `${TICKER_INTRADAY_MOVEMENT}/${ticker}`;
        if (dateStr) {
            tickerTradePositionsEndPoint += `?date=${dateStr}`;
        }
          

        const response = await getDataFromAPI(tickerTradePositionsEndPoint, navigate);
        if (response && response['updated_tickers'] > 0) {
            hideProgress();
            setName(response['name']);
            setIsLoading(false);
            return response['data']; // Return the fetched data
        }
        setIsLoading(false);
        hideProgress();
        return null; // Return null if no data is fetched
    };

    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;

        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading ticker trades for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTickerIntradayMovementEmpty = (intradayDirection.length === 0);

        if (( intradayDirection == undefined) || isTickerIntradayMovementEmpty === true) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No intraday movements fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };
    const datasetItems = [
        { key: 'volume', label: 'Volume', borderColor: 'rgb(255, 127, 80)', borderWidth: 2, pointRadius: 1.5},
        { key: 'vw_price', label: 'Price', borderColor: 'rgb(94, 124, 208)', borderWidth: 2, pointRadius: 1.5},
        { key: 'density', label: 'Density', borderColor: 'rgb(175, 195, 21)', borderWidth: 2, pointRadius: 1.5}
        // { key: 'return3', label: 'Return 3', borderColor: 'rgb(52, 151, 152)', borderWidth: 2, pointRadius: 1.5},
        // { key: 'return5', label: 'Return 5', borderColor: 'rgb(55, 13, 208)', borderWidth: 2, pointRadius: 1.5}
      ];

    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <ProgressBar />
                    {DisplayMessageIfNoTickerTrades()}
                    <div>
                        <div>
                            {intradayDirection !== undefined && Object.keys(intradayDirection).length > 0 &&
                                <DailyMovementLineChart dailyMovements={intradayDirection} datasetItems={datasetItems} initialOption="vw_price"></DailyMovementLineChart>
                            }
                        </div> 
                    </div>
                    {/* <div>
                        <div>
                            {intradayMovement !== undefined && Object.keys(intradayMovement).length > 0 &&
                                <DailyMovementLineChart intradayMovements={intradayMovement} datasetItems={datasetItems} initialOption="vw_price"></DailyMovementLineChart>
                            }
                        </div> 
                    </div>                              */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default TickerIntradayTrendsPage