import React, { useState, useEffect, useMemo } from "react"
import { useNavigate} from 'react-router-dom';
import { getDataFromAPI } from 'utils/WT3ApiUtil'
import Header from "common/components/Header";
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import PriceTrendsOverview from "admin/components/PriceTrendsOverview";
import ExchangeRadioList from 'admin/components/ExchangeRadioList'; // Import the TrendCheckboxes component
import TimeRangeRadioList from 'admin/components/TimeRangeRadioList';
import PriceTrendsFilterComponentChain from 'admin/components/PriceTrendsFilterComponentChain'

import ServiceNotAvailable from 'common/pages/ServiceNotAvailable'

const PriceTrendsOverviewPage = () => {

    console.log('Rendering PriceTrendsOverview.....');

    const navigate = useNavigate();


    const [priceTrends, setPriceTrends] = useState([])
    const [filteredPriceTrends, setFilteredPriceTrends] = useState([]); // Filtered data
    const [dateRange, setDateRange] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [selectedExchange, setSelectedExchange] = useState('NYSE');
    const [selectedTimeRange, setSelectedTimeRange] = useState("1");
    const [loading, setLoading] = useState(true); // Assume loading is true by default

    const getpriceTrendsEndpoint = (exchange, timeRange) => {
      return `/wt3-api/analytics/${exchange}/timerange/${timeRange}`;
    };
        
    const [filterOptions, setFilterOptions] = useState({
      ticker: { value: '', rule: 'eq', name: 'Ticker' },
      trade_return_1: { value: '', rule: 'gte', name: 'Return(1)' },
      price_change_3: { value: '', rule: 'gte', name: 'Change(3)' },
      price_change_5: { value: '', rule: 'gte', name: 'Change(5)' },
      price_change_10: { value: '', rule: 'gte', name: 'Change(10)' },
    });

    const sortedAndFilteredPriceTrends = useMemo(() => {
      // Check if priceTrends is empty
      if (!filteredPriceTrends || filteredPriceTrends.length === 0) {
        // Return priceTrends as is or an empty array
        return filteredPriceTrends || [];
      }

      return [...filteredPriceTrends].sort((a, b) => {
          if (sortConfig.key === null) return 0;

          if (sortConfig.direction === 'asc') {
              return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
              return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
      });
  }, [filteredPriceTrends, sortConfig]);
  

  useEffect(() => {
    
    const applyFilters = () => {
      if (priceTrends == undefined || priceTrends.length === 0) {
        console.log('No price trends data available.');
        setFilteredPriceTrends([]);
        return;
      }
    
      let filteredData = priceTrends.filter((trend) => {
        return Object.keys(filterOptions).every(key => {
          if (!trend.hasOwnProperty(key) || filterOptions[key].value === '') return true;
          
          const filterType = typeof trend[key];
          const filterValue = filterType === 'number' ? parseFloat(filterOptions[key].value) : filterOptions[key].value;
        
          // Check for NaN values in filterValue when it's supposed to be a number
          if (filterType === 'number' && isNaN(filterValue)) {
            console.log(`NaN encountered for ${key}`);
            return true;
          }
    
          let result;
          switch (filterOptions[key].rule) {
            case 'eq':
              result = trend[key] === filterValue;
              break;
            case 'gte':
              result = filterType === 'number' && trend[key] >= filterValue;
              break;
            case 'lte':
              result = filterType === 'number' && trend[key] <= filterValue;
              break;
            default:
              result = true;
          }
    
          return result;

        });
      });    
      setFilteredPriceTrends(filteredData);
    };
  
    applyFilters();
  }, [filterOptions, priceTrends]);
  
  
    const displayPriceTrendsOverviewHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header" onClick={() => requestSort('Ticker')}><span>Ticker</span></div>
                <div className="list-item-header" onClick={() => requestSort('trade_return_1')}><span>Return(1)</span></div>
                {!(selectedTimeRange === '1') && (
                  <React.Fragment>
                    <div className="list-item-header" onClick={() => requestSort('trade_return_3')}><span>Return(3)</span></div>
                    <div className="list-item-header" onClick={() => requestSort('trade_return_5')}><span>Return(5)</span></div>
                  </React.Fragment>
                )}
                <div className="list-item-header" onClick={() => requestSort('price_change_3')}><span>%Change(3)</span></div>
                <div className="list-item-header" onClick={() => requestSort('price_change_5')}><span>%Change(5)</span></div>
                <div className="list-item-header" onClick={() => requestSort('price_change_10')}><span>%Change(10)</span></div>
                {(selectedTimeRange === '1') && (
                  <React.Fragment>                
                    <div className="list-item-header" onClick={() => requestSort('rsi5')}><span>RSI(5)</span></div>
                    <div className="list-item-header" onClick={() => requestSort('mad')}><span>Mad</span></div>
                  </React.Fragment>
                )} 
            </div>
          )
    }

    const displayDateRange = () => {
        // Check if priceTrends is empty
      if (loading) {
        return <div>Loading price trends...</div>;
      }
      if (!priceTrends || priceTrends.length === 0) {
          // Return priceTrends as is or an empty array
          // return '';
          return <div>No price trends available.</div>;

        }      
      return (
            <React.Fragment>
                <h5>Date Range: {dateRange['start']} - {dateRange['end']}</h5>
                <h5>Rows: {sortedAndFilteredPriceTrends.length}</h5>
            </React.Fragment>
          )
      }

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };


        // Handle exchange changes
        const handleExchangeOptionChange = (exchangeOption) => {
          setPriceTrends([]);
          setSelectedExchange(exchangeOption);
        };     

        // Handle exchange changes
        const handleTimeRangeOptionChange = (timeRangeOption) => {
          setPriceTrends([]);
          setSelectedTimeRange(timeRangeOption);
        };

        const handleAllFiltersChange = (filterConfigs) => {
          let filteredData = [...priceTrends]; // Replace with your original data
        
          filterConfigs.forEach(filterConfig => {
            if (filterConfig.key && filterConfig.value) {
              const filterValue = parseFloat(filterConfig.value);
              if (filterConfig.rule === 'gte') {
                filteredData = filteredData.filter(item => item[filterConfig.key] >= filterValue);
              } else if (filterConfig.rule === 'lte') {
                filteredData = filteredData.filter(item => item[filterConfig.key] <= filterValue);
              } else if (filterConfig.rule === 'eq') {
                filteredData = filteredData.filter(item => item[filterConfig.key].toString().startsWith(filterConfig.value));
              }
            }
          });

          setFilteredPriceTrends(filteredData); // Update the state with the filtered data
        };
        
        
        useEffect(() => {
          // console.log(`Time Range: ${selectedTimeRange}`);
          // console.log(`Exchange: ${selectedExchange}`);
          
            const fetchpriceTrends = async () => {
              showProgress();
              setLoading(true); // Set loading to true before fetching data
              const priceTrendsEndpoint = getpriceTrendsEndpoint(selectedExchange, selectedTimeRange);
              // console.log("Fetching price trends: " + priceTrendsEndpoint);
              const priceTrendsResponse = await getDataFromAPI(priceTrendsEndpoint, navigate);
              // console.log(priceTrendsResponse);
              if (priceTrendsResponse == undefined) {
                return <ServiceNotAvailable/ >
              } 
              setPriceTrends(priceTrendsResponse['data']);
              // console.log(priceTrends);
              setDateRange(priceTrendsResponse['daterange']);
              hideProgress();     
              setLoading(false); // Set loading to false after fetching data  
            };
            fetchpriceTrends();
          }, [selectedExchange, selectedTimeRange]);
          
    return (
    <div className="content">
        <Header />
        <ExchangeRadioList selectedOption={selectedExchange} handleOptionChange={handleExchangeOptionChange} />
        <TimeRangeRadioList selectedOption={selectedTimeRange} handleOptionChange={handleTimeRangeOptionChange} displayType={"overview"}/>
        <PriceTrendsFilterComponentChain filterOptions={filterOptions}  handleAllFiltersChange={handleAllFiltersChange}/>
        <div className="list-container">
                {displayDateRange()}
                <ProgressBar />
                {displayPriceTrendsOverviewHeader()}
                <PriceTrendsOverview priceTrendsList={sortedAndFilteredPriceTrends} selectedTimeRange={selectedTimeRange}/>
        </div>        
    </div>
    )
}
export default PriceTrendsOverviewPage;