import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { getDataFromAPI, getQueryParam } from 'utils/WT3ApiUtil'
import {LATEST_DAILY_MOVEMENTS} from 'utils/WT3ApiConstants'
import Header from "common/components/Header";
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import ExchangeRadioList from 'admin/components/ExchangeRadioList';
import PriceTrendsFilterComponentChain from 'admin/components/PriceTrendsFilterComponentChain'
import LatestDailyMovements from "tickertrades/components/LatestDailyMovements";
import ServiceNotAvailable from 'common/pages/ServiceNotAvailable'

const LatestDailyMovementsListPage = () => {

    console.log('Rendering dailyMovements.....');

    const navigate = useNavigate();
    
    const location = useLocation();

    const [dailyMovements, setDailyMovements] = useState([])
    const [filteredDailyMovements, setFilteredDailyMovements] = useState([]); // Filtered data
    const [dateRange, setDateRange] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [selectedExchange, setSelectedExchange] = useState('NASDAQ');
    const [loading, setLoading] = useState(true); // Assume loading is true by default

    const getDailyMovementsEndpoint = (exchange) => {
      let offset = getQueryParam(location, 'offset');
      if (offset) {
        return `${LATEST_DAILY_MOVEMENTS}/${exchange}?offset=${offset}`;
      } else {
        return `${LATEST_DAILY_MOVEMENTS}/${exchange}`;
      }
    };
    
    const [filterOptions, setFilterOptions] = useState({
      ticker: { value: '', rule: 'eq', name: 'Ticker' },
      volume: { value: '', rule: 'gte', name: 'Volume' },
      vw_price: { value: '', rule: 'gte', name: 'Price (EOD)' },
      return3: { value: '', rule: 'gte', name: 'Return (3)' },
      return5: { value: '', rule: 'gte', name: 'Return (5)' }
    });
        
    const sortedFiltereddailyMovements = useMemo(() => {
      if (!filteredDailyMovements || filteredDailyMovements.length === 0) {
        return filteredDailyMovements || [];
      }

      return [...filteredDailyMovements].sort((a, b) => {
          if (sortConfig.key === null) return 0;

          if (sortConfig.direction === 'asc') {
              return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
              return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
      });
  }, [filteredDailyMovements, sortConfig]);    

  const handleAllFiltersChange = (filterConfigs) => {
    let filteredData = [...dailyMovements]; // Replace with your original data
  
    filterConfigs.forEach(filterConfig => {
      if (filterConfig.key && filterConfig.value) {
        const filterValue = parseFloat(filterConfig.value);
        if (filterConfig.rule === 'gte') {
          filteredData = filteredData.filter(item => item[filterConfig.key] >= filterValue);
        } else if (filterConfig.rule === 'lte') {
          filteredData = filteredData.filter(item => item[filterConfig.key] <= filterValue);
        } else if (filterConfig.rule === 'eq') {
          filteredData = filteredData.filter(item => item[filterConfig.key].toString().startsWith(filterConfig.value));
        }
      }
    });

    setFilteredDailyMovements(filteredData); // Update the state with the filtered data
  };

  useEffect(() => {
    
    const applyFilters = () => {
      if (dailyMovements == undefined || dailyMovements.length === 0) {
        console.log('No trade positions data available.');
        setFilteredDailyMovements([]);
        return;
      }
    
      let filteredData = dailyMovements.filter((trend) => {
        return Object.keys(filterOptions).every(key => {
          if (!trend.hasOwnProperty(key) || filterOptions[key].value === '') return true;
          
          const filterType = typeof trend[key];
          const filterValue = filterType === 'number' ? parseFloat(filterOptions[key].value) : filterOptions[key].value;
        
          // Check for NaN values in filterValue when it's supposed to be a number
          if (filterType === 'number' && isNaN(filterValue)) {
            console.log(`NaN encountered for ${key}`);
            return true;
          }
    
          let result;
          switch (filterOptions[key].rule) {
            case 'eq':
              result = trend[key] === filterValue;
              break;
            case 'gte':
              result = filterType === 'number' && trend[key] >= filterValue;
              break;
            case 'lte':
              result = filterType === 'number' && trend[key] <= filterValue;
              break;
            default:
              result = true;
          }
    
          return result;

        });
      });    
      setFilteredDailyMovements(filteredData);
    };
  
    applyFilters();
  }, [filterOptions, dailyMovements]);

    const displaydailyMovementsHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header" onClick={() => requestSort('ticker')}><span>Ticker</span></div>
                <div className="list-item-header" onClick={() => requestSort('volume')}><span>Volume</span></div>
                <div className="list-item-header" onClick={() => requestSort('vw_price')}><span>Price</span></div>
                <div className="list-item-header" onClick={() => requestSort('return3')}><span>Return(3)</span></div>
                <div className="list-item-header" onClick={() => requestSort('return5')}><span>Return(5)</span></div>
            </div>
          )
    }


    const handleExchangeOptionChange = (exchangeOption) => {
      setDailyMovements([]);
      setSelectedExchange(exchangeOption);
    };     


    const displayDateRange = () => {
        // Check if dailyMovements is empty
      if (loading) {
        return <div>Loading price trends...</div>;
      }
      if (!dailyMovements || dailyMovements.length === 0) {
          // Return dailyMovements as is or an empty array
          // return '';
          return <div>No price trends available.</div>;

        }      
      return (
            <React.Fragment>
                <h5>Date Range: {dateRange['start']} - {dateRange['end']}</h5>
                <h5>Rows: {sortedFiltereddailyMovements.length}</h5>
            </React.Fragment>
          )
      }

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };



      useEffect(() => {
          const fetchDailyMovements = async () => {
            showProgress();
            setLoading(true); // Set loading to true before fetching data
            const dailyMovementsEndpoint = getDailyMovementsEndpoint(selectedExchange);
            // console.log("Fetching price trends: " + dailyMovementsEndpoint);
            const dailyMovementsResponse = await getDataFromAPI(dailyMovementsEndpoint, navigate);
            // console.log(dailyMovementsResponse);
            if (dailyMovementsResponse == undefined) {
              return <ServiceNotAvailable/ >
            } 
            setDailyMovements(dailyMovementsResponse['data']);
            // console.log(dailyMovements);
            setDateRange(dailyMovementsResponse['daterange']);
            hideProgress();     
            setLoading(false); // Set loading to false after fetching data  
          };
          fetchDailyMovements();
        }, [selectedExchange]);
          
    return (
    <div className="content">
        <Header />
        <ExchangeRadioList selectedOption={selectedExchange} handleOptionChange={handleExchangeOptionChange} />
        <PriceTrendsFilterComponentChain filterOptions={filterOptions}  handleAllFiltersChange={handleAllFiltersChange}/>
        <div className="list-container">
            {displayDateRange()}
            <ProgressBar />
            {displaydailyMovementsHeader()}
            <LatestDailyMovements dailyMovementsList={sortedFiltereddailyMovements}/>
        </div>        
    </div>
    )
}
export default LatestDailyMovementsListPage;