// import React from 'react';
import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import { getDataFromAPI} from "../../utils/WT3ApiUtil"

import TickerDetailsWideComponent from "../../tickerdetails/TickerDetailsWideComponent"
import ChartPriceMovementLive from "./ChartPriceMovementLive"

const OpenTradeDetailsComponent = ({stockTrade}) => {
    const navigate = useNavigate();
    const [tickerDetails, setTickerDetails] = useState([])
    const [ticker, setTicker] = useState(stockTrade.ticker)


    const getTickerDetailsEndpoint = (ticker) => {
        return `/wt3-api/ticker/${ticker}/details`;
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                console.log(tickerDetailsEndPoint);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    return (
        <React.Fragment>
            <div className="open-trade-details-wrapper">
                <div className="live-charts">
                    <ChartPriceMovementLive stockTrade={stockTrade} />
                </div>
                <div className="ticker-details-wrapper">
                    {(tickerDetails.exchange_name === "NYSE" || tickerDetails.exchange_name === "NASDAQ") && (
                    <TickerDetailsWideComponent tickerDetails = {tickerDetails} />
                    )}
                </div>               
            </div>
        </React.Fragment>     
    );
}

export default OpenTradeDetailsComponent;