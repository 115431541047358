import React, { useState } from 'react';

import './collapsible.css';


const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="collapsible" onClick={toggleCollapsible}>
        <span className="collapsible_title">{title}</span>
        <span className="collapsible-toggle-icon">
          {isOpen ? '-' : '+'} {/* Toggle between - and + */}
        </span>
      </div>
      <div
        className="collapsible_content"
        style={{
          maxHeight: isOpen ? '1000px' : '0', // Adjust as needed
          overflow: 'hidden',
          transition: 'max-height 0.2s ease-out'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;