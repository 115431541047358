import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getDataFromAPI, getTickerDetailsEndpoint } from 'utils/WT3ApiUtil'
import { INTRADAY_DIVERGENCE_END_POINT } from "utils/WT3ApiConstants"
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import AggregatesLineChart from "analysis/components/AggregatesLineChart"
import {CloseButton} from 'admin/styles/StyleComponents'
import {Bars} from 'react-loader-spinner';

import Header from "common/components/Header";
import Modal from 'react-modal';

  export  const datasetItems = [
    { key: 'vw_price', label: 'VWAP', borderColor: 'rgb(94, 14, 08)', borderWidth: 2, pointRadius: 1.5},
    { key: 'vw_hl', label: 'vw_hl', borderColor: 'rgb(94, 124, 208)', borderWidth: 2, pointRadius: 1.5},
    { key: 'vw_co', label: 'vw_co', borderColor: 'rgb(255, 127, 80)', borderWidth: 2, pointRadius: 1.5},
  ];


export const fetchIntradayDivergence = async (ticker, navigate, timeRange) => {
    let tickerIntradayAggregatesEndPoint = getIntradayDivergenceEndPoint(ticker);
    tickerIntradayAggregatesEndPoint = `${tickerIntradayAggregatesEndPoint}?window=${timeRange}`
    console.log("tickerIntradayAggregatesEndPoint:" + tickerIntradayAggregatesEndPoint);

    const response = await getDataFromAPI(tickerIntradayAggregatesEndPoint, navigate);
    if (response) {
        console.log(response['data']);
        return response['data'];
    }
    return null; // Return null if no data is fetched
};

export const getIntradayDivergenceEndPoint = (ticker) => {
    return INTRADAY_DIVERGENCE_END_POINT.replace("[ticker]", ticker);

}

const IntradayAggregatesModal = ({ ticker, isOpen, onRequestClose }) => {
    const [intradayAggregates, setIntradayAggregates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTimeRange, setSelectedTimeRange] = useState("1");

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const intradayAggregates = await fetchIntradayDivergence(ticker, navigate, selectedTimeRange);
                setIntradayAggregates(intradayAggregates);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isOpen) {
            fetchData();
        }
    }, [ticker, isOpen, location, navigate]);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',  // Adjust the width as needed
          height: '60%', // Adjust the height as needed
          overflow: 'auto' // Add scroll if content is too long
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
            <h2>{ticker}</h2>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Bars
                        height="100"
                        width="100"
                        color="#f99200"
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>
            ) : (
                intradayAggregates.length > 0 && (
                    <React.Fragment>
                        <AggregatesLineChart aggregates={intradayAggregates} datasetItems={datasetItems} initialOption="vw_hl" />
                        <CloseButton onClick={onRequestClose}>&times;</CloseButton>
                    </React.Fragment>
                )
            )}
        </Modal>
    );
};

const IntradayDivergencePage = ({}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTimeRange, setSelectedTimeRange] = useState("1");
    const [isLoading, setIsLoading] = useState(false)

    const { ticker } = useParams();

    const [intradayAggregates, setIntradayAggregates] = useState([]);
    const [tickerDetails, setTickerDetails] = useState([])

    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;
    
        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );

    
    useEffect(() => {
        const fetchData = async () => {
            showProgress();
            setIsLoading(true)
            let intradayAggregates = await fetchIntradayDivergence(ticker, navigate, selectedTimeRange);
            console.log(intradayAggregates);
            setIntradayAggregates(intradayAggregates);
            hideProgress();
            setIsLoading(false)
        };

        fetchData();
    }, [ticker, selectedTimeRange]);

    // Handle exchange changes
    const handleTimeRangeOptionChange = (timeRangeOption) => {
        setIntradayAggregates([]);
        setSelectedTimeRange(timeRangeOption);
    };

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading intraday aggregates for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTickerIntradayAggregatesEmpty = (intradayAggregates.length === 0);

        if (( isLoading == false 
            && (intradayAggregates == undefined) || isTickerIntradayAggregatesEmpty === true)) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No aggregates fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <ProgressBar />
                    {DisplayMessageIfNoTickerTrades()}
                    <div>
                        <div>
                            {intradayAggregates !== undefined && Object.keys(intradayAggregates).length > 0 &&
                                <AggregatesLineChart aggregates={intradayAggregates} datasetItems={datasetItems} initialOption="vw_hl"></AggregatesLineChart>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export { IntradayDivergencePage, IntradayAggregatesModal };
