export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
}

export const isValidPassword = (password) => {
    return password && password.length >= 8;
}

export const isValidName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/; // This will allow spaces as well, in case of multiple words in a name.
    return name && nameRegex.test(name);
}

export const formatISODate = (isoDateStr) => {
	const isoDate = new Date(isoDateStr);
	const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true};
	const formattedDate = isoDate.toLocaleString('en-US', options);
	return formattedDate
}

export const formatISODateMonthHourMinute = (isoDateStr) => {
    if (isoDateStr == undefined || isoDateStr == null) {
        return "";
    }
    const isoDate = new Date(isoDateStr);
    const day = isoDate.getDate(); // gets the day as a number
    const month = isoDate.toLocaleString('en-US', { month: 'short' }); // gets the abbreviated month name
    const time = isoDate.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }); // gets the time in 12-hour format with minutes
    return `${day} ${month}, ${time}`;
};

export const formatISODateShort = (isoDateStr) => {
	const isoDate = new Date(isoDateStr);
    const localDate = new Date(isoDate.getTime() - (isoDate.getTimezoneOffset() * 60000));

	const options = { month: 'short', day: 'numeric', year: 'numeric'};
	const formattedDate = localDate.toLocaleString('en-US', options);
	return formattedDate
}

export const formatISODateShortNoYear = (isoDateStr) => {
	const isoDate = new Date(isoDateStr);
	const options = { month: 'short', day: 'numeric'};
	const formattedDate = isoDate.toLocaleString('en-US', options);
	return formattedDate
}

export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // This will format the date as per your locale
};

// export const getDateFromISODateTime = (isoDateTimeStr) => {
//     const date = new Date(isoDateTimeStr);
//     const isoDate = date.toISOString().split('T')[0];
//     return isoDate;
// };

export const getDateFromISODateTime = (isoDateTimeStr) => {
    const date = new Date(isoDateTimeStr);
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toISOString().split('T')[0];
};

// export const formatToTimeString = (dateTimeStr) => {
//     // Create a new Date object using the datetime string
//     const date = new Date(dateTimeStr);
//     // Get the UTC time equivalent of the date
//     const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
//     const estDate = new Date(utcDate.getTime() - 5 * 3600000);

//     // Convert to time string in the desired format
//     const timeString = estDate.toLocaleTimeString('en-US', {
//         hour: 'numeric',
//         minute: '2-digit',
//         second: '2-digit',
//         hour12: true
//     });

//     return timeString;
// }

export const formatToTimeString = (dateTimeStr) => {
    // Create a new Date object using the datetime string
    const date = new Date(dateTimeStr);

    // Convert to time string in the desired format (HH:MM AM/PM)
    // Assuming dateTimeStr is already in EST, no need to convert to UTC or adjust time zones
    const timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });

    return timeString;
};


export const getUniqueDates = (timestamps) => {
    const uniqueDates = new Set();

    for (let i = 0; i < timestamps.length; i++) {
        let timestamp = timestamps[i];
        const datePart = timestamp.split('T')[0]; // Extract the date part
        uniqueDates.add(datePart);
    };
    return Array.from(uniqueDates);
};


export const convertToCSV = (data) => {
	// Get header
	const header = Object.keys(data[0]).join(',');
	const rows = data.map(row => 
	  Object.values(row).map(value => 
		// Wrap string values with double quotes
		typeof value === 'string' ? `"${value}"` : value
	  ).join(',')
	);
	// Form CSV string
	const csv = [header, ...rows].join('\n');
	return csv;
  }
  
export const downloadCSV = (csv, filename) => {
	const blob = new Blob([csv], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
  }
  

export const  getCSVDownloadLink = (csvData) => {
    if (!csvData || csvData.length === 0) {
        return ""; // Return an empty string if there is no data
    }
    const csv = convertToCSV(csvData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    return url;
}

export const capitalizeFirstLetter = (str) => {
    // Check if the input string is not empty
    if (str.length === 0) {
        return str; // Return an empty string as-is
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toCamelCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const formatAsFloat = (num, round) => {
    if (Number.isInteger(num)) {
        return num.toFixed(round);
    }
    return num;
}

export const removeFractionalSecondsAndTimezoneFromISODT = (isoDateTimeString) => {
    // Use a regular expression to remove fractional seconds and timezone
    return isoDateTimeString.replace(/\.\d+/, '').replace(/([-+]\d{2}:\d{2})$/, '');
};


export const addSeconds = (dateTimeString, secondsToAdd) =>{
    // Manually parse the date-time string
    const [datePart, timePart] = dateTimeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);

    // Create a new Date object (in UTC to avoid timezone conversion)
    const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    // Add one second
    date.setUTCSeconds(date.getUTCSeconds() + secondsToAdd);

    // Format the updated date back into a string without converting to local time
    const updatedDateString = date.toISOString().split('.')[0];

    // Extract the updated time part and append it to the original date part
    const updatedTimePart = updatedDateString.split('T')[1];
    return `${datePart}T${updatedTimePart}`;
}
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smooth scrolling
    });
};

export const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth' // for smooth scrolling
    });
};