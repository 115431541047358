import React, { useState, useEffect, useRef} from "react"


import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const TickerTradesDeltaBarChart = ({tickerTradesDelta, showTitle = true }) => {
  
    // Extracting the relevant data
    // console.log("tickerTradesDeltatickerTradesDelta=>", tickerTradesDelta)
    // const chartComponentRef = useRef(null);
    const [chartDisplayData, setChartDisplayData] = useState([])
    const [chartWidth, setChartWidth] = useState(null);

    const titleText = `${tickerTradesDelta.name} (${tickerTradesDelta.ticker})`;
    const titleURL = `/intraday/${tickerTradesDelta.ticker}`;
    const titleRef = useRef(null);
    const chartComponentRef = useRef(null);
    // Utility function to transform data
    const transformDataForDisplay = (data) => {
        if (data !== undefined) {
            return data.map(item => {
                const transformedValue = Math.sqrt(Math.abs(item));
                const roundedValue = Math.ceil(transformedValue); // Round to next higher integer
                return item < 0 ? -roundedValue : roundedValue;
            });
        }
    };

    useEffect(() => {
        if (tickerTradesDelta) {
            const { pct_change_avg_size, pct_change_total_size, pct_change_vw_price } = tickerTradesDelta;
            const transformedData = transformDataForDisplay([pct_change_avg_size, pct_change_total_size]);
            transformedData.push(pct_change_vw_price);
            setChartDisplayData(transformedData);
        }
    }, [tickerTradesDelta]);

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (chartComponentRef.current) {
    //             setChartWidth(chartComponentRef.current.container.current.offsetWidth);
    //         }
    //     };

    //     // Set initial size
    //     handleResize();

    //     // Add event listener
    //     window.addEventListener('resize', handleResize);

    //     // Remove event listener on cleanup
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    useEffect(() => {
        if (showTitle && chartComponentRef.current) {
            const chart = chartComponentRef.current.chart;
    
            // Remove existing custom title (if any)
            const customTitle = chart.renderer.box.querySelector('.custom-chart-title');
            if (customTitle) {
                customTitle.remove();
            }
    
            let titleX = chart.plotLeft + (chart.plotWidth * 0.5);
            let titleY = chart.plotTop;
            // Adjust title position to avoid overlap
            titleY -= 10;
            chart.renderer.text(
                `<a href="${titleURL}" class="custom-chart-title" style="color: #013A6F; font-weight: bold; font-size: 14px; font-family: Arial, sans-serif; margin-bottom:10px">${titleText}</a>`,
                titleX,
                titleY
            ).attr({
                align: 'center',
                zIndex: 5
            }).css({
                cursor: 'pointer'
            }).add();
        }
    }, [tickerTradesDelta, titleText, titleURL]);
    
    const options = {
        chart: {
            type: 'bar',
            backgroundColor: '#ddd',
            height: 150,
            spacingTop: 20 // Adjust this value as needed           
        },     
        title: {
            text: '', 
            useHTML: true,
            style: {
                fontSize: '14px', // Reduce the font size
                color: '#013A6F', // Custom color
                fontFamily: 'Arial, sans-serif' // Custom font family
                // You can add more CSS properties as needed
            }            
        },
        subtitle: {
            text: `${tickerTradesDelta.last_updated_dt} (Seq: ${tickerTradesDelta.sequence})`, // Replace with your subtitle text
            align: 'center',
            verticalAlign: 'bottom',
            style: {
                color: '#013A6F',
                fontSize: '14px',
                fontFamily:  "Segoe UI"
            }
        },        
        xAxis: {
            categories: ['Avg Trade Size', 'Total Trade Size', 'VW Price'],
            title: {
                text: null
            },
            labels: {
                enabled: true // Disable x-axis labels
            },
            gridLineWidth: 0 // Remove grid lines for x-axis
        },
        yAxis: {
            min: -30, // Adjust as needed
            max: 50,  // Adjust as needed
            title: {
                align: 'high'
            },
            labels: {
                enabled: false // Disable x-axis labels
            },
            gridLineWidth: 0 // Remove grid lines for y-axis
        },
        tooltip: {
            valueSuffix: ' %'
        },
        plotOptions: {
            bar: {
                // pointWidth: 20,
                groupPadding: 0,
                pointPadding: 0,
                dataLabels: {
                    enabled: true,
                    // inside: true, // Display data labels inside the bars
                    align: 'left',
                    formatter: function () {
                        return this.y; // Format data label (show the y-value)
                    }
                },               
            },
        },
        legend: {
            enabled: false // Disable the legend
        },
        series: [{
            data: chartDisplayData,
            colorByPoint: true, // Enable individual colors
            colors: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)'],
            borderWidth: 0        
        }],
        credits: {
            enabled: true, // or false if your license allows
            text: 'Highcharts.com', // Default text
            href: 'https://www.highcharts.com' // Default URL
        }        
    };
    


    return (
        <React.Fragment>
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef}/>
        </React.Fragment>
    );
};

export default TickerTradesDeltaBarChart;
