import { useState, useEffect } from "react"
import { getDataFromAPI, editAPIData } from 'utils/WT3ApiUtil'
import { TRENDING_TRADES_END_POINT } from 'utils/WT3ApiConstants'
import { getDateFromISODateTime } from 'utils/WT3DisplayUtil'
import { useNavigate} from 'react-router-dom';
import TradeStatusRadioList from 'admin/components/TradeStatusRadioList'

import {CloseButton, TradeAttribute, TradeAttributeLabel} from 'admin/styles/StyleComponents'

import Modal from 'react-modal';

Modal.setAppElement('#root'); // Replace '#root' with your app element ID

const TrendingTradeEditModal = ({trade, isOpen, onRequestClose}) => {

    const navigate = useNavigate();
    
    const [trendingTrade, setTrendingTrade] = useState(trade);
    const [message, setMessage] = useState(null);
    const [tradeOpen, setTradeOpen] = useState(trendingTrade.open)
    const [tradeLastPrice, setTradeLastPrice] = useState(trendingTrade.last_price)
    const [tradeStatus, setTradeStatus] = useState(trendingTrade.status)

    const [tradeOpenDate, setTradeOpenDate] = useState(getDateFromISODateTime(trendingTrade.open_dt))
    const [tradeNumShares, setTradeNumShares] = useState(trendingTrade.num_shares)

    
    const [errorMessage, setErrorMessage] = useState()

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',  // Adjust the width as needed
          height: '60%', // Adjust the height as needed
          overflow: 'auto' // Add scroll if content is too long
        }
      };
    const fetchTrendingTrade = async (orderId) => {
        console.log(`Fetching trade: ${orderId}`);
        let fetchTrendingTradeEndPoint   = `${TRENDING_TRADES_END_POINT}/${orderId}`
        const data = await getDataFromAPI(fetchTrendingTradeEndPoint, navigate);
        if (data !== null) {
            console.log(`Trade reloaded. OrderID: ${orderId}`);
            console.log(data);
            setTrendingTrade(data);
        }
    };
    // Handle trade status option change
    const handleTradeStatusOptionChange = (tradeStatus) => {
        console.log(`Trade status updated to: ${tradeStatus}`);
        setTradeStatus(tradeStatus);
    }; 
    console.log(`Rendering Trending trade. OrderId: ${trendingTrade.order_id}`);

    const displayTrendingTrade = () => {
        if (trendingTrade == null) {
            return '';
        }
        return (
            <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Trade Details" style={customStyles}>
                <div className="user">
                    {errorMessage && <div className="fail">{errorMessage}</div>}
                    {<div className="success">{message}</div>}

                    <CloseButton onClick={onRequestClose}>&times;</CloseButton>
                    <span className="list-title">Edit Trade</span>

                    <TradeAttribute>
                        <TradeAttributeLabel>Ticker: </TradeAttributeLabel>
                        <TradeAttributeLabel>{trendingTrade.ticker}</TradeAttributeLabel>
                    </TradeAttribute>
                    <TradeAttribute>
                        <TradeAttributeLabel>Open Price</TradeAttributeLabel>    
                        <input className="form-input" value={tradeOpen} onChange={e => setTradeOpen(e.target.value)} 
                            type="text" placeholder="Open Price" autoComplete="off" />
                    </TradeAttribute>
                    <TradeAttribute>
                        <TradeAttributeLabel>Last Price</TradeAttributeLabel>    
                        <input className="form-input" value={tradeLastPrice} onChange={e => setTradeLastPrice(e.target.value)} 
                            type="text" placeholder="Last Price" autoComplete="off" />
                    </TradeAttribute>
                    <TradeAttribute>
                        <TradeAttributeLabel>Opened At</TradeAttributeLabel>            
                        <input className="form-input" value={tradeOpenDate}  onChange={e => setTradeOpenDate(e.target.value)}
                            type="text" placeholder="Open Date" autoComplete="off" />
                    </TradeAttribute>
                    <TradeAttribute>
                        <TradeAttributeLabel>Shares</TradeAttributeLabel>            
                        <input className="form-input" value={tradeNumShares}  onChange={e => setTradeNumShares(e.target.value)}
                            type="text" placeholder="Shared traded" autoComplete="off" />
                    </TradeAttribute>                    
                    <TradeStatusRadioList selectedOption={tradeStatus} handleOptionChange={handleTradeStatusOptionChange}></TradeStatusRadioList>  
                    <div className="button-container">
                        <button className="button-primary" onClick={refreshData}>Refresh</button>
                        <button className="button-primary" onClick={onEditTrendingTrade}>Save</button>
                        {(trendingTrade.status === "placed") && (
                            <button className="button-primary" onClick={refreshData}>Delete</button>
                        )}                        
                    </div>
                </div>
            </Modal>
        );
    };
    useEffect(() => {
        setTradeOpen(trendingTrade.open);
        setTradeLastPrice(trendingTrade.last_price);
        setTradeStatus(trendingTrade.status);
        setTradeOpenDate(getDateFromISODateTime(trendingTrade.open_dt));
        setTradeNumShares(trendingTrade.num_shares);        
        console.log("Trending trade updated : "  + trendingTrade);
    }, [trendingTrade]);
    
    const refreshData = () => {
        fetchTrendingTrade(trendingTrade.order_id);
        setMessage(`Trending trade reloaded`);
        // Set a timeout to hide the message after 3 seconds
        setTimeout(() => {
            setMessage(null);
        }, 1500);        
    };

    const onEditTrendingTrade = async () => {
        const trendingTradeData = {
            order_id: trendingTrade.order_id,
            open: tradeOpen,
            open_dt: tradeOpenDate,
            last_price: tradeLastPrice,
            status: tradeStatus,
            num_shares: tradeNumShares
        };
        
        console.log(`Updating trending trade ${JSON.stringify(trendingTradeData, null, 2)}`);

        try {
            const response = await editAPIData(TRENDING_TRADES_END_POINT, trendingTradeData, navigate);
            setMessage(`Trending trade updated for ${trendingTrade.ticker}`);
            // Set a timeout to hide the message after 3 seconds
            setTimeout(() => {
                setMessage(null);
                onRequestClose();
            }, 1500);
            
        } catch (error) {
            console.error('Error updating trade:', error);
            setErrorMessage(error.response.data.error)
        } finally {
        }
    }

    return (
        <div className="list-container">
            {displayTrendingTrade()}
        </div>
        )
}
export default TrendingTradeEditModal;