import { useState, useEffect, useMemo } from "react"
import { useNavigate } from 'react-router-dom';
import { getDataFromAPI } from 'utils/WT3ApiUtil'
import { formatISODate } from 'utils/WT3DisplayUtil'
import Header from "common/components/Header";

const UserListPage = () => {

    console.log('Rendering UserListPage');
    const [userList, setUserList] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const displayUserListHeader = () => {
        return(
            <div className="list-header">
                <div className="list-item-header" onClick={() => requestSort('name')}><span>Name</span></div>
                <div className="list-item-header" onClick={() => requestSort('email')}><span>Email</span></div>
                <div className="list-item-header" onClick={() => requestSort('date_created')}><span>Created On</span></div>
                <div className="list-item-header" onClick={() => requestSort('last_logged_in')}><span>Last Login</span></div>
            </div>
          )
    }
    const displayUserList = () => {
        return (
            sortedUserList.map(user => (
                <div className="list-row" key={user.user_id} >
                    <div className="list-item">
                        <a href={`/users/${user.user_id}`} className="link-item"><span>{user.name}</span></a>
                    </div>
                    <div className="list-item">{user.email}</div>
                    <div className="list-item">{formatISODate(user.date_created)}</div>
                    <div className="list-item">{formatISODate(user.last_logged_in)}</div>
                </div>
            ))
        );
    }
    
    const sortedUserList = useMemo(() => {
        return [...userList].sort((a, b) => {
            if (sortConfig.key === null) return 0;

            if (sortConfig.direction === 'asc') {
                return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
            } else {
                return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
            }
        });
    }, [userList, sortConfig]);

    const requestSort = (key) => {
        setSortConfig((prev) => {
          if (prev.key === key && prev.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return { key, direction: 'asc' };
        });
      };

    function getUserListEndPoint() {
        return `/wt3-api/users`;
    }

    
    const navigate = useNavigate();

    
    useEffect(() => {
        const fetchUserList = async () => {
            let userListEndpoint = getUserListEndPoint();
            setUserList(await getDataFromAPI(userListEndpoint, navigate));
        };
    
        fetchUserList();
    }, []);
        
    return (
    <div className="content">
        <Header />
        <div className="list-container">
            <span className="list-title">Users</span>
            {displayUserListHeader()}
            {displayUserList(userList)}
        </div>
    </div>
    )
}
export default UserListPage;