import React, { useState, useEffect, useMemo } from "react"
// import { Chart as ChartJS } from 'chart.js/auto';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import PriceTrendsCheckboxComponent from 'admin/components/PriceTrendsCheckboxComponent'

const DailyMovementLineChart = ({dailyMovements, datasetItems, initialOption }) => {
    
    // Step 1: Initialize state for checkboxes
    const initialOptionsState = datasetItems.reduce(
        (acc, item) => ({ ...acc, [item.key]: false }),
        {}
      ); 

          // Set only 'trade_return_1' to true
    initialOptionsState[initialOption] = true;

    const [selectedOptions, setSelectedOptions] = useState(initialOptionsState);

    // Step 3: Handle change in checkboxes
    const handleOptionChange = (key) => {
        setSelectedOptions({ ...selectedOptions, [key]: !selectedOptions[key] });
      };

      
    const chartData = useMemo(() => {
        // memoize chart data calculation
        const dates = dailyMovements.map(trend => trend.date);
        const datasets = datasetItems.filter(item => selectedOptions[item.key]).map(item => ({
            label: item.label,
            data: dailyMovements.map(trend => trend[item.key] === "NAN" ? null : trend[item.key]),
            fill: false,
            borderColor: item.borderColor,
            borderWidth: item.borderWidth,
            pointRadius: item.pointRadius,            
            tension: .5
        }));
  
        return {
            labels: dates,
            datasets: datasets
        };
    }, [dailyMovements, selectedOptions, datasetItems]); // dependencies

// Define the plugin
const crosshairPlugin = {
  id: 'crosshair',
  afterDraw(chart, args, options) {
    if (chart.tooltip?._active?.length) {
      const ctx = chart.ctx;
      const x = chart.tooltip._active[0].element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = options.lineWidth || 1;
      ctx.strokeStyle = 'rgba(10,20,30,1)';
      ctx.stroke();
      ctx.restore();
    }
  }
};
    const DrawMovementLineChart = () => {
        console.log("Inside DrawMovementLineChart....");
        useEffect(() => {
          // Register the plugin when the component mounts
          Chart.register(crosshairPlugin);
        }, []);      
        // Sort the data by date
        dailyMovements.sort((a, b) => new Date(a.date) - new Date(b.date));
      
        const dates = dailyMovements.map(trend => trend.date);
      
        const datasets = datasetItems.filter(item => selectedOptions[item.key]).map(item => ({
          label: item.label,
          data: dailyMovements.map(trend => trend[item.key] === "NAN" ? null : trend[item.key]),
          fill: false,
          borderColor: item.borderColor,
          tension: .5
        }));
      
      const data = {
          labels: dates,
          datasets: datasets
        };
  
        const options = {
          scales: {
            x: { // For the x-axis
              grid: {
                display: true // Hides the grid lines for the x-axis
              }
            },
            y: { // For the y-axis
              grid: {
                display: true // Hides the grid lines for the y-axis
              }
            }
          },
          plugins: {
            legend: {
              position: 'right' // Keeps the legend to the right
            },
            tooltip: {
              // backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
              displayColors: false, // Do not display the legend color box in the tooltip
              callbacks: {
                label: function(context) {
                  // Return the label for x-axis and value as separate lines
                  const label = context.chart.data.labels[context.dataIndex];
                  const value = context.parsed.y;
                  return [label, value.toString()];
                },
                title: function() {
                  // Return an empty string or null to not display the title
                  return '';
                }
              }
            },
            crosshair: { // Custom options for your crosshair plugin
              lineColor: 'rgba(255, 99, 132, 0.5)', // Example line color
              lineWidth: 0.8 // Example line width
            }                   
          }       
        };
        return (<div style={{ width: '1200px' }}>
                <Line data={chartData} options={options} />;
              </div>)
      };

      const directionDatasetItems = datasetItems.filter(item => item.key.includes('dir'));
      const movementDatasetItems = datasetItems.filter(item => item.key.includes('mov'));
      const divergenceDatasetItems = datasetItems.filter(item => item.key.includes('div'));
        
    return (
        <React.Fragment>
            {DrawMovementLineChart()}
            <h4>Direction</h4>
            <PriceTrendsCheckboxComponent datasetItems={directionDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
            <h4>Movement</h4>
            <PriceTrendsCheckboxComponent datasetItems={movementDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
            <h4>Divergence</h4>
            <PriceTrendsCheckboxComponent datasetItems={divergenceDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
        </React.Fragment>
    );
};

export default DailyMovementLineChart;
