import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import ProgressBar from "common/components/ProgressBarV2";
import ChartRecentIntradayAggregates from "home/components/ChartRecentIntradayAggregates"

import './HomePage.css';


const MajorMarketIndexFunds = () => {

    const [marketIndices, setMarketIndices] = useState([]);
    const [selectedMarketIndex, setSelectedMarketIndex] = useState(null); // Initial state is the first ticker
    const [progressBarControl, setProgressBarControl] = useState({ showProgress: () => {}, hideProgress: () => {} });

    const handleTickerClick = (ticker) => {
        setSelectedMarketIndex(ticker);
    };

    const navigate = useNavigate();

    const fetchMarketIndices = () => {
        const majorMarketIndices = [
            { ticker: "IWM", company_name: "Russell 2000 Index", exchange: "NYSEArca", order_id: "IWM" },
            { ticker: "IJR", company_name: "S&P SmallCap 600 Index", exchange: "NYSEArca", order_id: "IJR" },
            { ticker: "MDY", company_name: "S&P MidCap 400 Index", exchange: "NYSEArca", order_id: "MDY" },
            { ticker: "IWR", company_name: "Russell Midcap Index", exchange: "NYSEArca", order_id: "IWR" },
            { ticker: "SPY", company_name: "S&P 500 Index", exchange: "NYSEArca", order_id: "SPY" },
            { ticker: "QQQ", company_name: "NASDAQ-100 Index", exchange: "NASDAQ", order_id: "QQQ" },
            { ticker: "DIA", company_name: "Dow Jones Industrial Average", exchange: "NYSEArca", order_id: "DIA" },
            { ticker: "IWB", company_name: "Russell 1000 Index", exchange: "NYSEArca", order_id: "IWB" }
        ];
        progressBarControl.showProgress();
        setMarketIndices(majorMarketIndices);
        progressBarControl.hideProgress();
    };

    useEffect(() => {
        setProgressBarControl({
            showProgress: () => document.querySelector('#majorMarketIndexFundsProgressBar').classList.add('animate_progress_bar'),
            hideProgress: () => document.querySelector('#majorMarketIndexFundsProgressBar').classList.remove('animate_progress_bar')
          });        
    }, []);

    useEffect(() => {
        fetchMarketIndices();
    }, []);    
    useEffect(() => {
        if (marketIndices.length > 0) {
            handleTickerClick(marketIndices[0].ticker);
        }
    }, [marketIndices.length]); // Depend on the length of the array
    
    

    const displayMarketIndices = () => {
        console.log(`Upcoming trades found: ${marketIndices.length}`);   

        if (marketIndices == undefined || marketIndices.length < 1) {
            return <h2>No trades recommendations. Please check later.</h2>;
        }
        console.log("displaying MarketIndices....");
        if (marketIndices == undefined || marketIndices.length < 1) {
            return '';
        }
        console.log("selectedMarketIndex:" + selectedMarketIndex);
        return (
                marketIndices.map(marketIndex => (
                    <div className="list-item" key={marketIndex.ticker} onClick={() => handleTickerClick(marketIndex.ticker)}>
                        <div className={`ticker_option ${selectedMarketIndex === marketIndex.ticker ? 'selected' : ''}`} key={marketIndex.order_id}><div className="ticker_name">{marketIndex.company_name}
                            <div className="ticker_exchange">{marketIndex.exchange}</div></div><span className="ticker_symbol">{marketIndex.ticker}</span>
                        </div>
                    </div>               
                ))
          );
    }

    return (
                <React.Fragment>
                    <div className="upcoming-container">
                        <div className="upcoming-trades">
                            <ProgressBar id="majorMarketIndexFundsProgressBar" />
                            {displayMarketIndices()}
                        </div>
                        {selectedMarketIndex && 
                        <div className="trade-charts">
                            <ChartRecentIntradayAggregates ticker={selectedMarketIndex} />
                        </div>
                        }
                    </div>
                </React.Fragment>
            )
}

export default MajorMarketIndexFunds;