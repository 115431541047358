import React, { useState, useEffect} from "react"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getDataFromAPI, getTickerDetailsEndpoint, getQueryParam } from 'utils/WT3ApiUtil'
import { TICKER_INTRADAY_DIRECTION, TICKER_INTRADAY_MOVEMENT } from "utils/WT3ApiConstants"
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import DailyMovementLineChart from "tickertrades/components/DailyMovementLineChart"
import {datasetItems, fetchAndCombineData} from "analysis/DailyPriceMovementPage.js"

import Header from "common/components/Header";


const TickerIntradayMovementPage = ({}) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { ticker } = useParams();

    const [tickerDetails, setTickerDetails] = useState([])
    const [momentDirectionData, setMomentDirectionData] = useState([]);


    const getTitle = () => {
        return  <h4>{tickerDetails.name} ({ticker})</h4>
    }

    useEffect(
        () => {
            const fetchTickerDetails = async () => {
                let tickerDetailsEndPoint = getTickerDetailsEndpoint(ticker);
                setTickerDetails(await getDataFromAPI(tickerDetailsEndPoint, navigate));
            };
            fetchTickerDetails();
            console.log("Ticker details fetched...");
        }, []
    );


    useEffect(() => {
        const fetchData = async () => {
            showProgress();
            setIsLoading(true)
            const combinedData = await fetchAndCombineData(ticker, TICKER_INTRADAY_DIRECTION, TICKER_INTRADAY_MOVEMENT, location, navigate);
            console.log(combinedData);
            setMomentDirectionData(combinedData);
            hideProgress();
            setIsLoading(false)
        };

        fetchData();
    }, [ticker, location, navigate]);


    const getURLForRobinhood = () => {
        let robinhoodUrl = `https://robinhood.com/stocks/${ticker}`;

        return  <a href={robinhoodUrl} target="_blank" className="custom-chart-title" style={{ color: "#013A6F", fontWeight: "bold", fontSize: 14 + "px", fontFamily: "Arial, sans-serif", marginBottom: 10 + "px" }}>
                ROBINHOOD</a>
    }

    const DisplayMessageIfNoTickerTrades = () => {
        if (isLoading) {
            return (
                <div>
                    <React.Fragment>
                        <h2>Loading ticker trades for {ticker}</h2>
                    </React.Fragment>
                </div>
            );            
        }  
        const isTickerIntradayMovementEmpty = (momentDirectionData.length === 0);

        if (( momentDirectionData == undefined) || isTickerIntradayMovementEmpty === true) {
            return (
                <div>
                    <React.Fragment>
                        <h3 key={ticker}>
                            No daily movements fetched for {ticker}
                        </h3>
                        <hr></hr>
                    </React.Fragment>
                </div>
            );
        }
    };
      
    return (
        <React.Fragment>
            <Header />
            <div className="content">
                <div className="bar-chart-list">
                    {getTitle()}
                    {getURLForRobinhood()}
                    <ProgressBar />
                    {DisplayMessageIfNoTickerTrades()}
                    <div>
                        <div>
                            {momentDirectionData !== undefined && Object.keys(momentDirectionData).length > 0 &&
                                <DailyMovementLineChart dailyMovements={momentDirectionData} datasetItems={datasetItems} initialOption="mov_vw_price"></DailyMovementLineChart>
                            }
                        </div> 
                    </div>            
                </div>
            </div>
        </React.Fragment>
    );
}

export default TickerIntradayMovementPage