import axios from 'axios';
import { useState} from "react"
import { useNavigate } from 'react-router-dom';

import { isValidEmail, isValidPassword, isValidName } from 'utils/WT3DisplayUtil'; // adjust the path if needed

import {addAPIData} from 'utils/WT3ApiUtil';

import Logo from 'common/components/Logo';
 
const addUserEndPoint = "/wt3-api/user"

const SignUpPage = () => {
    const navigate = useNavigate();

    const [emailValue, setEmailValue] = useState('')
    const [nameValue, setNameValue] = useState('')
    const [passwordValue, setPasswordValue] = useState('')
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [emailValidated, setEmailValidated] = useState(false)
    const [passwordValidated, setPasswordValidated] = useState(false)
    const [nameValidated, setNameValidated] = useState(false)
    const [passwordConfirmed, setPasswordConfirmed] = useState(false)

    const onSignUpClicked = async () => {
        console.log("Signing up user....")
        const userData = {
            name: nameValue,
            email: emailValue,
            password: passwordValue,
            confirmPassword: confirmPasswordValue
        };

        console.log(`Creating user: ${userData}`);

          try {
            const response = await addAPIData(addUserEndPoint, userData, navigate);
            console.log('User signed up successfully:', response.data.user_id);
          } catch (error) {
            console.error('Error signing up:', error);
          }    
    }

    const handleBlurOnName = () => {
        if (!isValidName(nameValue)) {
            setErrorMessage('Name can only contain alphabets');
            setNameValidated(false);
        } else {
            setNameValidated(true);
        }
    };

    const handleBlurOnEmail = () => {
        if (!isValidEmail(emailValue)) {
            setErrorMessage('Invalid email format');
            setEmailValidated(false);
        } else {
            setEmailValidated(true);
        }
    };

    const handleBlurOnPassword = () => {
        if (!isValidPassword(passwordValue)) {
            setErrorMessage('Password must be at least 8 characters long');
            setPasswordValidated(false);
        } else {
            setPasswordValidated(true);
        }
    };

    const handleBlurOnPasswordConfirmed = () => {
        if (passwordValue !== confirmPasswordValue) {
            setErrorMessage('Passwords do not match');
            setPasswordConfirmed(false);
        } else {
            setPasswordConfirmed(true);
        }
    };

    return (
    <div className="content-container">
        <Logo />
        <h1>
            Sign Up
        </h1>
        {errorMessage && <div className="fail">{errorMessage}</div>}

        <input className="form-input" value={nameValue} 
                onChange={e => setNameValue(e.target.value)} onBlur={handleBlurOnName}
                type="text" placeholder="Full Name" autoComplete="off" />

        <input className="form-input" value={emailValue} 
                onChange={e => setEmailValue(e.target.value)}  onBlur={handleBlurOnEmail}
                type="text" placeholder="Email address" autoComplete="off" />

        <input className="form-input" value={passwordValue} 
                onChange = {e => setPasswordValue(e.target.value)} onBlur={handleBlurOnPassword} 
                type="password" placeholder="confirm password..." autoComplete="off" />
        <input className="form-input" value={confirmPasswordValue} 
                onChange = {e => setConfirmPasswordValue(e.target.value)} onBlur={handleBlurOnPasswordConfirmed} 
                type="password" placeholder="password" autoComplete="off" />

        <button className="button-primary" disabled = {!emailValidated|| !passwordValidated || !nameValidated || !passwordConfirmed} 
                onClick={onSignUpClicked}>Sign Up</button>
        <button className="button-primary" onClick={() => navigate("/forgot-password")}>Forgot Password?</button>
        <button className="button-primary" onClick={() => navigate("/login")}>Already have an account? Log In</button>

    </div>)
}

export default SignUpPage;