import React from "react";
import { useNavigate} from 'react-router-dom';

const PriceTrendsOverview = ({priceTrendsList, selectedTimeRange}) => {

    const navigate = useNavigate();

    const handleRowClick = (event) => {
        // Find the ticker within the clicked row. Adjust the selector as necessary.
        const tickerElement = event.currentTarget.querySelector('.ticker');
        const ticker = tickerElement.textContent;
        // Construct the URL
        const url = `/trends/${ticker}`;

        //navigate(url);
        window.open(url, '_blank');
    };

    const displayPriceTrends = () => {        
        return (
            priceTrendsList.map(tickerPriceTrends => (
                    <div className="list-row list-row-clickable" key={tickerPriceTrends.ticker} onClick={handleRowClick}>
                        <div className="list-item ticker">{tickerPriceTrends.ticker}</div>
                        <div className="list-item">{tickerPriceTrends.trade_return_1}</div>
                        {!(selectedTimeRange === '1') && (
                            <React.Fragment>
                                <div className="list-item">{tickerPriceTrends.trade_return_3}</div>
                                <div className="list-item">{tickerPriceTrends.trade_return_5}</div>
                            </React.Fragment>
                        )}
                        <div className="list-item">{tickerPriceTrends.price_change_3}</div>
                        <div className="list-item">{tickerPriceTrends.price_change_5}</div>
                        <div className="list-item">{tickerPriceTrends.price_change_10}</div>
                        {(selectedTimeRange === '1') && (
                            <React.Fragment>
                                <div className="list-item">{tickerPriceTrends.rsi5}</div>
                                <div className="list-item">{tickerPriceTrends.mad}</div>
                            </React.Fragment>
                        )}                        
                      </div>
              ))//map
          ); //return
    }
    
    return (
                <div className="scrollable-div">{displayPriceTrends()}</div>
            )
}

export default PriceTrendsOverview;