import './Logo.css';

const ClickableLogo = () => {
    return (
        <div className="clickable-logo">
            <a href="/">
                <img src="/images/logo2.png" alt="Home" />
            </a>
        </div>
    )
};

export default ClickableLogo;
