import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from "react-modal"; // if using react-modal


import { getQueryParam} from "utils/WT3ApiUtil"
import { TICKER_TRADES_COUNT_ENDPOINT, TICKER_TRADES_IMPORT_ENDPOINT, TICKER_TRADES_USER_ACTION_ENDPOINT } from "utils/WT3ApiConstants"
import { getDataFromAPI, deleteAPIData, addAPIData } from "utils/WT3ApiUtil"
import {formatToTimeString, getDateFromISODateTime, removeFractionalSecondsAndTimezoneFromISODT, addSeconds} from "utils/WT3DisplayUtil"

import {toCamelCase, formatISODateShort} from "utils/WT3DisplayUtil"
import TickerLogo from "tickerdetails/TickerLogo"

import IntradayTradeAnalysis from "tickertrades/components/IntradayTradeAnalysis"

import {CloseButton} from 'admin/styles/StyleComponents'
import { ProgressBar, hideProgress, showProgress } from "common/components/ProgressBar";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',  // Adjust the width as needed
    height: '60%', // Adjust the height as needed
    overflow: 'auto' // Add scroll if content is too long
  }
};

const ManageTickerTradesPage = ({tickerDetails}) => {

    console.log(`Rendering ManageTickerTradesPage for ${tickerDetails.ticker}`);

    const navigate = useNavigate();
    const location = useLocation();
    const [actionUpdateMessage, setActionUpdateMessage] = useState();

    let dateStr = getQueryParam(location, 'date');

    const [tradesCount, setTradesCount] = useState(0); // Assume loading is true by default
    const [tradingDate, setTradingDate] = useState(null); // Assume loading is true by default
    const [latestTrade, setLatestTrade] = useState(null); 
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Use a counter or timestamp as the state
    const [intradayAnalysisKey, setIntradayAnalysisKey] = useState(0);
    const [showIntradayAnalysis, setShowIntradayAnalysis] = useState(false);

    const openModal =() => {
      setIsModalOpen(true);
    }

    const closeModal = () => {
      setIsModalOpen(false);
  };

    const refereshTickerTrades = () => {
        // Update the key to a new value to force remount
        setActionUpdateMessage("");
        fetchTickerTradesCount();
        setIntradayAnalysisKey(prevKey => prevKey + 1);
        setShowIntradayAnalysis(true); // Enable rendering of the component
    };
    
    const deleteTickerTrades = async(ticker, date) => {
      setActionUpdateMessage("");
      let tickerTradesDeleteEndpoint = `${TICKER_TRADES_USER_ACTION_ENDPOINT}/${ticker}/${date}`;
      console.log(`Deleting ticker trades ${tickerTradesDeleteEndpoint}`)
      const response = await deleteAPIData(tickerTradesDeleteEndpoint, navigate);
      if (response.status == 400) {
          console.log(response['data']['error']);
          setActionUpdateMessage(response['data']['error'])
          return
      } 
      console.log(response);
      setActionUpdateMessage(`Trades deleted: ${response['data']['cnt_deleted']}`)
      refereshTickerTrades();
    };

    const importTickerTrades = async() => {
      setActionUpdateMessage("");
      let ticker = tickerDetails.ticker;
      let date = getDateFromISODateTime(tradingDate);
      let import_start_dt = `${date}T07:00:00`;
      let import_end_dt = `${date}T16:00:00`;

      if (tradesCount > 0 && latestTrade !== null) {
        import_start_dt = removeFractionalSecondsAndTimezoneFromISODT(latestTrade['updated_at']);
        import_start_dt = addSeconds(import_start_dt, 1)
      }
      
      if (import_start_dt >= import_end_dt) {
        setActionUpdateMessage(`Start time ${import_start_dt} not less than End time ${import_end_dt}. It seems trades are upto date!`);
        return
      }

      let tickerTradesImportEndpoint = TICKER_TRADES_IMPORT_ENDPOINT;

      const import_params = {
          ticker: ticker,
          start_dt: import_start_dt,
          end_dt: import_end_dt
      };


      console.log(`importing ticker trades from ${tickerTradesImportEndpoint} for ${import_params}`);
      showProgress();
      setActionUpdateMessage(`Importing Trades in range : ${import_start_dt} to ${import_end_dt}`);
      const response = await addAPIData(tickerTradesImportEndpoint, import_params, navigate);
      if (response.status == 400) {
          console.log(response['data']['error']);
          setActionUpdateMessage(response['data']['error']);
          hideProgress();
          return;
      } 
      setActionUpdateMessage(`Trades Imported: ${response['data']['trades_imported']}`);
      hideProgress();
      // refereshTickerTrades();
    }    
    
    const fetchTickerTradesCount = async () => {
      let ticker = tickerDetails.ticker;
      let tickerTradesCountEndpoint = `${TICKER_TRADES_COUNT_ENDPOINT}/${ticker}`;
      
      if (dateStr) {
          tickerTradesCountEndpoint += `?date=${dateStr}`;
      }

      const response = await getDataFromAPI(tickerTradesCountEndpoint, navigate);
      setTradesCount(response['count_trades'])
      setTradingDate(response['end_dt']);
      if (response['count_trades'] && response['count_trades'] > 0) {
        setLatestTrade(response['latest_trade'])
      } else {
        setLatestTrade(null);
      }
  };

    useEffect(() => {

      console.log(`ManageTickerTradesPage: Ticker: ${tickerDetails.ticker}, Date: ${dateStr}`)
      
      if (tickerDetails && tickerDetails.ticker) {
        fetchTickerTradesCount();
      }
    }, [tickerDetails]); // Add ticker to dependencies



  const getTickerTradesUpdate = () => {
      const divStyle = {
        width: '30%'
    };    
    return <div>
              <div class="ticker_trades_latest" style={divStyle}>
                <div className="ticker_details_row">
                  <div className="ticker_details_row_lbl">Date:</div>
                  <div className="ticker_details_row_cnt">{formatISODateShort(tradingDate)}</div>
                </div>
                <div className="ticker_details_row">
                  <div className="ticker_details_row_lbl">Count:</div>
                  <div className="ticker_details_row_cnt">{tradesCount}</div>
                </div>
                {latestTrade && (
                <div className="ticker_details_row">
                  <div className="ticker_details_row_lbl">Last Updated:</div>
                  <div className="ticker_details_row_cnt">{formatToTimeString(latestTrade['updated_at'])}</div>
                </div>
              )}
              </div>
            <div className="list-row" style={divStyle}>
            <div className="list-item-stocks">
                    <a onClick={() => importTickerTrades()} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Import</a>
              </div>               
              <div className="list-item-stocks">
                    <a onClick={() => refereshTickerTrades()} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Refresh</a>
              </div>
              <div className="list-item-stocks">
                    <a onClick={() => openModal()} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Expand</a>
              </div>              
              <div className="list-item-stocks">
                {latestTrade && (
                    <a onClick={() => deleteTickerTrades(tickerDetails.ticker, getDateFromISODateTime(tradingDate))} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Delete</a>
                )}
              </div>
            </div>

            <div className="list-item-stocks">
                {<div className="success" style={{textAlign: "left"}}>{actionUpdateMessage}</div>}
            </div>              

        </div>
    }
    const getTitle = () => {
      return (tickerDetails.ticker) && <div>
        <TickerLogo ticker={tickerDetails.ticker} name={toCamelCase(tickerDetails.name)}></TickerLogo>
      </div>      
    }

    return (
      <div>
        <ProgressBar/>
        {getTickerTradesUpdate()}
        {showIntradayAnalysis && (
                isModalOpen ? (
                    <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
                        <CloseButton className="close-button" onClick={closeModal}>&times;</CloseButton>
                        <IntradayTradeAnalysis key={intradayAnalysisKey} ticker={tickerDetails.ticker} date={dateStr} />
                        <button onClick={closeModal}>Close</button>
                    </Modal>
                ) : (
                    <IntradayTradeAnalysis key={intradayAnalysisKey} ticker={tickerDetails.ticker} date={dateStr} />
                )
            )}
      </div>
    )
}
export default ManageTickerTradesPage;