import React from 'react';
import 'common/components/loading.css'; // Import the CSS file for styling
import Logo from 'common/components/Logo'; // Path to your logo image

const LoadingAnimation = () => {
    return (
        <div className="loading-container">
            <Logo/>
            <div className="loader"></div>
        </div>
    );
};

export default LoadingAnimation;
