import React from "react";
import { useNavigate} from 'react-router-dom';

const PriceTrendTradeCorr = ({priceTrendTradeCorrList, columnStyle}) => {

    const navigate = useNavigate();

    const handleRowClick = (event) => {
        // Find the ticker within the clicked row. Adjust the selector as necessary.
        const tickerElement = event.currentTarget.querySelector('.ticker');
        const ticker = tickerElement.textContent;
        // Construct the URL
        const url = `/trends/${ticker}`;
        //navigate(url);
        window.open(url, '_blank');
    };

    const displayTrendTradeCorrs = () => {   
        return (
            priceTrendTradeCorrList.map(priceTrendTradeCorr => (
                    <div className="list-row-corr list-row-clickable" style={columnStyle} key={priceTrendTradeCorr.ticker} onClick={handleRowClick}>
                        <div className="list-item-corr ticker">{priceTrendTradeCorr.ticker}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d1_r1}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d1_r3}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d1_r5}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d3_r1}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d3_r3}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d3_r5}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d5_r1}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d5_r3}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_d5_r5}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_rsi_r1}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_rsi_r3}</div>
                        <div className="list-item-corr">{priceTrendTradeCorr.cor_rsi_r5}</div>
                      </div>
              ))//map
          ); //return
    }
    
    return (
                <div className="scrollable-div">{displayTrendTradeCorrs()}</div>
            )
}

export default PriceTrendTradeCorr;