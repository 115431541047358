import React, { useState, useEffect, useMemo } from "react"
import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import PriceTrendsCheckboxComponent from 'admin/components/PriceTrendsCheckboxComponent'

const TradesPositionsLineChart = ({tickerTradesDelta, showTitle = true }) => {
    
    const datasetItems = [
        { key: 'sod_pct_change_avg_size', label: 'Avg Size (SOD)', borderColor: 'rgb(255, 127, 80)' },
        { key: 'sod_pct_change_total_size', label: 'Total Size (SOD)', borderColor: 'rgb(55, 19, 180)' },
        { key: 'sod_pct_change_vw_price', label: 'Price (SOD)', borderColor: 'rgb(125, 79, 180)' },
        { key: 'eod_pct_change_avg_size', label: 'Avg Size (EOD)', borderColor: 'rgb(150, 123, 182)' },
        { key: 'eod_pct_change_total_size', label: 'Total Size (EOD)', borderColor: 'rgb(150, 123, 82)' },
        { key: 'eod_pct_change_vw_price', label: 'Price (EOD)', borderColor: 'rgb(15, 179, 100)' }
      ];

    // Step 1: Initialize state for checkboxes
    const initialOptionsState = datasetItems.reduce(
        (acc, item) => ({ ...acc, [item.key]: false }),
        {}
      ); 

          // Set only 'trade_return_1' to true
    initialOptionsState['eod_pct_change_avg_size'] = true;

    const eodDatasetItems = datasetItems.filter(item => item.key.includes('eod'));
    const sodDatasetItems = datasetItems.filter(item => item.key.includes('sod'));


    const [selectedOptions, setSelectedOptions] = useState(initialOptionsState);

    // Step 3: Handle change in checkboxes
    const handleOptionChange = (key) => {
        setSelectedOptions({ ...selectedOptions, [key]: !selectedOptions[key] });
      };

      
    // Utility function to transform data
    const transformDataForDisplay = (data) => {
        return data.map(item => {
            // const transformedValue = Math.sqrt(Math.abs(item));
            // const roundedValue = Math.ceil(transformedValue); // Round to next higher integer
            // return item < 0 ? -roundedValue : roundedValue;
            item = item * 10;
            let roundedValue = parseFloat(item.toFixed(2));
            return roundedValue;
            });
    }

    useEffect(() => {
        const { pct_change_avg_size, pct_change_total_size, pct_change_vw_price } = tickerTradesDelta;
        const transformedData = transformDataForDisplay([pct_change_avg_size, pct_change_total_size, pct_change_vw_price]);
    }, [tickerTradesDelta]);

    const chartData = useMemo(() => {
        // memoize chart data calculation
        const dates = tickerTradesDelta.map(trend => trend.date);
        const datasets = datasetItems.filter(item => selectedOptions[item.key]).map(item => ({
            label: item.label,
            data: tickerTradesDelta.map(trend => trend[item.key] === "NAN" ? null : trend[item.key]),
            fill: false,
            borderColor: item.borderColor,
            tension: .5
        }));
  
        return {
            labels: dates,
            datasets: datasets
        };
    }, [tickerTradesDelta, selectedOptions, datasetItems]); // dependencies

    const DrawTradePositionsLineChart = () => {
        console.log("Inside DrawTradePositionsLineChart....");
      
        // Sort the data by date
        tickerTradesDelta.sort((a, b) => new Date(a.date) - new Date(b.date));
      
        const dates = tickerTradesDelta.map(trend => trend.date);
      
        const datasets = datasetItems.filter(item => selectedOptions[item.key]).map(item => ({
          label: item.label,
          data: tickerTradesDelta.map(trend => trend[item.key] === "NAN" ? null : trend[item.key]),
          fill: false,
          borderColor: item.borderColor,
          tension: .5
        }));
      
      const data = {
          labels: dates,
          datasets: datasets
        };
  
        const options = {
          scales: {
            x: { // For the x-axis
              grid: {
                display: true // Hides the grid lines for the x-axis
              }
            },
            y: { // For the y-axis
              grid: {
                display: true // Hides the grid lines for the y-axis
              }
            }
          },
          plugins: {
            legend: {
              position: 'right' // Keeps the legend to the right
            },
            tooltip: {
              // backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
              displayColors: false, // Do not display the legend color box in the tooltip
              callbacks: {
                label: function(context) {
                  // Return the label for x-axis and value as separate lines
                  const label = context.chart.data.labels[context.dataIndex];
                  const value = context.parsed.y;
                  return [label, value.toString()];
                },
                title: function() {
                  // Return an empty string or null to not display the title
                  return '';
                }
              }
            }         
          }       
        };
        return (<div style={{ width: '1200px' }}>
                <Line data={chartData} options={options} />;
              </div>)
      };

    return (
        <React.Fragment>
            {DrawTradePositionsLineChart()}
            <PriceTrendsCheckboxComponent datasetItems={sodDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
            <PriceTrendsCheckboxComponent datasetItems={eodDatasetItems} selectedOptions={selectedOptions} onOptionChange={handleOptionChange}/>
        </React.Fragment>
    );
};

export default TradesPositionsLineChart;
