import { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { getDataFromAPI, editAPIData } from 'utils/WT3ApiUtil'
import { formatISODate } from 'utils/WT3DisplayUtil'
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";
import Header from "common/components/Header";
import Breadcrumbs from 'common/components/Breadcrumbs'


const UserEditPage = () => {

    const navigate = useNavigate();

    const { userId } = useParams();
    const [user, setUser] = useState(null)
    const [message, setMessage] = useState(null);


    const userEndPoint =  `/wt3-api/user/${userId}`;

    if (userId == null) {
        navigate("/notfound");
    }

    const fetchUser = async (userId) => {
        console.log(`Fetching user: ${userId}`)    
        const data = await getDataFromAPI(userEndPoint, navigate);
        if (data !== null) {
            setUser(data);
        }    
    };

    console.log(`Rendering UserEditPage. UserId: ${userId}`);

    const displayUser = () => {
        if (user == null) {
            return '';
        }
        return (
            <div className="user">
                
                <span className="list-title">Edit User</span>

                <div className="user-attr">
                    <label>Name: </label>
                    <div>{user.name}</div>
                </div>
                <div className="user-attr">
                    <label>Email: </label>
                    <div>{user.email}</div>
                </div>
                <div className="user-attr">
                    <label>Created On: </label>
                    <div>{formatISODate(user.date_created)}</div>
                </div>
                <div className="user-attr">
                    <label>Last Log In: </label>
                    <div>{formatISODate(user.last_logged_in)}</div>
                </div>
                <div className="user-attr">
                    <label className="checkbox-container">Is Admin
                        <input type="checkbox" checked={user.is_admin} 
                            onChange={() => setUser({...user, is_admin: !user.is_admin})} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="checkbox-container user-attr">
                    <label className="checkbox-container">Is Enabled:
                        <input type="checkbox" checked={user.is_enabled} 
                        onChange={() => setUser({...user, is_enabled: !user.is_enabled})} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                {<div className="success">{message}</div>}
                <ProgressBar />
                <div className="button-container">
                    <button className="button-primary" onClick={refreshData}>Refresh</button>
                    <button className="button-primary" onClick={onEditUser}>Save</button>
                </div>
            </div>
        );
    };
    
    useEffect(() => {
        fetchUser(userId);
    }, [userId]);
        
    const refreshData = () => {
        fetchUser(userId);
    };

    const onEditUser = async () => {
        const userData = {
            user_id: user.user_id,
            is_admin: user.is_admin,
            is_enabled: user.is_enabled
        };
        
        console.log(`Updating user ${JSON.stringify(userData, null, 2)}`);

        try {
            showProgress();
            const response = await editAPIData(userEndPoint, userData, navigate);
            console.log('User updated :', response.data);
            // Set the message to be displayed
            setMessage('User updated');
            // Set a timeout to hide the message after 3 seconds
            setTimeout(() => {
                setMessage(null);
            }, 1500);
            
        } catch (error) {
            console.error('Error updating user:', error);
        } finally {
            hideProgress();
        }
    }

    return (
        <div className="list-container">
            <Header />
            <Breadcrumbs />
            {displayUser()}
        </div>
        )
}
export default UserEditPage;