import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import ProgressBar from "common/components/ProgressBarV2";
import { TRENDING_TRADES_END_POINT } from "utils/WT3ApiConstants"
import { getDataFromAPI} from "utils/WT3ApiUtil"
import ChartRecentIntradayAggregates from "home/components/ChartRecentIntradayAggregates"

import './HomePage.css';


const UpcomingTradeList = () => {

    const [upcomingTrades, setUpcomingTrades] = useState([]);
    const [selectedUpcomingTradeTicker, setSelectedUpcomingTradeTicker] = useState(null); // Initial state is the first ticker
    const [progressBarControl, setProgressBarControl] = useState({ showProgress: () => {}, hideProgress: () => {} });
    const navigate = useNavigate();

    const handleTickerClick = (ticker) => {
        setSelectedUpcomingTradeTicker(ticker);
    };

    
    const fetchUpcomingTrades = async () => {
        console.log(`Fetching upcoming trades`);
        let trendingTradesEndPoint = `${TRENDING_TRADES_END_POINT}?status=placed`
        progressBarControl.showProgress();
        const response = await getDataFromAPI(trendingTradesEndPoint, navigate);
        progressBarControl.hideProgress();
        if (response) {
            setUpcomingTrades(response['data']);
        }
    };

    useEffect(() => {
        fetchUpcomingTrades();
    }, []);
    
    useEffect(() => {
        setProgressBarControl({
            showProgress: () => document.querySelector('#upcomingTradesProgressBar').classList.add('animate_progress_bar'),
            hideProgress: () => document.querySelector('#upcomingTradesProgressBar').classList.remove('animate_progress_bar')
          });        

        if (upcomingTrades.length > 0) {
            handleTickerClick(upcomingTrades[0].ticker);
        }
    }, [upcomingTrades.length]); // Depend on the length of the array
    
    

    const displayUpcomingTrades = () => {
        console.log(`Upcoming trades found: ${upcomingTrades.length}`);   

        if (upcomingTrades == undefined || upcomingTrades.length < 1) {
            return <h2>No trades recommendations. Please check later.</h2>;
        }
        console.log("displaying UpcomingTrades....");
        if (upcomingTrades == undefined || upcomingTrades.length < 1) {
            return '';
        }
        console.log("selectedUpcomingTradeTicker:" + selectedUpcomingTradeTicker);
        return (
                upcomingTrades.map(stockTrade => (
                    <div className="list-item" key={stockTrade.ticker} onClick={() => handleTickerClick(stockTrade.ticker)}>
                        <div className={`ticker_option ${selectedUpcomingTradeTicker === stockTrade.ticker ? 'selected' : ''}`} key={stockTrade.order_id}><div className="ticker_name">{stockTrade.company_name}
                            <div className="ticker_exchange">{stockTrade.exchange}</div></div><span className="ticker_symbol">{stockTrade.ticker}</span>
                        </div>
                    </div>               
                ))
          );
    }

    return (
                <React.Fragment>
                    <div className="upcoming-container">
                        <div className="upcoming-trades">
                        <ProgressBar id="upcomingTradesProgressBar" />
                            {displayUpcomingTrades()}
                        </div>
                        {selectedUpcomingTradeTicker && 
                        <div className="trade-charts">
                                <ChartRecentIntradayAggregates ticker={selectedUpcomingTradeTicker} />
                        </div>
                        }
                    </div>
                </React.Fragment>
            )
}

export default UpcomingTradeList;