import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';

const pusher = new Pusher('c6faee3a5e4561bceda8', {
  cluster: 'us2'
});

const hostname = window.location.hostname;
const channelName = `price-movement-${hostname}`;
console.log(`Fetching messages from: ${channelName}`);

const PusherMessageComponent = ({ resetTrigger }) => {
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const channel = pusher.subscribe(channelName);

    channel.bind('generate', function (message) {
      console.log(message);
      setMessages(prevMessages => {
        const updatedMessages = { ...prevMessages };
        const exchange = Object.keys(message)[0];
        const newMessage = message[exchange];

        if (!updatedMessages[exchange]) {
          updatedMessages[exchange] = [];
        }

        // Append the new message if it's not already in the list
        if (!updatedMessages[exchange].some(msg => msg.message === newMessage.message && msg.progress === newMessage.progress)) {
          updatedMessages[exchange].push(newMessage);
        }

        return updatedMessages;
      });
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  // Reset messages when resetTrigger changes
  useEffect(() => {
    setMessages({});
  }, [resetTrigger]);

  const getProgressBar = (messages) => (
    <div style={{ display: 'flex', height: '30px', backgroundColor: '#e0e0e0', borderRadius: '5px', overflow: 'hidden' }}>
      {messages.map((msg, index) => (
        <div
          key={index}
          style={{
            width: `${msg.progress}%`,
            backgroundColor: '#177dc4',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: index < messages.length - 1 ? '1px solid white' : 'none'
          }}
        >
          {msg.message}
        </div>
      ))}
    </div>
  );

  return (
    <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div>Progress:</div>
      </div>
      {Object.entries(messages).map(([exchange, messages]) => (
        <div key={exchange} style={{ marginBottom: '20px' }}>
          <strong>{exchange}:</strong>
          <div style={{ marginTop: '10px' }}>
            {getProgressBar(messages)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PusherMessageComponent;
