import React, { useState } from 'react';
import PriceTrendsFilterComponent from './PriceTrendsFilterComponent';

const PriceTrendsFilterComponentChain = ({ filterOptions, handleAllFiltersChange }) => {
  // State to track each filter component's configuration
  const [filterConfigs, setFilterConfigs] = useState([{ key: '', value: '', rule: 'gte' }]);

  const handleAddFilterComponent = () => {
    setFilterConfigs([...filterConfigs, { key: '', value: '', rule: 'gte' }]);
  };

  const handleRemoveFilterComponent = (index) => {
    const updatedFilterConfigs = filterConfigs.filter((_, idx) => idx !== index);
    setFilterConfigs(updatedFilterConfigs); // Update the state with the new filter configurations
    handleAllFiltersChange(updatedFilterConfigs); // Update the filtered data based on the new configurations
  };

  const handleIndividualFilterChange = (index, filterName, value, rule) => {
    const updatedFilterConfigs = filterConfigs.map((config, idx) => {
      if (idx === index) {
        return { ...config, key: filterName, value, rule };
      }
      return config;
    });

    setFilterConfigs(updatedFilterConfigs);
    handleAllFiltersChange(updatedFilterConfigs);
  };

  return (
    <div>
      {filterConfigs.map((config, index) => (
        <div className="filter-component-container" key={index}>
          <PriceTrendsFilterComponent
            filters={filterOptions}
            handleFilterChange={(filterName, value, rule) => handleIndividualFilterChange(index, filterName, value, rule)}
          />
          {index === filterConfigs.length - 1 && (
            <div className='filter-action'>
              <button onClick={handleAddFilterComponent}>+</button>
            </div>
          )}
          {index !== 0 && (
            <div className='filter-action'>
              <button onClick={() => handleRemoveFilterComponent(index)}>-</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PriceTrendsFilterComponentChain;
