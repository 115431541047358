import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import React from 'react';


const ServerErrorPage = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
        <Header />
        <div className="content-container">
            {/* <div class="content"> */}
                <div className="error-container">
                    <p>You need to be logged in to access this resource.</p>
                    <p>Please log in or sign up to continue.</p>
                    <p></p>
                    <button className="button-primary" onClick={() => navigate("/login")}>Already have an account? Log In</button>
                    <button className="button-primary" onClick={() => navigate("/signup")}>Don't have an account? Sign Up</button>        		
                </div>
            {/* </div> */}
        </div>
        </React.Fragment>
    )
}

export default ServerErrorPage