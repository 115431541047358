import React, { useState } from 'react';

let showProgress;
let hideProgress;

const disableButtons = () => {
      document.querySelectorAll('.button-primary').forEach(button => {
        button.disabled = true;
    });
}

const enableButtons = () => {
  document.querySelectorAll('.button-primary').forEach(button => {
    button.disabled = false;
});
}

function ProgressBar() {
  const [isShowing, setIsShowing] = useState(false);

  showProgress = () => {
    setIsShowing(true);
    disableButtons();
  };

  hideProgress = () => {
    setIsShowing(false);
    enableButtons();
  };

  return (
    <div
    className={`progress-bar ${isShowing ? 'animate_progress_bar' : ''}`}
    style={{
        background: '#f99200',
        height: '3px',
        width: '0%', // Set to '0%' since the animation itself handles the width transitions
    }}
    >
    </div>
  );
}
export { ProgressBar, showProgress, hideProgress };
