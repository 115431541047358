import React, { useState, useEffect, useRef } from "react"
import { useNavigate} from 'react-router-dom';
import { ProgressBar, showProgress, hideProgress } from "common/components/ProgressBar";

// Import Highcharts and the necessary components
// import Highcharts from 'highcharts';
// import { HighchartsChart, Chart, withHighcharts, stockChart } from 'react-jsx-highcharts';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { getDataFromAPI} from "../../utils/WT3ApiUtil"
import { RECENT_AGGREGATES_END_POINT } from "../../utils/WT3ApiConstants"


const ChartRecentIntradayAggregates = ({ticker}) => {
    const chartComponentRef = useRef(null);
    const [recentAggregates, setRecentAggregates] = useState([]);
    const [chartWidth, setChartWidth] = useState(null);

    const navigate = useNavigate();

    const fetchAggregates = async (ticker) => {
        console.log(`Fetching recent aggregates for ${ticker}`);
        showProgress();
        showLoading();
        let recentAggregatesEndpoint = getEndpointWithAggregatesType("intraday", ticker);
        try {
            const response = await getDataFromAPI(recentAggregatesEndpoint, navigate);
            if (response) {
                setRecentAggregates(response['data']);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // handle error scenario
        } finally {
            hideLoading(); // Hide loading after fetching data
            hideProgress();
        }
    };

    // Function to replace aggregates_type with your choice
    function getEndpointWithAggregatesType(aggregatesType, ticker) {
        return RECENT_AGGREGATES_END_POINT.replace("[aggregates_type]", aggregatesType).replace("[ticker]", ticker);
    }

    const showLoading = () => {
        if (chartComponentRef.current && chartComponentRef.current.chart) {
            chartComponentRef.current.chart.showLoading();
        }
    };

    const hideLoading = () => {
        if (chartComponentRef.current && chartComponentRef.current.chart) {
            chartComponentRef.current.chart.hideLoading();
        }
    };
    
    useEffect(() => {
        fetchAggregates(ticker);
    }, [ticker]);

    useEffect(() => {
        const handleResize = () => {
            if (chartComponentRef.current) {
                setChartWidth(chartComponentRef.current.container.current.offsetWidth);
            }
        };

        // Set initial size
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
        
    const get_range_selector = () => {
            return {
                buttons: [{
                    type: 'hour',
                    count: 1,
                    text: '1h'
                }, {
                    type: 'day',
                    count: 1,
                    text: '1D'
                }, {
                    type: 'day',
                    count: 5,
                    text: '5D'
                }, {
                    type: 'all',
                    count: 1,
                    text: 'All'
                }],
                selected: 1,
                inputEnabled: false
            }
    }    
    const drawPriceMovements = (price_series) => {
        // Set the utc to False globally for Highcharts
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        }); 

        const options = {
            chart: {
                backgroundColor: '#ddd',
            // Dynamic width based on state
                width: chartWidth,
                height: chartWidth * (2.5 / 4), // Or any other aspect ratio you prefer                
                zooming: {
                    mouseWheel: {
                      enabled: false
                    }
                  },
                  accessibility: {enabled: false}
            },
            title: {
                text: `${ticker} Intraday Chart`,
                style: {
                    color: '#535353',
                    fontSize: '16px'
                    
                }                
            },            
            rangeSelector: get_range_selector(), 
            plotOptions: {
                candlestick: {
                    upColor: '#f99200',
                    opacity: .8,
                    lineColor: '#f99200',
                    color: '#ffffff'
                },
                line: {
                    color: '#f99200',
                    opacity: .7
                }
            },
            navigator: {
                enabled: false, // Disable the navigator
            },
            scrollbar: {
                enabled: false, // Disable the scrollbar
            },
            series: [{
                name: 'Closing Price',
                data: price_series
            }],
            tooltip: {
                formatter: function() {
                    const dateStr = Highcharts.dateFormat('%b %e, %I:%M %p', this.x);
                    const valueStr = Highcharts.numberFormat(this.y, 2); // Format value to two decimals
                    return `${dateStr}<br/><b>Close: ${valueStr}</b>`;
                }
            },            
            time: {
                timezone: 'America/New_York'
            }
        };
        // In your component's render method
        return (
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
                ref={chartComponentRef}
            />
        );                
    };

      const processDataForHighstock = (data) => {
        return data.map(item => {
            // Extracting the timestamp and the closing price
            return [
                new Date(item.datetime).getTime(), // Convert datetime to Unix timestamp in milliseconds
                item.quote.close // The closing price
            ];
        });
    };
    

    const chartTickerPriceMovements = () => {
        if (recentAggregates == undefined || recentAggregates.length < 1) {
            return "";
        }
        console.log("inside chart price movements......");
        console.log(`Recent aggregates fetched for ${ticker}: ${recentAggregates.length}`);   
        if (recentAggregates == undefined || recentAggregates.length < 1) {
            return '';
        }
        let closing_timeseries_data = processDataForHighstock(recentAggregates);
        return drawPriceMovements(closing_timeseries_data);
    }

    return (
                <React.Fragment>
                    <ProgressBar />
                    {chartTickerPriceMovements()}
                    <div id="closing_prices"></div>
                </React.Fragment>
            )
}

export default ChartRecentIntradayAggregates;