// import React from 'react';
import React, { useState, useEffect } from "react"
import { useNavigate} from 'react-router-dom';
import Collapsible from "common/components/CollapsibleComponent";
import StockTrades from "stocks/components/StockTrades";

import { getDataFromAPI, addAPIData } from 'utils/WT3ApiUtil'

import './stocktrades.css';


const StockTradesAdminPage = ({ticker}) => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);
    const [openTrades, setOpenTrades] = useState(null);
    const [closedTrades, setClosedTrades] = useState(null);

    const handleError = (error) => {
        setErrorMessage(error);
    };

    const getTradesEndpoint = (ticker) => {
        return `/wt3-api/trades/${ticker}`;
    };

    const getPlaceTradeOrderEndpoint = () => {
        return `/wt3-api/trade/place`;
    };

    const fetchOpenTrades = async () => {
        console.log(`Fetching open trades for: ${ticker}`);
        let tradesEndPoint = getTradesEndpoint(ticker);
        let openTradesEndPoint = `${tradesEndPoint}?status=open,placed`
        const openTradesResponse = await getDataFromAPI(openTradesEndPoint, navigate);
        // Check if openTradesResponse is defined before accessing its 'data' property
        if (openTradesResponse) {
            console.log("openTradesResponse");
            console.log(openTradesResponse['data']);
            setOpenTrades(openTradesResponse['data']);
        }        
    };

    const fetchClosedTrades = async () => {
        console.log(`Fetching closed trades for: ${ticker}`);
        let tradesEndPoint = getTradesEndpoint(ticker);
        let closedTradesEndPoint = `${tradesEndPoint}?status=closed`
        const closedTradesResponse = await getDataFromAPI(closedTradesEndPoint, navigate);
        // Check if closedTradesResponse is defined before accessing its 'data' property
        if (closedTradesResponse) {
            console.log("closedTradesResponse");
            console.log(closedTradesResponse['data']);
            setClosedTrades(closedTradesResponse['data']);
        }        
    };

    useEffect(() => {
        fetchOpenTrades();
        fetchClosedTrades();
    }, [ticker]);

    const placeTradeOrder = async () => {
        setErrorMessage("");
        const data = {
            ticker: ticker
        };
        console.log(`opening trade for ${JSON.stringify(data, null, 2)}`);
        document.querySelectorAll('.button-primary').forEach(button => {
            button.disabled = true;
        });
        let openTradesEndPoint = getPlaceTradeOrderEndpoint();
        const response = await addAPIData(openTradesEndPoint, data, navigate);
        if (response.status == 400) {
            setErrorMessage(response['data']["error"]);
        } else {
            console.log(`Trade order placed ${response}`)
            await fetchOpenTrades(); // Fetch open trades again after successful trade placement
        }
    }

    return (
        <div className="content stock_trades">
            <Collapsible title="Trades">
            <div className="stock_trades_content">
                <h4 className="stock_trade_header">Trade in Progress</h4>
                <a onClick={() => {fetchOpenTrades(); fetchClosedTrades(); setErrorMessage("")}} style={{ cursor: 'pointer', borderBottom: '1px dotted', textDecoration: 'none' }}>Refresh</a>
                    {openTrades && openTrades.length > 0 ? (
                        <React.Fragment>
                            {<div className="fail" style={{textAlign: "left"}}>{errorMessage}</div>}
                            <StockTrades stockTrades={openTrades} onTradeClose={() => {fetchClosedTrades(); fetchOpenTrades();}} onError={handleError} />
                        </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h4>No open trades</h4>
                                {<div className="fail" style={{textAlign: "left"}}>{errorMessage}</div>}
                                <div className="button-container">
                                    <button className="button-primary" onClick={placeTradeOrder}>Place Order</button>
                                </div> 
                            </React.Fragment>                        
                        )
                    }
                <h4 className="stock_trade_header">Past Trades</h4>
                {closedTrades && closedTrades.length > 0 ? (
                        <StockTrades stockTrades={closedTrades} onTradeClose={() => {fetchClosedTrades(); fetchOpenTrades();}} onError={handleError} />
                        ) : (
                            <React.Fragment>
                                <h4>No Closed trades</h4>
                            </React.Fragment>                        
                        )
                    }                
            </div>
            </Collapsible>           
        </div>
    );   
}

export default StockTradesAdminPage;