import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';

import './NavBar.css';
import axios from 'axios';

function Navbar() {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const [user, setUser] = useState(null);

  const getUserProfileEndPoint = () => {
    return '/wt3-api/user/profile';
  }

  const fetchUserProfile = async () => {
    // console.log(`Fetching user profile`);
    let userProfleEndPoint = getUserProfileEndPoint();
    const userProfileResponse = await axios.get(userProfleEndPoint);
    // Check if openTradesResponse is defined before accessing its 'data' property
    if (Object.keys(userProfileResponse.data).length === 0 && userProfileResponse.data.constructor === Object) {
      console.log("Not able to fetch user profile...")
    } else {
      console.log("User Profile fetched");
      setUser(userProfileResponse['data']);
      // console.log(userProfileResponse);
    }   
};

  useEffect(() => {
    fetchUserProfile();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleLogout = async () => {
    console.log("logging out....")
    try {
      // Send logout request to server
      const response = await fetch('/wt3-api/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('wt3api_access_token')}`
        }
      });

      if (response.ok) {
        console.log("User successfully logged out!!")
        window.location.href = '/';
      } else {
        console.error('Failed to logout.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const renderAuthLinks = () => {
    if (user) {
      return (
          <li><a onClick={handleLogout} style={{ cursor: 'pointer'}}>Log Out</a></li>
      );
    } else {
      return (
          <li><a href="/login">Sign In</a></li>
      );
    }
  };

  const renderHomelink = () => {
    return (
      <li><a href="/">Home</a></li>
    );
};

  const renderFeedbacklink = () => {
    if (user &&  user.is_admin) {
        return null;
    }
{      return (
        <li><a href="/feedback">Feedback</a></li>
      );}
  };

  const renderPastPickslink = () => {
      return (
        <li><a href="/past-picks">Past Picks</a></li>
      );
  };

  const renderAboutlink = () => {
    return (
      <li><a href="/about">About Us</a></li>
    );
  };

  const renderAdminDashboard = () => {
    if (!user || !user.is_admin) {
      return null;
   }    
    return (
        <li className="dropdown">
        <span className="dropbtn">
          <a>Dashboard</a>
        </span>
        <div className="dropdown-content">
            {renderTrendsLink()}
            {renderTradesLink()}
            {renderUsersLink()}
            {renderFeedbackListLink()}
        </div>
      </li> 
    )
  }

  const renderUsersLink = () => {
    if (!user || !user.is_admin) {
      return null;
   }
   return <a href="/users">Users</a>
  }

  const renderTrendsLink = () => {
    if (!user || !user.is_admin) {
      return null;
   }
   return <a href="/trends">Trends</a>
  }

  const renderFeedbackListLink = () => {
    if (!user || !user.is_admin) {
      return null;
   }
   return <a href="/feedbackList">Feedback</a>
  }

  const renderTradesLink = () => {
  if (!user || !user.is_admin) {
      return null;
   }
   return <a href="/trades">Trades</a>
  }

  const renderUserWelcome = () => {
    if (user) { 
        return (
        <div className="user-welcome">
          Hello <span className="user-name">{user.name.split(' ')[0]}</span>
        </div>
      )
    }
  }

  return (
    <div className="navbar-container">
      <nav>
          <button className="hamburger" onClick={toggleMenu}>
              <span className="hamburger-line"></span>
              <span className="hamburger-line"></span>
              <span className="hamburger-line"></span>
          </button>
        <div className={`navbar-grid ${isMenuOpen ? 'show' : ''}`}>
          <ul className="navbar-list-link">
              {renderHomelink()}
              {/* {renderAboutlink()} */}
              {renderPastPickslink()}
              {renderFeedbacklink()}
              {renderAdminDashboard()}
              {renderAuthLinks()}
          </ul>
              {renderUserWelcome()}
        </div>
      </nav>
    </div>

  );
}

export default Navbar;
